<template>
	<article :id="`feed-video-item-${video.id}`" class="feed-video-item">
        <div class="columns is-multiline is-mobile">
            <div class="column is-5-fullhd">
                <div class="video-box">
                    <div class="iframe-video">
                        <div class="iframe-wrapper">
                            <div class="iframe-content">
                                <iframe 
                                    :src="video.video" 
                                    frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" 
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="video-info is-hidden-tablet">
                        <div class="pr-4">
                            <p class="video-title">{{ video.title }}</p>
                            <p v-if="video.book" class="video-author font-weight-semibold">{{ video.book.author }}</p>

                            <time class="video-date">{{ video.published_at }}</time>

                            <div v-if="video.collapse" id="box-video-description-mobile" class="box-video-description">
                                <div class="video-description box-description" v-html="video.description"></div>
                                
                                <div class="see-more">
                                    <button class="btn-expand-text" data-format="mobile" data-margin="70">Expandir texto</button>
                                </div>
                            </div>

                            <div v-else class="video-description" v-html="video.description"></div>
                        </div>

                        <cile-social-media :url="shareUrl"></cile-social-media>
                    </div>
                </div>
            </div>

            <div class="column is-6-fullhd is-hidden-mobile">
                <div class="video-info">
                    <div>
                        <p class="video-title">{{ video.title }}</p>
                        <p v-if="video.book" class="video-author font-weight-semibold">{{ video.book.author }}</p>

                        <time class="video-date" :datetime="video.published_at">{{ video.published_at }}</time>

                        <div id="box-video-description-desktop" class="box-video-description">
                            <div class="video-description box-description" v-html="video.description"></div>
                            
                            <div class="see-more">
                                <button class="btn-expand-text" data-format="desktop" data-margin="70">Expandir texto</button>
                            </div>
                        </div>
                    </div>

                    <cile-social-media :url="shareUrl"></cile-social-media>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
    import SocialMedia from '@components/cile/SocialMedia.vue';

    export default {
        name: 'VideoItemComponent',
        components: {
            'cile-social-media': SocialMedia
        },
        props: {
            video: {
                required: true,
                type: Object
            }
        },
        data(){
            return {}
        },
        computed: {
            shareUrl: function(){
                let url = this.$router.resolve({name: 'videoDetail', params: {id: this.video.id}});

                return url.href;
            }
        },
        mounted(){
            this.$Utilities.initCollapseBoxDescriptionEvent(document.querySelector(`#feed-video-item-${this.video.id}`));
        }
	}
</script>

<style lang="scss">
    @media screen and (max-width: 768px), print {
        .feed-video-item .share-social-media {
            p {
                display: none;
            }
    
            ul li {
                width: 30px;
                height: 30px;
                display: block;
    
                &:not(:last-child) {
                    margin-right: 0px !important;
                    margin-bottom: 15px;
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .feed-video-item {
        &:not(:last-child) {
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px solid #CCCCCC;
        }

        .video-info {
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
        
            .video-title {
                margin-bottom: 5px;
            }

            .video-author {
                margin-bottom: 20px
            }

            .video-date {
                @include font-config(16px, 20px, map.get($font-weights, 'regular'), left, map.get($colors, 'dark-gray'), italic);

                display: block;
                margin-bottom: 15px;
            }

            .video-description {
                margin-bottom: 25px;

                p {
                    @include font-config(16px, 20px);
                }
            }
        }

        .btn-share {
            @include font-config(18px, 22px, map.get($font-weights, 'regular'), left, #008087);

            display: flex;
            align-items: center;
            border: none;
            padding: 0px;
            background: transparent;
            cursor: pointer;

            img {
                margin-right: 5px;
            }
        }
    }

    @media screen and (max-width: 768px), print {
        .feed-video-item {
            .video-box {
                overflow: hidden;
                border-radius: 10px;
                background-color: #FFFFFF;
                box-shadow: 0px 3px 10px #0000001A;
        
                .video-info {
                    display: flex;
                    flex-direction: initial;
                    align-items: flex-start;
        
                    padding: 25px;
                }
            }
        }
    }
</style>