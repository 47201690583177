<template>
    <div class="share-social-media">
        <p>Compartilhar:</p>

        <ul>
            <li><img @click="$Utilities.facebookShare(url)" :src="require('@assets/images/icons/icon_facebook.svg')" class="is-cursor-pointer" /></li>
            <li><img @click="$Utilities.twitterShare(url)" :src="require('@assets/images/icons/icon_twitter.svg')" class="is-cursor-pointer" /></li>
            <li><img @click="$Utilities.whatsappShare(url)" :src="require('@assets/images/icons/icon_whatsapp.svg')" class="is-cursor-pointer" /></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'SocialMedia',
        props: {
            url: {
                required: false,
                type: String
            }
        },
        data(){
            return {}
        },
        methods: {}
	}
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .share-social-media {
        p {
            @include font-config(18px, 22px, map.get($font-weights, 'regular'), left, #008087);

            margin-bottom: 10px;
        }

        ul li {
            display: inline-flex;
            align-items: center;

            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
</style>