<template>
    <div class="beneficiaries">
        <cile-breadcrumb 
            :pages="[
                {name: 'Gestão de Beneficiários', route: ''},
                {name: 'Assinaturas', route: ''}
            ]" />

        <section class="default-session">
            <header>
                <h1>Assinaturas</h1>
            </header>

            <cile-message :alert="alert" />

            <div :class="loadingContent ? 'loading large' : ''">
                <div v-show="!loadingContent">
                    <div class="beneficiary-box">
                        <div class="list-beneficiaries">
                            <div 
                                v-for="(subscriptionPlan, index) in subscriptionPlans" 
                                :key="`plan-${index}`" 
                                class="beneficiary">
                                <p class="beneficiary-name">Pedido {{ subscriptionPlan.idPedido }}: {{ subscriptionPlan.nomeProduto }} - de {{ subscriptionPlan.dtInicio }} a {{ subscriptionPlan.dtFim }}</p>

                                <cile-button 
                                    type="route"
                                    route="beneficiaries"
                                    :routeParams="{id: subscriptionPlan.idPedido}"
                                    title="Ver Beneficiários" 
                                    buttonStyle="button btn-green" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import Message from '@components/shared/message/Message.vue';
    import Button from '@components/shared/button/Button.vue';

    export default {
        name: 'SubscriptionPlans',
        metaInfo: {
            title: 'Assinaturas'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-message': Message,
            'cile-button': Button
        },
        data() {
			return {
                subscriptionPlans: [],
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            };
        },
        methods: {
            getSubscriptionPlans(){
                this.alert.show = false;
                this.loadingContent = true;

                this.$Utilities.scrollToTop();

                this.$axios
                    .get(
                        'subscription-plans',
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status == 204) {
                            this.$Utilities.controlAlert(null, this.alert, 'warning', 'Nenhuma assinatura foi encontrada.');
                        } else {
                            this.subscriptionPlans = res.data;
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 404) this.$router.push({name: 'dashboard'});

                        this.$Utilities.controlAlert(error.response.data, this.alert, 'danger');
                    })
                    .finally(() => this.loadingContent = false);
            }
        },
        created() {
            this.getSubscriptionPlans();
        }
	};
</script>

<style lang="scss"></style>