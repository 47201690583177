<template>
    <div class="">
        <cile-breadcrumb 
            :pages="[
                {name: 'Livros', route: ''},
                {name: pageTitle, route: ''}
            ]" />

        <section class="default-session" :class="loadingContent ? 'loading medium' : ''">
            <header v-show="!loadingContent">
                <h1>{{ pageTitle }}</h1>
            </header>

            <cile-message :alert="alert" />

            <div v-show="!loadingContent" class="columns is-multiline is-mobile">
                <div v-for="(book, index) in bookShowcase" 
                    :key="`last_books_bought_${index}`" 
                    class="column is-6-mobile is-4-tablet is-2-fullhd">
                    <cile-book-showcase :book="book" :linkBookDetail="true" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import Message from '@components/shared/message/Message.vue';
    import BookShowcase from '@components/cile/BookShowcase.vue';

    export default {
        name: 'BooksByCategory',
        metaInfo () {
            return {
                title: this.pageTitle
            }
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-message': Message,
            'cile-book-showcase': BookShowcase
        },
        data() {
			return {
                pageTitle: '',
                bookShowcase: [],
                loadingContent: true,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            };
        },
        methods: {
            getBooks(){
                this.alert.show = false;
                this.loadingContent = true;

                if(this.axiosAbortController != null) this.axiosAbortController.abort();

                this.axiosAbortController = new AbortController();

                this.$axios({
                    method: 'GET',
                    url: `books/category/${this.$route.params.slug}`,                    
                    signal: this.axiosAbortController.signal,
                    headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                })
                .then((res) => {
                    if(res.status == 204){
                        this.$Utilities.controlAlert(null, this.alert, 'emptyList');
                    }else{
                        this.bookShowcase = res.data;

                        this.pageTitle = this.bookShowcase[0].category;
                    }
                })
                .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                .finally(() => this.loadingContent = false);
            }
        },
        created() {
            this.getBooks();
        },
        watch:{
            $route (to, from){
                let toSlug = '';
                let fromSlug = '';

                if(this.$Utilities.hasProperty(to.meta, 'params')){
                    toSlug = to.meta.params.slug;
                }

                if(this.$Utilities.hasProperty(from.meta, 'params')){
                    fromSlug = from.meta.params.slug;                    
                }

                if(toSlug != fromSlug) this.getBooks();
            }
        }
	};
</script>

<style lang="scss"></style>