<template>
    <div class="beneficiaries add-beneficiary">
        <cile-breadcrumb 
            :pages="[
                {name: 'Gestão de Beneficiários', route: ''},
                {name: 'Assinaturas', route: 'subscriptionPlans'},
                {name: orderInfo != null ? orderInfo.nomePlano : '', route: 'beneficiaries', params: {id: orderId}},
                {name: 'Adicionar', route: ''}
            ]" />

        <section class="default-session">
            <header>
                <h1>Adicionar Beneficiário</h1>
            </header>

            <cile-message :alert="alert" />

            <div v-show="orderInfo.sugestaoBeneficiarios.length > 0" class="beneficiary-box">
                <h2>Sugestão de beneficiários</h2>

                <div class="list-beneficiaries">
                    <div v-for="(beneficiary, index) in orderInfo.sugestaoBeneficiarios" :key="`beneficiary-${index}`" class="beneficiary">
                        <p class="beneficiary-name">{{ beneficiary.nome }}</p>

                        <cile-button 
                            type="button"
                            title="Selecionar" 
                            buttonStyle="button btn-green"
                            @customClickEvent="selectBeneficiary(beneficiary)" />
                    </div>
                </div>
            </div>

            <cile-button 
                v-show="orderInfo.sugestaoBeneficiarios.length > 0"
                type="button"
                title="Outra pessoa..." 
                buttonStyle="button btn-blue"
                @customClickEvent="newBeneficiary()"
                :secondButton="{
                    type: 'route', 
                    route: 'beneficiaries',
                    title: 'Voltar', 
                    class: 'btn-pink-only-border', 
                    visible: !visibleBeneficiaryForm,
                    disabled: loading
                }" />

            <form v-show="visibleBeneficiaryForm || orderInfo.sugestaoBeneficiarios.length == 0" @submit.prevent="submitBeneficiary">
                <cile-input
                    label="Nome do beneficiário"
                    placeholder="Digite o nome"
                    v-model="$v.beneficiary.name.$model" 
                    :validation="$v.beneficiary.name"
                    :disabled="beneficiary.beneficiarySuggestion" />

                <p class="is-italic text-medium-gray mb-3">Escolha a melhor forma para envio da notificação</p>

                <cile-radio 
                    label=""
                    name="orderBy"
                    :options="[
                        {_id: 'email', name: 'E-mail'},
                        {_id: 'phone', name: 'Whatsapp'}
                    ]"
                    v-model="beneficiary.type" />

                <cile-input
                    v-show="beneficiary.type == 'email'"
                    label="E-mail do beneficiário"
                    placeholder="Digite o e-mail"
                    v-model="$v.beneficiary.email.$model" 
                    :validation="$v.beneficiary.email" />

                <cile-input
                    v-show="beneficiary.type == 'phone'"
                    label="Whatsapp do beneficiário"
                    placeholder="Digite o Whatsapp"
                    :inputMask="['(##) ####-####', '(##) #####-####']"
                    v-model="$v.beneficiary.phone.$model" 
                    :validation="$v.beneficiary.phone" />

                <cile-button 
                    type="submit" 
                    title="Adicionar beneficiário" 
                    buttonStyle="btn-blue"
                    :loading="loading"
                    :secondButton="{
                        type: 'route', 
                        route: 'beneficiaries',
                        title: 'Voltar', 
                        class: 'btn-pink-only-border', 
                        visible: true,
                        disabled: loading
                    }" />
            </form>
        </section>
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import Input from '@components/shared/fields/Input.vue';
    import Radio from '@components/shared/fields/Radio.vue';
    import Message from '@components/shared/message/Message.vue';
    import Button from '@components/shared/button/Button.vue';

    import { required, requiredIf, email } from 'vuelidate/lib/validators';

    export default {
        name: 'Beneficiary',
        metaInfo: {
            title: 'Beneficiários'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-input': Input,
            'cile-radio': Radio,
            'cile-message': Message,
            'cile-button': Button
        },
        data() {
			return {
                orderId: 0,
                orderInfo: null,
                beneficiary: {
                    user_id: 0,
                    type: 'email',
                    name: '',
                    email: '',
                    phone: '',
                    beneficiarySuggestion: false
                },
                visibleBeneficiaryForm: false,
                loading: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            };
        },
        validations() {
            return {
                beneficiary: {
                    type: {
                        required
                    },
                    name: {
                        required: requiredIf(() => this.visibleBeneficiaryForm)
                    },
                    email: {
                        email,
                        required: requiredIf(() => this.visibleBeneficiaryForm && this.beneficiary.type == 'email')
                    },
                    phone: {
                        required: requiredIf(() => this.visibleBeneficiaryForm && this.beneficiary.type == 'phone')
                    }
                }
            }
        },
        methods: {
            submitBeneficiary(){
                this.$v.$touch();

				if(this.$v.$invalid) return;

                this.loading = true;

                this.$axios({
                    method: 'POST',
                    url: `subscription-plans/${this.orderId}/beneficiary/add`,
                    data: this.beneficiary,
                    headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                })
                .then(res => {
                    this.$router.push({
                        name: 'beneficiaries', 
                        params: {
                            id: this.$route.params.id,
                            message: {
                                type: 'success',
                                text: res.data.message
                            }
                        }
                    });
                })
                .catch(error => {
                    if(error.response.status == 404) this.$router.push({name: 'dashboard'});

                    this.$Utilities.controlAlert(error.response.data, this.alert, 'danger');
                })
                .finally(() => this.loading = false);
            },
            newBeneficiary(){
                this.beneficiary.user_id = 0;
                this.beneficiary.name = '';
                this.beneficiary.email = '';
                this.beneficiary.phone = '';
                this.beneficiary.beneficiarySuggestion = false;

                this.showBeneficiaryForm();
            },
            showBeneficiaryForm(){
                this.visibleBeneficiaryForm = true;
            },
            selectBeneficiary(beneficiary){
                this.beneficiary.user_id = beneficiary.idUsuario;
                this.beneficiary.name = beneficiary.nome;
                this.beneficiary.email = '';
                this.beneficiary.phone = '';
                this.beneficiary.beneficiarySuggestion = true;

                this.showBeneficiaryForm();
            }
        },
        created() {
            if(this.$Utilities.hasProperty(this.$route.params, 'order')){
                this.orderId = this.$route.params.id;
                this.orderInfo = this.$route.params.order;
            }else{
                this.$router.push({name: 'beneficiaries', params: {id: this.$route.params.id}});
            }
        }
	};
</script>

<style lang="scss">
    .add-beneficiary {
        .input-component,
        .select-component {
            max-width: 478px;
        }

        .button-component .button {
            min-width: 230px;

            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }

    @media screen and (max-width: 900px), print {
        .add-beneficiary {
            .input-component,
            .select-component {
                max-width: initial;
            }

            .button-component .button {
                width: 100%;

                &:not(:last-child) {
                    margin-right: 0px;
                    margin-bottom: 15px;
                }
            }
        }
    }
</style>