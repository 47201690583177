<template>
	<article class="message" :class="messageType" v-show="alert.show">
        <div class="message-body" v-html="alert.message"></div>
    </article>
</template>

<script>
	export default {
        props: {
            alert: {
                required: true,
                type: Object
            }
        },
        computed: {
            messageType(){
                switch (this.alert.type) {
                    case 'danger':
                        return 'is-danger';
                        break;

                    case 'success':
                        return 'is-success';
                        break;
                
                    case 'warning':
                        return 'is-warning';
                        break;

                    default:
                        return '';
                        break;
                }
            }
        }
	}
</script>

<style></style>