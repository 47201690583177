<template>
    <div class="radio-component field">
        <label v-if="label" class="label">{{ label }}</label>
        <div class="control">
            <label v-for="(item, index) in options" class="radio" v-bind:key="item._id">
                <span class="radio-title">{{ item.name }}</span>
                <input 
                    type="radio" 
                    :name="name" 
                    :value="item._id" 
                    @input="$emit('input', $event.target.value)"
                    @change="changeEvent()"
                    :disabled="readonly"
                    :checked="(index == 0 || item._id == value) ? true : false">
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
</template>

<script>
	export default {
		props: {
            value: {
                type: String
            },
            label: {
                required: false,
                type: String,
                default: ''
            },
            name: {
                required: true,
                type: String
            },
            options: {
                required: true,
                type: Array
            },
            readonly: {
                required: false,
                default: false,
                type: Boolean
            }
        },
        methods: {
            changeEvent(){
                this.$emit('customChangeEvent');
            }
        }
	}
</script>

<style>
    .radio-component .radio {    
        position: relative;
        margin-bottom: 0px;
        cursor: pointer;
        margin-right: 10px;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .radio-component .radio .radio-title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #484848;

        display: block;
        margin-left: 25px;
    }

    .radio-component .radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .radio-component .radio .checkmark {
        position: absolute;
        top: 4px;
        left: 3px;
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background-color: #eee;
    }

    .radio-component .radio:hover input ~ .checkmark {
        background-color: #ccc;
    }

    .radio-component .radio input:checked ~ .checkmark {
        background-color: #009C98;
    }

    .radio-component .radio input:checked ~ .radio-title {
        color: #009C98;
    }

    .radio-component .radio .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .radio-component .radio .checkmark:after {
        border: 1px solid #bdbdbd;
        width: 20px;
        height: 20px;
        display: block;
        top: -4px;
        left: -4px;
        border-radius: 30px;
    }
</style>
