<template>
    <div v-show="!isEmpty">
        <section :id="video != null ? `featured-video-${video.id}` : ''" class="default-session featured-section">
            <header class="is-hidden-mobile">
                <h1>{{ title }}</h1>
            </header>

            <div 
                class="featured-video" 
                :class="[
                    loadingContent ? 'loading medium v-align' : '',
                    video == null && !loadingContent ? 'review-not-found' : ''
                ]">
                <p v-if="video == null && !loadingContent" class="not-found-text">Não há vídeos deste livro.</p>

                <cile-videos 
                    v-if="video != null"
                    :video="video" />
            </div>
        </section>
    </div>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import Videos from '@views/feedVideos/components/Videos.vue';

    export default {
        name: 'FeaturedVideo',
        components: {
            'cile-message': Message,
            'cile-videos': Videos
        },
        props: {
            title: {
                required: false,
                type: String
            },
            endpoint: {
                required: true,
                type: String
            }
        },
        data(){
            return {
                video: null,
                isEmpty: false,
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            }
        },
        methods: {
            getVideo(){
                this.loadingContent = true;

                this.$axios
                    .get(
                        this.endpoint,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status != 204){
                            this.video = res.data;
                        } else {
                            this.isEmpty = true;
                        }
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            }
        },
        created(){
            this.getVideo();
        }
	}
</script>

<style lang="scss">
    .featured-section {
        height: 100%;

        .message {
            &,
            .message-body {
                height: 100%;
                border-radius: 6px;
            }

            .message-body {
                display: flex;
                align-items: center;
            }
        }
    }
</style>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .featured-section {
        margin: 0px 10px;

        &.loading.medium:after {
            top: calc(50% - 20px);
            transform: translateY(-50%);
        }
    }

    .featured-video {
        width: 100%;
        padding: 25px 25px;
        /*height: calc(100% - 50px);*/

        display: inline-flex;
        flex-direction: column;
        /*justify-content: space-between;*/

        overflow: hidden;
        border-radius: 6px !important;
        
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 10px #00000014;
        border-radius: 0px 0px 6px 6px;

        .video-info {
            padding: 15px 25px 25px 25px;

            .video-description,
            .video-title,
            .video-data {
                @include font-config(16px, 20px);
            }

            .video-description {
                margin-bottom: 10px;
            }

            .video-title {
                font-weight: 600;

                margin-bottom: 10px;
            }

            .video-data {
                font-style: italic;
            }
        }
    }
</style>