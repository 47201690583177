<template>
    <nav class="breadcrumb-component breadcrumb" aria-label="breadcrumbs">
        <ul>
            <li v-for="(page, index) in pages" :key="`breadcrumb-${index}`">
                <router-link 
                    v-if="page.route"
                    :to="{
                        name: page.route,
                        params: $Utilities.hasProperty(page, 'params') ? page.params: {}
                    }">{{ page.name }}</router-link>

                <span v-else>{{ page.name }}</span>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'BreadcrumbComponent',
        data() {
			return {};
        },
        props: {
            pages: {
                required: true,
                type: Array
            }
        },
        methods: {},
        created() {}
	};
</script>

<style lang="scss">
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .breadcrumb-component.breadcrumb {
        &,
        a,
        a:hover {
            @include font-config(16px, 20px, map.get($font-weights, 'regular'), left, #888888, italic);

            padding-right: 0px;
        }

        a {
            padding: 0px;
        }

        &:not(:last-child){
            margin-bottom: 20px;
        }

        li + li::before {
            color: #888888;
            margin: 0px 5px;
        }
    }
</style>