<template>
	<section :class="loadingContent ? 'loading large' : ''">
        <cile-message :alert="alert" />

        <div v-show="!loadingContent" class="columns is-multiline is-mobile">
            <div v-for="(book, index) in bookShowcase" 
                 :key="`last_books_bought_${index}`" 
                 class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd">
                <cile-book-showcase 
                    :book="book"
                    :linkBookDetail="true" />
            </div>
        </div>
    </section>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import BookShowcase from '@components/cile/BookShowcase.vue';

    export default {
        name: 'SearchBooksComponent',
        components: {
            'cile-message': Message,
            'cile-book-showcase': BookShowcase
		},
        data(){
            return {
                filter: '',
                bookShowcase: [],
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            }
        },
        methods: {
            search(filter) {
                this.filter = filter;
                this.alert.show = false
                this.loadingContent = true;
                this.bookShowcase = [];

                this.$axios
                    .get(
                        `search/book/${this.filter}`,
                        {
                            headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`},
                            params: {
                                device: this.$Utilities.device()
                            }
                        }
                    )
                    .then((res) => {
                        if(res.status == 204){
                            this.$Utilities.controlAlert(null, this.alert, 'emptyList');

                            return;
                        }

                        this.bookShowcase = res.data.map(item => {
                            return {
                                id: item.id,
                                title: this.$Utilities.markText(item.title, this.filter),
                                summary: this.$Utilities.markText(item.author, this.filter),
                                image: item.image,
                                is_my_book: item.is_my_book,
                                ecommerce_url: item.ecommerce_url,
                                subscription_plan_url: item.subscription_plan_url
                            }
                        });
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            }
        },
        created(){
            this.search(this.$route.query.filter);
        },
        watch:{
            $route (to, from){
                this.search(this.$route.query.filter);
            }
        }
	}
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';
</style>