<template>
    <div>
        <span v-if="beneficiary.solicitante == 1" class="tag requester">Solicitante</span>
        <span v-else-if="beneficiary.pendencia == 1" class="tag pending">Pendente</span>
        <span v-else-if="beneficiary.pendencia == 0" class="tag confirmed">Confirmado</span>
    </div>
</template>

<script>
    export default {
        name: 'BeneficiaryTag',
        props: {
            beneficiary: {
                required: true,
                type: Object
            }
        },
        data(){
            return {}
        },
        methods: {
            
        }
	}
</script>

<style lang="scss"></style>