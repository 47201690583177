<template>
    <article :data-cfi="annotation.cfiRange" class="my-annotation">
        <header>
            <p>{{ annotation.bookTitle }}</p>
            <time v-if="annotation.date">{{ annotation.date }}</time>

            <div v-if="annotation.options" class="annotation-options" :class="annotation.loading ? 'loading small' : ''">
                <button class="btn-options" :class="annotation.loading ? 'is-invisible' : ''">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                <div class="options-list" :class="annotation.loading ? 'is-invisible' : ''">
                    <ul>
                        <li><a href="#" @click="editClickEvent">Editar Nota</a></li>
                        <li><a href="#" @click="deleteClickEvent">Excluir Nota</a></li>
                    </ul>
                </div>
            </div>
        </header>

        <div class="annotation-box green-border" :style="{borderColor: `rgb(${annotation.color})`}" @click="clickEvent">
            <div class="annotation-content">
                <p class="content-title"><img src="~@assets/images/icons/icon_destaque.svg" /> Destaque</p>

                <div class="annotation font-weight-semibold">
                    <p v-html="annotation.markedText"></p>
                </div>
            </div>

            <div v-if="annotation.description" class="annotation-content">
                <p class="content-title"><img src="~@assets/images/icons/icon_nota.svg" /> Nota</p>

                <p v-html="annotation.description"></p>
            </div>

            <div v-if="annotation.tags.length > 0" class="annotation-content">
                <p class="content-title"><img src="~@assets/images/icons/icon_tag.svg" /> Tags</p>

                <div class="tags-list">
                    <div 
                        v-for="tag in annotation.tags" 
                        :key="`annotation-tag-${tag}`" 
                        class="tag-item color-1">{{ tag }}</div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
    export default {
        name: 'AnnotationComponent',
        props: {
            annotation: {
                required: true,
                type: Object
            }
        },
        data() {
			return {};
        },
        methods: {
            clickEvent(){
                this.$emit('annotationClickEvent', this.annotation);
            },
            editClickEvent(){
                this.$emit('editClickEvent', this.annotation);
            },
            deleteClickEvent(){
                this.$emit('deleteClickEvent', this.annotation);
            }
        },
        created() {}
	};
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .my-annotation {
        &:not(:last-child) {
            margin-bottom: 35px;
        }

        header {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            p {
                @include font-config(18px, 22px, map.get($font-weights, 'semi-bold'));
            }
        }

        .annotation-options {
            position: absolute;
            right: 25px;
            bottom: -55px;

            cursor: pointer;

            &:hover > .options-list {
                display: block !important;
            }

            .btn-options {
                display: flex;
                align-items: center;
                padding: 5px 10px;
                background: transparent;
                border: none;

                cursor: pointer;

                span {
                    display: block;
                    background-color: #393A3B;
                    width: 5px;
                    height: 5px;
                    border-radius: 5px;

                    &:not(:last-child) {
                        margin-right: 2px;
                    }
                }
            }

            .options-list {
                display: none;
                text-align: center;

                position: absolute;
                right: 0px;
                z-index: 999;
                width: 150px;
                padding: 20px 25px;
                background-color: #FFFFFF;
                border: 1px solid #CBCBCB;

                ul li {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    a {
                        @include font-config(16px);

                        background-color: #FFFFFF;
                    }
                }

            }
        }

        .annotation-box {
            padding: 25px 35px;
            background-color: #FFFFFF;
            box-shadow: 0px 3px 10px #0000001A;
            border-radius: 3px;
            border-top: 4px solid transparent;
            cursor: pointer;

            &.green-border {
                border-top-color: #00AAAD;
            }

            .annotation-content {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }
        
                .content-title {
                    @include font-config(16px, 20px, map.get($font-weights, 'regular'), left, #858585);

                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;

                    img {
                        margin-right: 5px;
                    }
                }
                
                .annotation {            
                    p {
                        @include font-config(16px, 20px, map.get($font-weights, 'regular'), left);
                    }
        
                    &.font-weight-semibold p {
                        font-weight: map.get($font-weights, 'semi-bold');
                    }
                }
            }

            .tags-list {
                margin-top: 0px;
            }
        }
    }
</style>