<template>
    <div class="review-pdf">
        <cile-breadcrumb :pages="[
            { name: 'Resenhas', route: 'feedReviews' },
            { name: title, route: '' }
        ]" />

        <section class="default-session" :class="loadingContent ? 'loading large' : ''">
            <!-- <header v-show="!loadingContent">
                <h1>{{ title }}</h1>
            </header> -->

            <cile-message :alert="alert" />

            <div v-show="pdf.url != '' && !external_link">
                <div class="pdf-status has-text-centered mb-4">
                    <p>Páginas: {{ pdf.pageNum }} / {{ pdf.numPages }}</p>
                </div>

                <div class="pdf-reader">
                    <button class="arrow left" :class="pdf.pageNum != 1 ? 'is-active' : ''" :disabled="pdf.pageNum == 1"
                        @click="onPrevPage"></button>

                    <div id="wrapper-canvas">
                        <pdf :src="pdf.url" :page="pdf.pageNum" @num-pages="pdf.numPages = $event"
                            @page-loaded="pdf.pageNum = $event"></pdf>
                    </div>

                    <button class="arrow right" :class="pdf.pageNum != pdf.numPages ? 'is-active' : ''"
                        :disabled="pdf.pageNum == pdf.numPages" @click="onNextPage"></button>
                </div>
            </div>

            <div class="iframe-wordpress" height="5000px">
                <iframe ref="iframeWp" id="iframe-wp" v-show="!!external_link" :src="external_link" width="100%"
                    height="600px" frameborder="0"></iframe>
                <!-- <iframe ref="iframeWp" id="iframe-wp" v-show="!!external_link" :src="external_link" height="600px"
                    width="100%" frameborder="0" scrolling="no"></iframe> -->
            </div>


        </section>
    </div>
</template>

<script>
import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
import Message from '@components/shared/message/Message.vue';

import pdf from 'vue-pdf'

export default {
    name: 'ReviewPdf',
    metaInfo: {
        title: 'PDF'
    },
    components: {
        'cile-breadcrumb': Breadcrumb,
        'cile-message': Message,
        pdf
    },
    data() {
        return {
            title: '',
            pdf: {
                url: '',
                pdfjsLib: null,
                pdfDoc: null,
                pageNum: 1,
                numPages: 0,
                pageRendering: false,
                pageNumPending: null,
                scale: 0.8,
                canvas: null,
                ctx: null
            },
            loadingContent: false,
            alert: {
                message: '',
                type: '',
                show: false
            },
            external_link: null
        };
    },
    methods: {
        // setIframeHeight() {
        //     const iframe = this.$refs.iframeWp;
        //     console.log("🚀 ~ setIframeHeight ~ iframe:", iframe)
        //     if (iframe) {
        //         iframe.onload = function () {
        //             // const contentHeight = iframe.contentWindow.document.body.scrollHeight + 'px';
        //             const contentHeight = document.body.scrollHeight + 'px';
        //             console.log("🚀 ~ setIframeHeight ~ iframe:", iframe.height)
        //             console.log("🚀 ~ setIframeHeight ~ document:", document.body.scrollHeight)
        //             iframe.height = contentHeight;
        //             console.log("🚀 ~ setIframeHeight ~ iframe:", iframe.height)

        //         };
        //     }
        // },
        // iframeLoaded() {
        //     var iFrameID = document.getElementById('iframe-wp');
        //     console.log("🚀 ~ iframeLoaded ~ iFrameID:", iFrameID)
        //     if (iFrameID) {
        //         // here you can make the height, I delete it first, then I make it again
        //         iFrameID.height = "";
        //         const contentHeight = document.body.scrollHeight + 'px';
        //         // iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
        //         iFrameID.height = contentHeight;
        //         console.log("🚀 ~ iframeLoaded ~ iFrameID.height:", iFrameID.height)
                
        //     }
        // },
        getReview(id) {
            this.alert.show = false;
            this.loadingContent = true;

            this.$Utilities.scrollToTop();

            this.$axios
                .get(
                    `book/review/${id}`,
                    { headers: { 'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}` } }
                )
                .then((res) => {
                    if (res.status == 204) {
                        this.$router.push({
                            name: 'feedReviews',
                            params: { message: 'reviewError' }
                        });
                    } else {
                        this.title = res.data.title;
                        this.pdf.url = res.data.pdf;
                        this.external_link = res.data.external_link;
                    }
                })
                .catch(error => {
                    this.$router.push({
                        name: 'feedReviews',
                        params: { message: 'reviewError' }
                    });
                })
                .finally(() => this.loadingContent = false);
        },
        onPrevPage() {
            if (this.pdf.pageNum <= 1) return;

            this.pdf.pageNum--;
        },
        onNextPage() {
            if (this.pdf.pageNum >= this.pdf.numPages) return;

            this.pdf.pageNum++;
        }
    },
    mounted() {
        this.getReview(this.$route.params.id);
        // this.setIframeHeight();
        // this.iframeLoaded();
    }
};
</script>

<style lang="scss">
.review-pdf {

    .pdf-progress {
        height: 3px;
        width: calc(100% - 100px);
        margin: 25px auto 0px auto;
        background-color: rgba(178, 178, 178, 0.4);

        .progress {
            height: 3px;
            border-radius: 0px;
            background-color: #00AAAD;

            -webkit-transition: width 0.5s;
            transition: width 0.5s;
        }
    }

    .pdf-reader {
        position: relative;

        @media screen and (min-width: 769px) and (max-width: 1280px) {
            // background-color: red;
            padding: 0px 40vh;
        }

        @media screen and (min-width: 1281px) {
            // background-color: blue;
            padding: 0px 55vh;
        }

        // padding: 0px 75px;
        margin-bottom: 50px;

        .pdf-content {
            display: block;
            margin: 0 auto;
            /*max-width: 100%;
                width: 100%;*/
        }

        .arrow {
            position: absolute;
            top: calc(50% - 25px);

            border: solid rgba(0, 170, 173, 0.5);
            border-width: 0 5px 5px 0;
            display: inline-block;
            padding: 15px;

            &:disabled {
                cursor: no-drop;
            }

            &.is-active {
                border-color: rgb(0, 170, 173);
                cursor: pointer;
            }

            &.left {
                left: 25px;
            }

            &.right {
                right: 25px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .review-pdf .pdf-reader {
        padding-left: 0px;
        padding-right: 0px;

        .arrow {
            top: initial;
            bottom: -50px;
        }
    }
}
</style>