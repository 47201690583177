<template>
    <div class="">
        <cile-breadcrumb 
            :pages="[{name: 'Pesquisar', route: ''}]" />

        <section class="default-session">
            <header>
                <h1>Resultados "{{ filter }}"</h1>
            </header>

            <div id="search-menu" class="search-menu">
                <ul>
                    <li 
                        v-for="(itemMenu, index) in filterMenu" 
                        :key="`search-menu-${index}`" 
                        :id="`search-menu-${itemMenu.route}`">
                        <router-link 
                            :to="{name: itemMenu.route, query: {filter: filter}}"
                            :class="$route.name == itemMenu.route ? 'active' : ''">{{ itemMenu.title }}</router-link>
                    </li>
                </ul>
                <div id="menu-indicator" class="menu-indicator"></div>
            </div>

            <router-view></router-view>
        </section>
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';

    export default {
        name: 'FeedNews',
        metaInfo: {
            title: 'Pesquisa'
        },
        components: {
            'cile-breadcrumb': Breadcrumb
        },
        data() {
			return {
                filter: '',
                filterMenu: [
                    {title: 'Trechos', route: 'searchBookExcerpts'},
                    {title: 'Livros', route: 'searchBooks'},
                    //{title: 'Notícias', route: 'searchNews'},
                    {title: 'Tags', route: 'searchTags'},
                    {title: 'Anotações', route: 'searchAnnotation'}
                ]
            };
        },
        methods: {
            moveMenuIndicator(target_element){
                let element_menu_indicator = document.querySelector('#menu-indicator');
                
                element_menu_indicator.style.width = `${target_element.offsetWidth}px`;
                element_menu_indicator.style.left = `${target_element.offsetLeft}px`;
            },
            mouseOverEvent(e){
                this.moveMenuIndicator(e.target)
            },
            mouseLeaveEvent(e){
                this.moveMenuIndicator(document.querySelector("#search-menu li a.active"))
            },
            mouseClickEvent(e){
                document.querySelectorAll('#search-menu li a').forEach(element => element.classList.remove('active'));
                e.target.classList.add('active');
            },
            resizeEvent(){
                this.moveMenuIndicator(document.querySelector("#search-menu li a.active"))
            },
            initMenuIndicator(){
                var $this = this;

                this.moveMenuIndicator(document.querySelector("#search-menu li a.active"));

                document.querySelectorAll('#search-menu li a').forEach(item => {
                    item.addEventListener('mouseover', this.mouseOverEvent);
                    item.addEventListener('mouseleave', this.mouseLeaveEvent);
                    item.addEventListener('click', this.mouseClickEvent);
                });

                window.addEventListener('resize', this.resizeEvent);
            }
        },
        mounted() {
            this.filter = this.$route.query.filter;

            this.initMenuIndicator();
        },
        beforeDestroy(){
            document.querySelectorAll('#search-menu li a').forEach(item => {
                item.removeEventListener('mouseover', this.mouseOverEvent);
                item.removeEventListener('mouseleave', this.mouseLeaveEvent);
                item.removeEventListener('click', this.mouseClickEvent);
            });

            window.removeEventListener('resize', this.resizeEvent);
        },
        watch:{
            $route (to, from){
                this.filter = this.$route.query.filter;
            }
        }
	};
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .search-menu {
        position: relative;
        margin: 20px 0px 50px 0px;
        margin-left: -1.5rem;
        margin-right: -1.5rem;

        padding-left: 1.5rem;
        padding-right: 1.5rem;
        background-color: #ECECEC;

        ul {
            width: 100%;
            margin: 0px;
            display: flex;
            list-style: none;        
            border-bottom: 1px solid #707070;

            li {
                flex: 1 0;
                text-align: center;

                margin: 0px;
                cursor: pointer;

                a {
                    display: block;
                    padding: 15px 0px;
                    height: 100%;

                &:hover {
                    color: #008087;
                }
                
                &.active {
                        font-weight: map.get($font-weights, 'bold');
                        color: #008087;
                    }
                }
            }
        }
            
        .menu-indicator {
            position: absolute;
            bottom: 0px;

            display: block;
            width: 0px;
            height: 4px;
            background-color: #008087;

            -webkit-transition: left .4s ease-in-out, width .4s ease-in-out;
                    transition: left .4s ease-in-out, width .4s ease-in-out;
        }
    }

    @media screen and (max-width: 768px), print {
        .search-menu ul li a {
            @include font-config(14px, 18px, map.get($font-weights, 'regular'), center);
        }
    }
</style>