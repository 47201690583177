<template>
    <nav v-if="paginationConfig.current > 0" class="pagination is-centered" role="navigation" aria-label="pagination">
        <a 
            v-if="paginationConfig.current > 1" 
            class="pagination-previous" 
            @click="changePage(paginationConfig.current - 1)">Página Anterior</a>

        <a 
            v-if="isNotLastPage"
            class="pagination-next" 
            @click="changePage(paginationConfig.current + 1)">Próxima Página</a>

        <ul class="pagination-list">
            <li v-for="(page, index) in pages" :key="index">
                <span 
                    v-if="page == 'blank'"
                    class="pagination-ellipsis">&hellip;</span>
                <a
                    v-else
                    class="pagination-link" 
                    :class="paginationConfig.current == page ? 'is-current' : ''"
                    aria-label="Pagina"
                    @click="changePage(page)">{{ page }}</a>
            </li>
        </ul>
    </nav>
</template>

<script>
	export default {
		props: {
            paginationConfig: {
                required: true,
                type: Object,
                default: () => {
                    return {
                        current: 0,
                        totalPages: 0,
                        lastPage: false,
                        perPage: 10
                    }
                }
            }
        },
        methods: {
            changePage(page){
                this.$emit('customChangePageEvent', page);
            }
        },
        computed: {
            pages(){
                let pages = [];
                let range = 3;

                if(typeof this.paginationConfig.totalPages == 'undefined') return [];

                if(this.paginationConfig.totalPages <= 4){
                    for(var page = 1; page <= this.paginationConfig.totalPages; page++){
                        pages.push(page);
                    }
                }else{
                    if(this.paginationConfig.current <= range - 1){
                        //1, 2, 3, ..., 8
                        for(var page = 1; page <= range; page++){
                            pages.push(page);
                        }

                        pages.push('blank');
                        pages.push(this.paginationConfig.totalPages);
                    }else if((this.paginationConfig.current - 1) + range < this.paginationConfig.totalPages){
                        //1, ..., 3, 4, 5, ..., 8
                        pages.push(1);
                        pages.push('blank');

                        for(var page = 0; page < range; page++){
                            pages.push((this.paginationConfig.current - 1) + page);
                        }

                        pages.push('blank');
                        pages.push(this.paginationConfig.totalPages);
                    }else{
                        //1, ..., 6, 7, 8
                        pages.push(1);
                        pages.push('blank');

                        for(var page = (this.paginationConfig.totalPages - range); page < this.paginationConfig.totalPages; page++){
                            pages.push(page + 1);
                        }
                    }
                }

                return pages;
            },
            isNotLastPage(){
                if(typeof this.paginationConfig.totalPages != 'undefined'){
                    return this.paginationConfig.current != this.paginationConfig.totalPages && this.paginationConfig.totalPages != 0;
                }else if(typeof this.paginationConfig.lastPage != 'undefined'){
                    return !this.paginationConfig.lastPage;
                }

                return false;
            }
        }
	}
</script>

<style lang="scss" scoped>
    .pagination {
        margin-top: 35px;

        .pagination-list a,
        .pagination-next,
        .pagination-previous {
            font-size: 16px;
            line-height: 20px;
            color: #4a4a4a;
            background: transparent;

            border: none;
            padding: 0px;
            border-radius: 0px;
        }

        .pagination-next,
        .pagination-previous {
            font-weight: 600;
        }

        .pagination-next,
        .pagination-previous {
            &:active {
                box-shadow: none;
            }
        }

        .pagination-list a {
            width: 30px;
            min-width: initial;

            height: 30px;

            &:active {
                box-shadow: none;
            }

            &.is-current {
                font-weight: 600;
                color: #008087;

                background-color: rgba(0, 128, 135, 0.1);
            }
        }
    }
</style>