<template>
    <div class="know-our-plans plans-page">
        <header>
            <div class="container">
                <figure class="image logo">
                    <img src="~@assets/images/cile-logo-2.svg" />
                </figure>

                <cile-button 
                    type="route"
                    route="login" 
                    :routeParams="{page: 'login'}"
                    title="Entrar" 
                    buttonStyle="button btn-green btn-login" />
            </div>
        </header>

        <section class="main-banner">
            <div class="content-box">
                <div class="container">
                    <h1 class="is-uppercase">Acesse e leia os livros da Editora Brasil Seikyo em formato digital</h1>

                    <div class="content">
                        <p>Criamos uma plataforma interativa, personalizada e cheia de funcionalidades que vai transformar a sua experiência com a leitura</p>
                    </div>

                    <div class="try-it-now-content">
                        <p>Assinaturas a partir de <br> <span class="has-text-weight-bold price">R$ 16,10/mês*</span></p>

                        <a 
                            :href="`${this.$ecommerceUrl}/clube-livros-digitais#description-of-plans`" 
                            target="_blank" 
                            class="button btn-pink is-uppercase">Quero conhecer</a>

                        <p>*aplicável ao plano anual individual para quem é assinante de algum produto da Editora Brasil Seikyo</p>
                    </div>
                </div>
            </div>

            <div class="video-box video-desktop">
                <video 
                    autoplay
                    loop
                    playsinline="" 
                    muted
                    data-keepplaying="" 
                    data-ratio="1.7777777777778" 
                    preload="metadata">
                    <source type="video/mp4" src="~@assets/images/digital/video.mp4?_=1">
                    <a href="~@assets/images/digital/video.mp4">~@assets/images/digital/video.mp4</a>
                </video>
            </div>

            <div class="video-box video-mobile">
                <video 
                    autoplay
                    loop
                    playsinline="" 
                    muted
                    data-keepplaying="" 
                    class="background-video-shortcode" 
                    data-ratio="1.7777777777778" 
                    id="video-8-1"
                    preload="metadata">
                    <source type="video/mp4" src="~@assets/images/digital/video-mobile.mp4?_=1">
                    <a href="~@assets/images/digital/video-mobile.mp4">~@assets/images/digital/video-mobile.mp4</a>
                </video>
            </div>
        </section>

        <section class="banner a-new-experience">
            <div class="container">
                <div class="area-1">
                    <header>
                        <h1 class="is-uppercase">Leve seus livros favoritos com você!</h1>
                    </header>
                    
                    <div class="content">
                        <div class="mb-5">
                            <p class="font-weight-semibold">Você poderá organizar e otimizar suas leituras com ferramentas de marcações de texto, espaço para anotações e a função de salvar seus trechos favoritos</p>    
                        </div>

                        <router-link 
                            :to="{
                                name: 'login', 
                                params: {
                                    page: 'login'
                                }
                            }" 
                            class="button btn-pink is-uppercase">Acesse</router-link>
                    </div>
                </div>                

                <div class="area-2 img-box">
                    <img src="~@assets/images/digital/nova-experiencia.png" class="img-fluid">
                </div>
            </div>
        </section>

        <section class="banner all-contents">
            <div class="container">
                <div class="area-1 img-box">
                    <img src="~@assets/images/digital/conteudos.png" class="img-fluid">
                </div>

                <div class="area-2">
                    <header>
                        <h1 class="font-weight-bold is-uppercase">Um portal de livros com temas para inspirar e despertar o nobre potencial humano</h1>
                    </header>

                    <div class="content">
                        <div class="mb-5">
                            <p class="font-weight-semibold">Visualização personalizada da sua estante, acompanhamento do avanço de suas leituras e conteúdos exclusivos</p>
                        </div>

                        <router-link 
                            :to="{
                                name: 'login', 
                                params: {
                                    page: 'login'
                                }
                            }" 
                            class="button btn-pink is-uppercase">Acesse</router-link>
                    </div>
                </div>
            </div>
        </section>

        <section id="subscription-plan-benefits" class="subscription-plan-benefits">
            <div class="container">
                <header class="is-sr-only">
                    <h1>Vantagens do plano de assinatura</h1>
                </header>

                <vue-carousel 
                    :navigationEnabled="false"
                    navigationPrevLabel=""
                    navigationNextLabel=""
                    :perPageCustom="[[0, 1], [992, 5]]">
                    <vue-slide 
                        data-index="0"
                        data-name="PlanBenefits"
                        v-for="(benefit, index) in planBenefits" 
                        :key="`plan_benefits_${index}`">
                        <div class="item">
                            <div class="plan-benefits">
                                <img :src="benefit.image" />
                                <p class="has-text-weight-semibold">{{ benefit.description }}</p>
                            </div>
                        </div>
                    </vue-slide>
                </vue-carousel>
            </div>
        </section>

        <section class="description-of-plans">
            <header>
                <h1 class="has-text-weight-regular">Gostou da experiência?</h1>

                <p>Conheça os planos de assinatura</p>

                <div class="horizontal-line"></div>
            </header>

            <div class="container">
                <div class="columns">
                    <div v-for="(plan, index) in plans" :key="`plan-${index}`" class="column is-12-mobile is-4">
                        <div class="plan-description digital-plan-description" :class="plan.class">
                            <div>
                                <figure class="image">
                                    <img src="~@assets/images/digital/icon_assinatura_digital.svg" />
                                </figure>

                                <h2 class="is-uppercase has-text-centered">{{ plan.title }}</h2>

                                <div class="horizontal-line"></div>
                            </div>

                            <div class="description">
                                <div class="number-of-users">
                                    <div class="users-icon">
                                        <img 
                                            v-for="(number_of_user, number_of_user_index) in plan.number_of_users" 
                                            :key="`number-of-user-${index}-${number_of_user_index}`"
                                            src="~@assets/images/digital/number-of-users.svg" />
                                    </div>

                                    <p class="font-weight-bold">
                                        {{ plan.number_of_users }} 
                                        {{ plan.number_of_users > 1 ? 'acessos' : 'acesso' }}
                                    </p>
                                </div>

                                <p v-html="plan.description"></p>
                            </div>

                            <div class="select-box">
                                <select class="form-control" :ref="`selectPlan${index}`" @change="changePlan(plan, $event)">
                                    <option 
                                        v-for="(frequency, frequencyIndex) in plan.frequency"
                                        :key="`plan-${index}-frequency-${frequencyIndex}`"
                                        :data-price="frequency.price"
                                        :data-url="frequency.url"
                                        :data-frequency="JSON.stringify(frequency)"
                                        :selected="frequencyIndex == 0">{{ frequency.title }}</option>
                                </select>
                            </div>

                            <p class="plan-price has-text-centered" v-html="planPrice(plan.selected.price, plan.selected.installments)"></p>

                            <p v-show="plan.selected.installments > 1" class="has-text-centered mb-4" v-html="planTotalPrice(plan.selected.price)"></p>

                            <a :href="plan.selected.url" target="_blank" class="button is-uppercase">Assinar agora!</a>
                        </div>
                    </div>
                </div>

                <p class="footnote">*O parcelamento só é possível na modalidade de pagamento cartão de crédito.</p>
                <p class="footnote">**20% de desconto para os assinantes de algum produto da Editora Brasil Seikyo. O desconto será efetivado no final do processo de compra</P>
            </div>
        </section>

        <section id="video-tutorial" class="more-about-cile">
            <div class="container">
                <header>
                    <h1 class="has-text-centered">Viaje para novos lugares a partir dos livros</h1>
                    
                    <div class="horizontal-line"></div>
                </header>

                <div class="video">
                    <div class="iframe-wrapper">
                        <div class="iframe-content">
                            <iframe 
                                src="https://player.vimeo.com/video/767273386?h=e39c3fbe71"
                                frameborder="0"
                                allow="autoplay; fullscreen; picture-in-picture" 
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="frequently-asked-questions">
            <header>
                <h1 class="has-text-centered">Dúvidas frequentes</h1>
                <div class="horizontal-line"></div>
            </header>

            <div class="container">
                <div if="frequently-asked-questions-accordion" class="accordion">
                    <article v-for="(faqItem, index) in faq" :key="`faq-${index}`" class="message">
                        <div class="message-header">
                            <a :href="`#collapsible-message-accordion-${index}`" data-action="collapse">{{ faqItem.title }}</a>
                        </div>

                        <div :id="`collapsible-message-accordion-${index}`" class="message-body is-collapsible" data-parent="frequently-asked-questions-accordion">
                            <div class="message-body-content" v-html="faqItem.description"></div>
                        </div>
                    </article>                    
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import Select from '@components/shared/fields/Select.vue';
    import Button from '@components/shared/button/Button.vue';
    import SvgIcon from '@components/shared/images/SvgIcon.vue';

    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: 'LandingPage',
        metaInfo: {
            title: 'Assinatura Digital'
        },
        components: {
            'cile-message': Message,
            'cile-select': Select,
            'cile-button': Button,
            'cile-svg-icon': SvgIcon,
            'vue-carousel': Carousel,
            'vue-slide': Slide
        },
        data() {
			return {
                planBenefits: [
                    {
                        image: require('@assets/images/digital/beneficios-01.svg'),
                        description: 'Compartilhe o acesso com até 4 pessoas'
                    },
                    {
                        image: require('@assets/images/digital/beneficios-02.svg'),
                        description: 'Busque os conteúdos do seu interesse com facilidade'
                    },
                    {
                        image: require('@assets/images/digital/beneficios-03.svg'),
                        description: 'Salve os trechos preferidos das obras'
                    },
                    {
                        image: require('@assets/images/digital/beneficios-04.svg'),
                        description: 'Pesquise resenhas, vídeos e muito mais!'
                    },
                    {
                        image: require('@assets/images/digital/beneficios-05.svg'),
                        description: 'Leia no seu celular, tablet ou computador'
                    }
                ],
                plans: [
                    {
                        title: 'Plano Individual',
                        description: 'Acesse a biblioteca digital de livros da EBS. Você poderá pesquisar os conteúdos dos livros, além de salvar os trechos favoritos. Um portal completo com vídeos, resenhas e muito mais!',                        
                        class: 'green-bg',
                        selected: null,
                        number_of_users: 1,
                        frequency: [
                            {
                                title: 'Anual',
                                installments: 12,
                                price: '242.40',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/19?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Individual Anual&extra_fields[0][slug]=tipo_do_produto'
                            },
                            {
                                title: 'Semestral',
                                installments: 6,
                                price: '136.20',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/18?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Individual Semestral&extra_fields[0][slug]=tipo_do_produto'
                            },
                            {
                                title: 'Trimestral',
                                installments: 3,
                                price: '72.00',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/15?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Individual Trimestral&extra_fields[0][slug]=tipo_do_produto'
                            },
                            {
                                title: 'Mensal',
                                installments: 1,
                                price: '25.30',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/14?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Individual Mensal&extra_fields[0][slug]=tipo_do_produto'
                            }
                        ]
                    },
                    {
                        title: 'Plano Duo',
                        description: 'Acesse a biblioteca digital de livros da EBS. Você poderá pesquisar os conteúdos dos livros, além de salvar os trechos favoritos. Um portal completo com vídeos, resenhas e muito mais!',
                        class: 'pink-bg',
                        selected: null,
                        number_of_users: 2,
                        frequency: [
                            {
                                title: 'Anual',
                                installments: 12,
                                price: '334.80',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/22?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Duo Anual&extra_fields[0][slug]=tipo_do_produto'
                            },
                            {
                                title: 'Semestral',
                                installments: 6,
                                price: '188.40',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/21?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Duo Semestral&extra_fields[0][slug]=tipo_do_produto'
                            },
                            {
                                title: 'Trimestral',
                                installments: 3,
                                price: '99.30',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/17?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Duo Trimestral&extra_fields[0][slug]=tipo_do_produto'
                            },
                            {
                                title: 'Mensal',
                                installments: 1,
                                price: '34.90',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/16?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Duo Mensal&extra_fields[0][slug]=tipo_do_produto'
                            }
                        ]
                    },
                    {
                        title: 'Plano Premium',
                        description: 'Acesse a biblioteca digital de livros da EBS. Você poderá pesquisar os conteúdos dos livros, além de salvar os trechos favoritos. Um portal completo com vídeos, resenhas e muito mais!',
                        class: 'gray-bg',
                        selected: null,
                        number_of_users: 4,
                        frequency: [
                            {
                                title: 'Anual',
                                installments: 12,
                                price: '478.80',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/26?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Premium Anual&extra_fields[0][slug]=tipo_do_produto'
                            },
                            {
                                title: 'Semestral',
                                installments: 6,
                                price: '269.40',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/25?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Premium Semestral&extra_fields[0][slug]=tipo_do_produto'
                            },
                            {
                                title: 'Trimestral',
                                installments: 3,
                                price: '142.20',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/24?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Premium Trimestral&extra_fields[0][slug]=tipo_do_produto'
                            },
                            {
                                title: 'Mensal',
                                installments: 1,
                                price: '49.90',
                                url: 'https://cile.brasilseikyo.com.br/checkout/cart/plan/add/23?redirect_to=checkout/cart&extra_fields[0][value]=Assinatura CILE Digital Premium Mensal&extra_fields[0][slug]=tipo_do_produto'
                            }
                        ]
                    }
                ],
                faq: [
                    {
                        title: "1. O que é o CILE Digital?",
                        description: "<p>O CILE Digital é o portal de livros digitais da Editora Brasil Seikyo. Neste portal, você poderá acessar a biblioteca digital de livros da EBS, pesquisar conteúdos nos livros, salvar seus trechos favoritos, realizar anotações e organizá-las por tags. Há também resenhas, vídeos, playlists e conteúdos exclusivos para complementar a leitura.</p>"
                    },
                    {
                        title: "2. Como faço para assinar o CILE Digital?",
                        description: "<p>Você pode realizar a assinatura pelo site <a href='https://www.cile.com.br' target='_blank'>www.cile.com.br</a>.</p><p><strong>Quais são as opções de planos de assinatura?</strong></p><p><strong>Plano Individual:</strong> um assinante terá acesso a todos os conteúdos.</p><p><strong>Plano Duo:</strong> um assinante e um beneficiário terão acesso a todos os conteúdos.</p><p><strong>Plano Premium:</strong> um assinante e três beneficiários terão acesso a todos os conteúdos.</p>"
                    },
                    {
                        title: "3. Quais são as formas de pagamento?",
                        description: "Boleto bancário, Pix e cartão de crédito"
                    },
                    {
                        title: "4. Quais são as opções de parcelamento?",
                        description: "<p>Para plano de assinatura trimestral, o pagamento pode ser feito em até 3x no cartão de crédito.</p><p>Para plano de assinatura semestral, o pagamento pode ser feito em até 6x no cartão de crédito.</p><p>Para plano de assinatura anual, o pagamento pode ser feito em até 12x no cartão de crédito.</p><p>Não é possível parcelar o pagamento no Pix nem no boleto bancário.</p>"
                    },
                    {
                        title: "5. Em qual dia é feita a cobrança da assinatura?",
                        description: "<p>A cobrança será feita no mesmo dia que realizou a assinatura.</p>"
                    },
                    {
                        title: "6. Como trocar o cartão de crédito associado à minha assinatura?",
                        description: "<p>No site do CILE (<a href='https://www.cile.com.br' target='_blank'>www.cile.com.br</a>), realize o login, entre no seu perfil e clique na opção “Meus cartões”. Nesta tela, você poderá realizar a substituição do cartão.</p>"
                    },
                    {
                        title: "7. Eu tenho que fazer a assinatura todo mês?",
                        description: "<p>Não, você faz a assinatura uma única vez , na periodicidade escolhida. Se você optou pelo cartão de crédito, a cobrança será realizada mensalmente no cartão cadastrado. Nos casos de Pix e boleto bancário, enviaremos, por e-mail, as informações de pagamento na data da vigência da assinatura.</p>"
                    },
                    {
                        title: "8. O que é beneficiário?",
                        description: "<p>Beneficiário é a pessoa que você define para compartilhar sua assinatura nos planos Duo e Premium.</p>"
                    },
                    {
                        title: "9. Quantas pessoas eu posso colocar como beneficiário no meu plano?",
                        description: "<p>No plano Duo, você pode incluir 1 (uma) pessoa, já no plano Premium, você pode adicionar até 3 (três) pessoas.</p>"
                    },
                    {
                        title: "10. Posso alterar um beneficiário?",
                        description: "<p>Sim. No site do CILE Digital, no menu lateral, há a opção “Gestão de Beneficiários”. Nesse campo, você consegue excluir, alterar ou adicionar beneficiários.</p>"
                    },
                    {
                        title: "11. Como faço para cancelar a assinatura?",
                        description: "<p>No site <a href='https://www.cile.com.br' target='_blank'>www.cile.com.br</a>, dentro do seu perfil, você terá o campo “Minhas assinaturas”. Nessa página, você pode visualizar as opções de cancelamento.</p>"
                    },
                    {
                        title: "12. Como acesso a biblioteca digital de livros da EBS?",
                        description: "<p>Basta acessar o site <a href='https://www.ciledigital.com.br' target='_blank'>www.ciledigital.com.br</a> e realizar o login.</p>"
                    },
                    {
                        title: "13. Como posso salvar meus trechos favoritos?",
                        description: "<p>Na parte superior do leitor de livros, você verá a opção para salvar a página desejada. <a href='#video-tutorial'>Temos um vídeo tutorial dessa funcionalidade</a>.</p>"
                    },
                    {
                        title: "14. Onde posso fazer as anotações?",
                        description: "<p>Para fazer as tags e as anotações, é só grifar o trecho no livro e clicar em Nota/Tag, selecionar uma cor e escrever sua observação ou adicionar a tag que desejar.</p>"
                    },
                    {
                        title: "15. Onde encontro as resenhas, os vídeos, as playlists de músicas e os conteúdos exclusivos?",
                        description: "<p>No ambiente do CILE Digital, no menu lateral esquerdo, você encontra os campos “Vídeos” e “Resenhas”. No portal, também terá acesso as playlists e conteúdos exclusivos</p>"
                    },
                    {
                        title: "16. Se eu tenho a assinatura do CILE físico, terei acesso ao CILE Digital?",
                        description: "<p>Não. Para ter acesso ao CILE Digital, é preciso realizar uma nova assinatura na modalidade digital. Se você já é assinante do CILE ou de qualquer outro periódico da Editora Brasil Seikyo, tem desconto especial de 20% nas assinaturas do CILE Digital.</p>"
                    },
                    {
                        title: "17. Como posso falar com o atendimento?",
                        description: "<p>Pelos nossos canais de atendimento:</p><p>De segunda a sexta-feira, das 9h às 18h (horário de Brasília), exceto feriado.</p><p><strong>Telefone</strong> 0800-7751900</p><p><strong>WhatsApp</strong> (11) 3349-1900</p>"
                    },
                    {
                        title: "18. Quais livros terei acesso no CILE Digital?",
                        description: "<p>Os livros disponíveis para acesso aos assinantes do CILE Digital, são a coleção completa da Nova Revolução Humana e todos os lançamentos da Editora Brasil Seikyo a partir de janeiro de 2022.</p><p>Além do acesso de forma gratuita aos livros Coletâneas dos Escritos de Nichiren Daishonin, volumes 1 e 2 e também aos livros Diretrizes para o kosen-rufu, Vitória e Felicidade e Diretrizes para os Líderes que Lutam na Linha de Frente do Kosen-rufu.</p>"
                    }
                ],
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            };
        },
        methods: {
            getPlans(){
                this.plans.forEach(plan => plan.selected = plan.frequency[0]);
            },
            changePlan(plan, event){
                plan.selected = JSON.parse(event.target.options[event.target.selectedIndex].dataset.frequency);
            },
            planPrice(price, installments){
                price = (price / installments).toFixed(2);
                price = price.toString().split('.');

                if(price[1].length == 1) price[1] = price[1] + '0';

                let priceHtml = `<sup class="currency">R$</sup>${price[0]},<span class="decimal">${price[1]}</span>`;

                if(installments > 1) priceHtml += "<span class='month'>/mês</span>";

                priceHtml += "<sup class='asterisks'>**</sup>";

                return priceHtml;
            },
            planTotalPrice(price){
                price = price.toString().split('.');

                if(price[1].length == 1) price[1] = price[1] + '0';

                return `total R$ ${price}`;
            }
        },
        created() {
            this.getPlans();
        },
        mounted(){            
            const bulmaCollapsibleInstances = bulmaCollapsible.attach('.is-collapsible');
        }
	};
</script>

<style lang="scss">
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';
    
    .know-our-plans.plans-page {
        .main-banner .content-box,
        .a-new-experience,
        .subscription-plan-benefits,
        .all-contents,
        .description-of-plans,
        .more-about-cile,
        .frequently-asked-questions {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    .know-our-plans.plans-page {
        > header {
            padding: 15px 25px;

            .logo {
                max-width: 140px;
                margin-right: 25px;
            }

            .btn-login {
                min-width: 150px;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        > header .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        header {
            .horizontal-line {
                width: 70px;
                height: 3px;
                margin-left: 0px;
                margin-top: 25px;
            }
        }

        h1 {
            @include font-config(36px, 40px, map.get($font-weights, 'bold'));
        }
    }

    .know-our-plans.plans-page .description-of-plans {
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #F5F2ED;
        
        header {
            margin-bottom: 50px;
            
            h1,
            p {
                font-size: 36px;
                line-height: 40px;
                color: #2E3337;
            
                text-align: center;
            }

            .horizontal-line {
                margin-left: auto;
                margin-right: auto;
                background-color: #006F75;
            }
        }
        
        .plan-description {
            padding: 25px 25px 50px 25px;
            background-color: #F5F2ED;
        
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &.green-bg {
                background-color: #5AB7B4;
            }

            &.pink-bg {
                background-color: #DD9F9F;
            }

            &.gray-bg {
                background-color: #6B7277;
            }

            .image {
                max-width: 50px;
                margin: 0px auto 15px auto;
            }

            h2,
            p {
                color: #ffffff;
            }        

            h2 {
                font-size: 22px;
                line-height: 26px;
            
                margin-bottom: 0px;
            }

            .horizontal-line {
                width: 100px;
                height: 2px;
                margin-left: auto;
                margin-right: auto;
                background-color: #ffffff;
            }

            .plan-description-list {
                padding: 0px;
                margin-bottom: 60px;
                list-style: none;
                
                li {
                    font-size: 20px;
                    line-height: 24px;
                    text-align: left;
                
                    display: flex;
                    align-items: center;
                    
                    &:not(:last-child) {
                        margin-bottom: 35px;
                    }
                    
                    img {
                        width: 30px;
                        margin-right: 15px;
                    }
                }
            }

            .button {
                color: #009C98;

                width: 100%;
                min-width: auto;
                border-radius: 5px;
                box-shadow: 0px 3px 6px #00000029;
            }

            .description {
                margin-bottom: 25px;
                
                * {
                    font-size: 16px;
                    line-height: 20px;
                }
            }        
            
            .select-box {
                position: relative;
                
                &:after {
                    content: '';
                
                    pointer-events: none;
                    
                    position: absolute;
                    top: calc(50% - 3px);
                    right: 12px;
                    
                    width: 0; 
                    height: 0; 
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    
                    border-top: 6px solid #707070;
                }
                
                select {    
                    border-radius: 25px;
                
                    -webkit-appearance: none;
                    -moz-appearance: none;
                }

                .form-control {
                    outline: 0;
                    display: block;
                    width: 100%;
                    height: calc(1.5em + 0.75rem + 2px);
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #495057;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                }
            }

            .plan-price {
                font-size: 70px;
                line-height: 70px;
                font-weight: 600;
            
                margin: 35px 0px;
                
                .currency {
                    font-size: 25px;
                    line-height: 29px;
                
                    top: 10px;
                    margin-right: 5px;
                    vertical-align: top;
                }
                
                .decimal {
                    font-size: 40px;
                    line-height: 44px;
                }

                .month {
                    font-size: 28px;
                    line-height: 24px;
                }

                .asterisks {
                    font-size: 25px;
                    line-height: 29px;
                    
                    top: 10px;
                    margin-right: 5px;
                    vertical-align: top;
                }
            }

            .number-of-users {
                display: flex;
                align-items: center;
                justify-content: center;

                margin-bottom: 25px;
            
                .users-icon {
                    margin-right: 10px;

                    img:not(:last-child) {
                        margin-right: 5px;
                    }
                }
            }
        }
        
        .footnote {
            @include font-config(16px, 20px, map.get($font-weights, 'regular'), center, #6B7277);

            margin-top: 35px;

            &:last-child {
                margin-top: 15px;
            }
        }
    }

    @media screen and (min-width: 992px), print {
        .know-our-plans.plans-page .description-of-plans .plan-description {
            height: 100%;
        }
    }


    .know-our-plans.plans-page .banner {
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #FCFBFA;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .know-our-plans.plans-page .banner .container {
        display: flex;
        align-items: center;
    }

    .know-our-plans.plans-page .banner.a-new-experience {
        background-image: url('~@assets/images/digital/nova-experiencia-bg.svg');
        background-position: top right;

        .container {
            .area-1 {
                width: 450px;
            }

            header {
                margin-bottom: 25px;

                h1 {
                    color: #006F75;
                }

                .horizontal-line {
                    background-color: #EF6D6C;
                }
            }

            .img-box {
                width: calc(100% - 450px);
                padding-left: 50px;
            }

            .button {
                min-width: 250px;
            }
        }
    }

    @media screen and (max-width: 992px), print {
        .know-our-plans.plans-page .banner.a-new-experience {
            .container {
                flex-direction: column;

                .area-1,
                .area-2 {
                    width: 100%;
                }

                .area-1 {                
                    order: 1;

                    text-align: center;

                    h1 {
                        text-align: center;
                    }

                    .horizontal-line {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .area-2 {
                    text-align: center;
            
                    order: 0;
                    padding-left: 0px;
            
                    margin-bottom: 35px;
                }
            }
        }
    }

    .know-our-plans.plans-page .banner.all-contents {
        background-image: url('~@assets/images/digital/bg-conteudos.svg');
        background-position: bottom left;
        
        .container {
            .area-2 {
                width: 500px;
            }

            header {
                margin-bottom: 25px;

                h1 {
                    color: #F38480;
                }
            }

            .img-box {
                width: calc(100% - 500px);
                padding-right: 50px;

                img {
                    width: 100%;
                }
            }

            .button {
                min-width: 250px;
            }
        }
    }

    @media screen and (max-width: 992px), print {
        .know-our-plans.plans-page .banner.all-contents {
            .container {
                flex-direction: column;
                
                .area-1,
                .area-2 {
                    width: 100%;
                }
                
                .area-1 {
                    order: 1;
                    
                    text-align: center;
            
                    padding-right: 0px;
                }
                
                .area-2 {                
                    order: 0;

                    text-align: center;
                    
                    margin-bottom: 35px;

                    h1{
                        text-align: center;
                    }
                }
            }
        }
    }

    .know-our-plans.plans-page .subscription-plan-benefits {
        padding-top: 70px;
        padding-bottom: 70px;
        
        .plan-benefits {
            text-align: center;
            padding: 0px 15px;

            img {
                max-height: 170px;
            
                margin-bottom: 25px;
            }

            p {
                color: #3C4349;
            }
        }
    }

    .know-our-plans.plans-page .more-about-cile {
        padding-top: 25px;
        padding-bottom: 25px;
        background-color: #ffffff;

        header {
            h1 {
                color: #2E3337;
                font-weight: 400;
            }

            .horizontal-line {
                margin-left: auto;
                margin-right: auto;
                background-color: #006F75;
            }
        }

        .video {
            margin-top: 25px;

            .iframe-wrapper {
                width: 100%;
                height: 100%;
                margin: 0 auto;
                overflow: hidden;
                border-radius: 10px;

                .iframe-content {
                    position: relative;
                    padding-top: 56%;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .know-our-plans.plans-page .frequently-asked-questions {
        padding-top: 50px;
        padding-bottom: 50px;

        header {
            h1 {
                font-weight: 400;            
            }

            .horizontal-line {
                margin-left: auto;
                margin-right: auto;
                background-color: #006F75;
            }
        }

        &,
        .accordion .message .message-header,
        .accordion .message .message-body {
            background-color: #FCFBFA;
        }
        
        .accordion .message {
            border: none;
            margin-bottom: 0px;
            border-bottom: 1px solid #CCCCCC;
            
            .message-header {
                padding: 20px 15px;
                border: none;
                
                a {
                    @include font-config(20px, 24px, map.get($font-weights, 'regular'), left, #000000);                

                    text-decoration: none;

                    width: 100%;
                    position: relative;

                    &:after {
                        content: '';
                    
                        position: absolute;
                        top: calc(50% - 8px);
                        right: 0px;
                        display: block;
                        width: 10px;
                        height: 10px;
                        border: 2px solid #000000;
                        border-left: none;
                        border-top: none;
                        
                        -webkit-transform: rotate(45deg);
                                transform: rotate(45deg);
                    
                        transition: transform .5s ease;
                    }
                }

                &:has(~ .is-collapsible.is-active) a:after {
                    -webkit-transform: rotate(225deg);
                            transform: rotate(225deg);
                }
            }
            
            .card-body {
                padding-left: 0.75rem;
                padding-right: 0.75rem;
            }

            .message-body-content {
                p:not(:last-child) {
                    margin-bottom: 10px;
                }

                a,
                a:hover {
                    color: #485fc7;
                }
            }
        }
    }

    @media screen and (max-width: 768px), print {
        .know-our-plans.plans-page .frequently-asked-questions .accordion .card .card-header h2 button {
            font-size: 18px;
            line-height: 22px;
        }
    }

    .know-our-plans.plans-page .main-banner {
        position: relative;
        
        .video-box {
            background-repeat: no-repeat; 
            background-position: center center; 
            background-attachment: inherit; 
            background-size: contain; 
            opacity: 1;
            
            video {
                max-width:100%;
                width: 100%;
                height:auto;
                display:block;
                margin:0 auto;
            }

            &.video-mobile {
                display: none;
            }
        }

        .content-box {
            display: flex;
            align-items: center;
        
            position: absolute;
            z-index: 999;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            
            .container {
                max-width: 1000px;
        
                .content {
                    margin: 0px auto 45px auto;
                    max-width: 750px;
                }
                
                h1 {
                    @include font-config(48px, 56px, map.get($font-weights, 'bold'), center, #ffffff);
                
                    margin-bottom: 35px;
                }
                
                p {
                    @include font-config(21px, 23px, map.get($font-weights, 'semi-bold'), center, #ffffff);
                }
                
                .button {
                    display: block;
                    margin: 0 auto;
                    min-width: 250px;
                    max-width: 250px;
                }

                .try-it-now-content {
                    p {
                        @include font-config(16px, 22px, map.get($font-weights, 'regular'), center);
                    }

                    .price {
                        @include font-config(20px, 24px)
                    }
                    
                    > *:not(:last-child) {
                        margin-bottom: 25px;
                    }
                } 
            }        
        }
        
        .footer-content-box {
            @include font-config(21px, 23px, map.get($font-weights, 'regular'), center, #ffffff);
            
            padding: 20px;
        
            position: absolute;
            z-index: 999;
            left: 0px;
            bottom: 0px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.25);
        }
    }

    @media screen and (max-width: 992px), print {
        .know-our-plans.plans-page .main-banner {
            .content-box {
                .container {
                    margin-bottom: 30px;
                    
                    h1 {
                        font-size: 26px;
                        line-height: 32px;
                
                        margin-bottom: 20px;
                    }
                    
                    p {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }    
    }

    @media screen and (max-width: 650px), print {
        .know-our-plans.plans-page .main-banner {
            .video-box.video-desktop {
                display: none;
            }

            .video-box.video-mobile {
                display: block;
            }
        }
    }
</style>