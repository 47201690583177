<template>
    <div 
        :id="id"
        class="icon svg-icon-component"
        :class="className"
        v-html="content"
        @click="buttonClickEvent" />
</template>

<script>
    export default {
        props: {
            src: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: false
            },
            className: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                content: ''
            }
        },
        methods: {
            getIcon(src){
                this.$axios({
                        method: 'GET',
                        baseURL: '/',
                        url: src
                    })
                    .then(res => this.content = res.data);
            },
            buttonClickEvent(){
                this.$emit('customClickEvent');
            }
        },
        created(){
            this.getIcon(this.src);
        },
        watch: {          
            'src': {
                handler: function(){
                    this.getIcon(this.src);
                }
            }
        },
    }
</script>

<style>
    .svg-icon-component.icon {
        width: initial;
        height: initial;
    }
</style>