<template>
    <section class="default-session" :class="loadingContent ? 'loading medium' : ''">
        <header v-show="!loadingContent && !expiredSubPlan">
            <h1>Continue lendo</h1>
        </header>

        <cile-message :alert="alert" />

        <div v-show="!loadingContent" class="columns is-multiline is-mobile">
            <div v-for="(book, index) in bookShowcase" :key="`last_books_bought_${index}`"
                class="column is-12-mobile is-4-tablet is-2-fullhd">
                <cile-book-showcase :book="book" :linkBookDetail="true" :toReader="true" />
            </div>
        </div>
        <div v-show="!loadingContent && !expiredSubPlan" class="horizontal-line"></div>
    </section>
</template>

<script>
import Message from '@components/shared/message/Message.vue';
import BookShowcase from '@components/cile/BookShowcase.vue';

export default {
    name: 'KeepReading',
    components: {
        'cile-message': Message,
        'cile-book-showcase': BookShowcase
    },
    data() {
        return {
            bookShowcase: [],
            expiredSubPlan: false,
            loadingContent: true,
            alert: {
                message: '',
                type: '',
                show: false
            }
        }
    },
    methods: {
        getBooks() {
            this.alert.show = false;
            this.loadingContent = true;

            this.$axios
                .get(
                    'books/books-im-reading',
                    { headers: { 'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}` } }
                )
                .then((res) => {
                    if (res.status == 204) this.$Utilities.controlAlert(null, this.alert, 'warning', 'Você não está lendo nenhum livro no momento.');

                    this.bookShowcase = res.data;
                })
                .catch(error => {
                    if (error.response.status == 404 && error.response.data.action == 'expiredSubPlan') {
                        this.expiredSubPlan = true;
                    } else {
                        this.$Utilities.controlAlert(error.response.data, this.alert, 'danger');
                    }
                })
                .finally(() => this.loadingContent = false);
        }
    },
    created() {
        this.getBooks();
    }
}
</script>

<style lang="scss"></style>