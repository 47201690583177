<template>
    <div v-if="type == 'open-link'">
        <!-- <button :class="secondButton.class" @click="openLinkInNewTab(openLink)">Open Link in New Tab</button> -->
        <div class="field button-component">
            <label v-if="label" class="label">{{ label }}</label>

            <div class="control" :class="buttonAlignment">
                <!-- <router-link :to="{ name: route, params: routeParams }" class="button primary-button" :class="style"
                    :is="disabled || isDisabled ? 'span' : 'router-link'" :disabled="disabled || isDisabled">{{ title
                    }}</router-link> -->
                    <a class="button primary-button" @click="openLinkInNewTab(openLink)" :class="style">Ver resenha!</a>
            </div>
        </div>
    </div>

    <div v-else-if="type == 'route'" class="field button-component">
        <label v-if="label" class="label">{{ label }}</label>

        <div class="control" :class="buttonAlignment">
            <router-link :to="{ name: route, params: routeParams }" class="button primary-button" :class="style"
                :is="disabled || isDisabled ? 'span' : 'router-link'" :disabled="disabled || isDisabled">{{ title
                }}</router-link>

            <button v-if="secondButton.type == 'button'" v-show="secondButton.visible" type="button"
                @click="clickCancelEvent()" class="button cancel secondary-button" :class="secondButton.class"
                :disabled="loading">{{ secondButton.title }}</button>

            <router-link v-if="secondButton.type == 'route'" v-show="secondButton.visible"
                :to="{ name: secondButton.route }" class="button cancel secondary-button" :class="secondButton.class">{{
        secondButton.title }}</router-link>
        </div>
    </div>

    <div v-else class="field button-component">
        <label v-if="label" class="label">{{ label }}</label>

        <div class="control" :class="buttonAlignment">
            <button v-if="type == 'button' || type == 'submit'" v-show="mainShow" :type="type" @click="clickEvent()"
                class="button primary-button" :class="style" :disabled="isDisabled || secondButton.loading">{{ title
                }}</button>

            <a v-if="type == 'link'" :href="href" @click="clickEvent()" class="button primary-button" :class="style"
                :disabled="isDisabled || secondButton.loading" target="_blank">{{ title }}</a>

            <button v-if="secondButton.type == 'button'" v-show="secondButton.visible" type="button"
                @click="clickCancelEvent()" class="button cancel secondary-button"
                :class="[secondButton.class, secondButton.loading ? 'is-loading' : '']" :disabled="loading">{{
        secondButton.title }}</button>

            <router-link v-if="secondButton.type == 'route'" v-show="secondButton.visible"
                :to="{ name: secondButton.route }" class="button cancel secondary-button" :class="secondButton.class"
                :disabled="loading">{{ secondButton.title }}</router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            required: true,
            type: String
        },
        title: {
            required: true,
            type: String
        },
        label: {
            required: false,
            type: String
        },
        route: {
            required: false
        },
        routeParams: {
            required: false,
            type: Object
        },
        href: {
            required: false,
            type: String
        },
        icon: {
            required: false,
            type: false
        },
        buttonStyle: {
            required: false,
            default: '',
            type: String
        },
        isCentralized: {
            required: false,
            type: Boolean,
            default: false
        },
        alignment: {
            required: false,
            type: String,
            default: ''
        },
        loading: {
            required: false,
            default: false,
            type: Boolean
        },
        isDisabled: {
            required: false,
            default: false,
            type: Boolean
        },
        disabled: {
            required: false,
            default: false,
            type: Boolean
        },
        secondButton: {
            required: false,
            type: Object,
            default: () => {
                return {
                    type: '',
                    route: '',
                    title: '',
                    class: '',
                    visible: true,
                    loading: false
                }
            }
        },
        mainShow: {
            required: false,
            default: true,
            type: Boolean
        },
        openLink: {
            required: false,
            default: '',
            type: String
        }
    },
    methods: {
        clickEvent() {
            this.$emit('customClickEvent');
        },
        clickCancelEvent() {
            this.$emit('customClickSecondEvent');
        },
        openLinkInNewTab(link) {
            // console.log("@@@@@",e);
            // Specify the URL you want to open
            const url = link;

            // Open the URL in a new tab
            window.open(url, '_blank');
        }
    },
    computed: {
        style() {
            return this.loading ? `${this.buttonStyle} is-loading` : this.buttonStyle;
        },
        buttonAlignment() {
            let alignmentClass = '';

            if (this.isCentralized) return 'has-text-centered';

            switch (this.alignment) {
                case 'left':
                    alignmentClass = 'has-text-left';
                    break;

                case 'right':
                    alignmentClass = 'has-text-right';
                    break;

                case 'centered':
                    alignmentClass = 'has-text-centered';
                    break;

                case 'justified':
                    alignmentClass = 'is-justified';
                    break;
            }

            return alignmentClass;
        }
    }
}
</script>

<style lang="scss" scoped>
@use "sass:map";
@import '~@assets/css/config.scss';

.button-component {
    .control.is-justified {
        display: flex;
        justify-content: space-between;
    }

    .text-button {
        height: auto;
        border: 1px solid transparent;
        border-radius: 12px;
        min-width: 160px;
        background: none;
        cursor: pointer;
    }
}
</style>