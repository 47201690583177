<template>
    <div 
        class="logged-area light-mode ebook-reader" 
        :class="[
            configurationOptions.theme,
            features.summary.opened ? 'show-summary' : '',
            features.search.opened ? 'show-search' : '',
            features.annotation.opened ? 'show-annotation' : ''
        ]">
        <header id="main-header" class="main-header">
            <div class="mobile-logo has-text-centered">
                <router-link :to="{name: 'dashboard'}">
                    <figure class="image logo">
                        <img src="~@assets/images/logo_cile_topo.svg" />
                    </figure>
                </router-link>
            </div>

            <div class="container">
                <div class="column-1">
                    <router-link :to="{name: 'dashboard'}">
                        <figure class="desktop-logo image logo">
                            <img src="~@assets/images/logo_cile_topo.svg" />
                        </figure>
                    </router-link>

                    <button class="btn-chapter" @click="features.summary.opened ? closeSummary() : openSummary()">
                        <figure class="image reader-icon">
                            <cile-svg-icon v-show="!features.summary.opened" :src="require(`@assets/images/icons/reader/menu.svg`)" />
                            <cile-svg-icon v-show="features.summary.opened" :src="require(`@assets/images/icons/reader/voltar.svg`)" />
                        </figure>
                    </button>

                    <router-link 
                        :to="{name: 'myBooks'}" 
                        class="btn-my-books">
                        <cile-svg-icon class="reader-icon" :src="require(`@assets/images/icons/reader/livros.svg`)" /><span>Meus livros</span></router-link>
                </div>

                <div class="column-2">
                    <h1 class="book-title">{{ metadata != null ? metadata.title : '' }}</h1>
                    <p class="book-chapter">{{ currentChapter }}</p>
                </div>

                <div class="column-3">
                    <ul class="reader-menu">
                        <li v-show="!features.search.opened">
                            <button class="btn-search" @click="openSearch()">
                                <figure class="image reader-icon">
                                    <cile-svg-icon :src="require(`@assets/images/icons/reader/lupa.svg`)" />
                                </figure>
                                <p>Busca</p>
                            </button>
                        </li>

                        <li>
                            <button v-show="!loadingBookmark" class="btn-appointments" @click="createBookmark()">
                                <figure class="image reader-icon">
                                    <cile-svg-icon :src="require(`@assets/images/icons/reader/marcacao.svg`)" />
                                </figure>
                                <p>Marcar</p>
                            </button>

                            <div v-show="loadingBookmark" class="loading reader-option-loading"></div>
                        </li>

                        <li>
                            <button class="btn-config" @click="toggleConfigurationOptionsModal()">
                                <figure class="image reader-icon">
                                    <cile-svg-icon :src="require(`@assets/images/icons/reader/config.svg`)" />
                                </figure>
                                <p>Opções</p>
                            </button>
                        </li>

                        <li>
                            <button class="btn-annotation" @click="features.annotation.opened ? closeAnnotation() : openAnnotation()">
                                <figure class="image reader-icon">
                                    <cile-svg-icon v-show="!features.annotation.opened" :src="require(`@assets/images/icons/reader/notas.svg`)" />
                                    <cile-svg-icon v-show="features.annotation.opened" class="flip-element" :src="require(`@assets/images/icons/reader/voltar.svg`)" />
                                </figure>
                                <p v-show="!features.annotation.opened">Notas</p>
                            </button>
                        </li>

                        <li v-show="features.search.opened">
                            <button @click="closeSearch()">
                                <figure class="image reader-icon">
                                    <cile-svg-icon class="flip-element" :src="require(`@assets/images/icons/reader/voltar.svg`)" />
                                </figure>
                            </button>
                        </li>
                    </ul>                
                </div>
            </div>

            <article 
                class="message is-primary floating-alert" 
                :class="[
                    features.annotation.copyAlert.show ? 'is-active' : '',
                    features.annotation.copyAlert.type ? `is-${features.annotation.copyAlert.type}` : ''
                ]">
                <div class="message-body has-text-centered">{{ features.annotation.copyAlert.message }}</div>
            </article>
        </header>

        <div 
            v-show="features.annotation.tooltip.open" 
            id="annotation-tooltip"
            class="annotation-tooltip" 
            :style="{
                top: `${features.annotation.tooltip.top}px`, 
                left: `${features.annotation.tooltip.left}px`
            }">
            <div>
                <div class="ml-2 mr-2">
                    <div class="color-list pt-3 pb-3">
                        <div 
                            v-for="(color, key) in features.annotation.colorList.slice(0, 4)"
                            :key="`color-option-tooltip-${key}`" 
                            class="color-option"
                            :class="features.annotation.data.color == color ? 'selected' : ''"
                            :style="{backgroundColor: `rgb(${color})`, borderColor: `rgb(${color})`}"
                            @click="setAnnotationColor(color)"></div>
                    </div>
                </div>

                <div class="division"></div>

                <div class="ml-2 mr-2">
                    <button class="btn-annotation pt-3 pb-3" @click="openAnnotationModal()">
                        <figure class="image">
                            <cile-svg-icon :src="require(`@assets/images/icons/reader/notas.svg`)" />
                        </figure>
                        Nota / Tag
                    </button>
                </div>
            </div>

            <input v-show="false" id="clipboard" ref="clipboard" type="text" />

            <div>
                <button class="btn-annotation" @click="saveAnnotation()">Salvar</button>

                <button class="btn-annotation btn-annotation-copy" @click="copyAnnotation()">Copiar</button>

                <button class="btn-annotation btn-annotation-cancel" @click="cancelAnnotationTooltip()">Cancelar</button>
            </div>
        </div>

        <div id="main-content" class="main main-content">
            <div class="container">
                <div 
                    class="reader" 
                    :class="features.search.opened || features.annotation.opened ? 'featured-opened' : ''">
                    <button id="reader-prev" type="button" class="reader-prev" @click="prevPage()">
                        <figure class="image prev-icon">
                            <cile-svg-icon :src="require(`@assets/images/icons/reader/prev.svg`)" />
                        </figure>
                    </button>

                    <div id="reader-content" class="reader-content"></div>

                    <button id="reader-next" type="button" class="reader-next" @click="nextPage()">
                        <figure class="image next-icon">
                            <cile-svg-icon :src="require(`@assets/images/icons/reader/next.svg`)" />
                        </figure>
                    </button>
                </div>

                <div v-show="this.reader.book != null && this.reader.book.locations.total > 0" class="book-progress">
                    <div class="progress" :style="{ width: `${reader.percentage}%` }"></div>
                </div>
            </div>

            <div class="ebook-summary reader-sidebar custom-scroll">
                <div class="reader-sidebar-header">
                    <div class="sidebar-menu">
                        <button class="is-cursor-pointer" :class="features.summary.openList ? 'is-active' : ''" @click="openSummaryList()">
                            <figure class="image menu-icon reader-icon">
                                <cile-svg-icon :src="require(`@assets/images/icons/reader/menu.svg`)" />
                            </figure>
                        </button>

                        <button class="is-cursor-pointer" :class="features.bookmark.openList ? 'is-active' : ''" @click="openBookmarkList()">
                            <figure class="image menu-icon reader-icon">
                                <cile-svg-icon :src="require(`@assets/images/icons/reader/marcacao.svg`)" />
                            </figure>
                        </button>
                    </div>

                    <figure class="image menu-icon reader-icon is-cursor-pointer" @click="closeSummary()">
                        <cile-svg-icon :src="require(`@assets/images/icons/reader/close.svg`)" />
                    </figure>
                </div>

                <p class="book-title">{{ metadata != null ? metadata.title : '' }}</p>

                <cile-message :alert="features.bookmark.alert" />

                <ul v-show="features.summary.openList" class="chapter-list">
                    <li 
                        v-for="chapter in features.summary.chapters" 
                        :key="`chapter-${chapter.id}`"
                        class="chapter"><a href="#" @click="goToChapter(chapter.href)">{{ chapter.label }}</a></li>
                </ul>

                <ul v-show="features.bookmark.openList" class="chapter-list">
                    <li 
                        v-for="bookmark in features.bookmark.list" 
                        :key="`bookmark-${bookmark.id}`"
                        class="chapter bookmark">
                        <a href="#" @click="goToChapter(bookmark.location)">
                            <span class="has-text-weight-semibold">{{ bookmark.chapter }}</span> - <span>{{ bookmark.book_excerpt }}</span>
                        </a>

                        <button 
                            type="button" 
                            class="delete-bookmark" 
                            :disabled="bookmark.loading" 
                            @click="deleteBookmark(bookmark)">
                            <figure 
                                class="image" 
                                :class="bookmark.loading ? 'loading small' : ''" >
                                <cile-svg-icon v-show="!bookmark.loading" :src="require(`@assets/images/icons/reader/close.svg`)" />
                            </figure>
                        </button>
                    </li>
                </ul>
            </div>

            <div class="ebook-search reader-sidebar">
                <div class="reader-sidebar-header">
                    <p class="has-text-weight-bold">Pesquisar</p>
                    
                    <figure class="image menu-icon reader-icon is-cursor-pointer" @click="closeSearch()">
                        <cile-svg-icon :src="require(`@assets/images/icons/reader/close.svg`)" />
                    </figure>
                </div>

                <form id="form-search" class="form-search" @submit.prevent="search">
                    <div class="search-input-box">
                        <cile-input
                            placeholder="Pesquisar neste livro"
                            class="search-input"
                            v-model="features.search.filter"
                            :disabled="features.search.loading" />

                        <cile-svg-icon 
                            :src="require(`@assets/images/icons/reader/lupa.svg`)"
                            class="is-cursor-pointer"
                            @customClickEvent="search" />
                    </div>
                </form>

                <div class="search-result-list custom-scroll" :class="features.search.loading ? 'loading medium' : ''">
                    <div v-show="!features.search.loading && features.search.result.length == 0" class="search-result-item">
                        <div class="content">
                            <p class="has-text-centered is-italic">Nenhum resultado foi encontrado.</p>
                        </div>
                    </div>

                    <article 
                        v-show="!features.search.loading"
                        v-for="(result, index) in features.search.result" 
                        :key="`search-result-${index}`"
                        class="search-result-item"
                        @click="selectSearchItem(result.cfi)">
                        <div class="content">
                            <p class="result-title">{{ result.chapter }}</p>
                            <p class="result-book-excerpt" v-html="result.excerpt"></p>
                        </div>
                    </article>
                </div>
            </div>

            <div id="ebook-annotation" class="ebook-annotation reader-sidebar custom-scroll">
                <div class="reader-sidebar-header">
                    <p class="has-text-weight-bold">Notas e destaque</p>

                    <figure class="image menu-icon reader-icon is-cursor-pointer" @click="closeAnnotation()">
                        <cile-svg-icon :src="require(`@assets/images/icons/reader/close.svg`)" />
                    </figure>
                </div>

                <cile-message :alert="features.annotation.alert" />

                <div class="annotation-result-list" :class="features.annotation.loadingContent ? 'loading medium' : ''">
                    <cile-annotations 
                        v-for="(annotation, index) in features.annotation.list"
                        :key="`annotation-${index}`"
                        :annotation="annotation"
                        @annotationClickEvent="annotationClickEvent"
                        @editClickEvent="loadAnnotationModal"
                        @deleteClickEvent="deleteAnnotation" />
                </div>
            </div>

            <div class="modal modal-configuration-option" :class="configurationOptions.opened ? 'is-active' : ''">
                <div class="modal-background"></div>
            
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Opções</p>
                    </header>

                    <section class="modal-card-body">
                        <div class="configuration-option config-font-size">
                            <p class="configuration-option-title">Tamanho da fonte</p>

                            <div class="options-list">
                                <div 
                                    class="option-item font-size-option-1" 
                                    :class="configurationOptions.font.fontSize == 14 ? 'selected' : ''"
                                    @click="setFontSize(14)">Aa</div>

                                <div 
                                    class="option-item font-size-option-2" 
                                    :class="configurationOptions.font.fontSize == 20 ? 'selected' : ''"
                                    @click="setFontSize(20)">Aa</div>

                                <div 
                                    class="option-item font-size-option-3" 
                                    :class="configurationOptions.font.fontSize == 26 ? 'selected' : ''"
                                    @click="setFontSize(26)">Aa</div>

                                <div 
                                    class="option-item font-size-option-4" 
                                    :class="configurationOptions.font.fontSize == 31 ? 'selected' : ''"
                                    @click="setFontSize(31)">Aa</div>

                                <div 
                                    class="option-item font-size-option-5" 
                                    :class="configurationOptions.font.fontSize == 37 ? 'selected' : ''"
                                    @click="setFontSize(37)">Aa</div>
                            </div>
                        </div>
                    
                        <div class="configuration-option config-color">
                            <p class="configuration-option-title">Modo de Cor</p>

                            <div class="options-list">
                                <div 
                                    class="option-item option-white" 
                                    :class="configurationOptions.theme == 'padrao' ? 'selected' : ''"
                                    @click="setTheme('padrao')">Branco</div>

                                <div 
                                    class="option-item option-black"
                                    :class="configurationOptions.theme == 'dark' ? 'selected' : ''"
                                    @click="setTheme('dark')">Preto</div>

                                <div 
                                    class="option-item option-sepia"
                                    :class="configurationOptions.theme == 'sepia' ? 'selected' : ''"
                                    @click="setTheme('sepia')">Sépia</div>
                            </div>
                        </div>
                    </section>

                    <footer class="modal-card-foot">
                        <cile-button 
                            type="button" 
                            title="Aplicar" 
                            buttonStyle="button btn-green"
                            :secondButton="{
                                type: 'button', 
                                title: 'Cancelar', 
                                class: 'btn-grey', 
                                visible: true
                            }"
                            @customClickEvent="confirmConfigurationOptions()"
                            @customClickSecondEvent="resetConfigurationOptions()" />
                    </footer>
                </div>
            </div>

            <div class="modal modal-configuration-option" :class="features.annotation.openModal ? 'is-active' : ''">
                <div class="modal-background"></div>

                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Notas e Destaque</p>
                    </header>

                    <section class="modal-card-body">
                        <div class="configuration-option annotation-config-color">
                            <p class="configuration-option-title">Selecione a Cor</p>

                            <div class="color-list">
                                <div 
                                    v-for="(color, key) in features.annotation.colorList"
                                    :key="`color-option-${key}`" 
                                    class="color-option"
                                    :class="features.annotation.data.color == color ? 'selected' : ''"
                                    :style="{backgroundColor: `rgb(${color})`, borderColor: `rgb(${color})`}"
                                    @click="setAnnotationColor(color)"></div>
                            </div>
                        </div>

                        <div class="configuration-option">
                            <p class="configuration-option-title">Adicionar nota</p>

                            <cile-textarea
                                placeholder="Escreva aqui"
                                v-model="features.annotation.data.description" />
                        </div>

                        <div class="configuration-option">
                            <p class="configuration-option-title">Adicionar Tag</p>

                            <cile-input
                                type="inputButton"
                                placeholder="Escreva aqui" 
                                v-model="features.annotation.data.tag"
                                @customButtonClickEvent="addAnnotationTag()" />
                        </div>

                        <div class="configuration-option">
                            <p class="configuration-option-title">Tags adicionadas</p>

                            <p v-show="features.annotation.data.tags.length == 0" class="is-italic">Nenhuma tag adicionada</p>

                            <div v-show="features.annotation.data.tags.length > 0" class="tags-list">
                                <div 
                                    v-for="(tag, key) in features.annotation.data.tags"
                                    :key="`tag-item-${key}`"
                                    class="tag-item color-1">{{ tag }}</div>
                            </div>
                        </div>
                    </section>

                    <footer class="modal-card-foot">
                        <cile-button 
                            type="button" 
                            title="Aplicar" 
                            buttonStyle="button btn-green"
                            :secondButton="{
                                type: 'button', 
                                title: 'Cancelar', 
                                class: 'btn-grey', 
                                visible: true
                            }"
                            :loading="features.annotation.loadingModal"
                            @customClickEvent="saveAnnotationModal()"
                            @customClickSecondEvent="cancelAnnotationModal()" />
                    </footer>
                </div>
            </div>

            <div class="modal modal-footnote" :class="features.footnote.openModal ? 'is-active' : ''">
                <div class="modal-background"></div>
            
                <div class="modal-card">
                    <header class="modal-card-head"></header>

                    <section class="modal-card-body">
                        <div v-html="features.footnote.selected" class="has-text-centered"></div>
                    </section>

                    <footer class="modal-card-foot">
                        <cile-button 
                            type="button" 
                            title="Fechar" 
                            class="has-text-centered"
                            buttonStyle="button btn-grey"
                            @customClickEvent="closeFootnoteModal()" />
                    </footer>
                </div>
            </div>
        </div>

        <div v-show="loadingContent" class="stand-by loading large"></div>

        <footer id="main-footer" class="main-footer">
            <div class="lb-seal">
                <p class="">
                    Made with
                    <img src="~@assets/images/icons/coracao.svg" class="buy-button" />
                    by <a href="https://littleboat.com.br/" target="_blank">Little Boat</a>
                </p>
            </div>
        </footer>
    </div>
</template>

<script>
    import Input from '@components/shared/fields/Input.vue';
    import Message from '@components/shared/message/Message.vue';
    import Textarea from '@components/shared/fields/Textarea.vue';
    import Button from '@components/shared/button/Button.vue';
    import SvgIcon from '@components/shared/images/SvgIcon.vue';
    import Annotations from '@components/cile/Annotation.vue';

    export default {
        name: 'EbookReader',
        components: {
            'cile-input': Input,
            'cile-message': Message,
            'cile-textarea': Textarea,
            'cile-button': Button,
            'cile-svg-icon': SvgIcon,
            'cile-annotations': Annotations
        },
        data() {
			return {
                bookId: 1,
                filter: '',
                filterCfiRange: '',
                keyword: '',
                readerAction: '',
                metadata: null,
                summary: [],
                reader: {
                    book: null,
                    rendition: null,
                    displayed: null,
                    currentHeight: 0,
                    locations: null,
                    percentage: 0
                },
                features: {
                    summary: {
                        opened: false,
                        openList: false,
                        chapters: []
                    },
                    bookmark: {
                        openList: true,
                        list: [],
                        alert: {
                            message: '',
                            type: '',
                            show: false
                        }
                    },
                    search: {
                        opened: false,
                        loading: false,
                        filter: '',
                        matches: [],
                        result: [],
                        markInstance: null
                    },
                    annotation: {
                        opened: false,
                        openModal: false,
                        loadingModal: false,
                        loadingContent: false,
                        loaded: false,
                        alert: {
                            message: '',
                            type: '',
                            show: false
                        },
                        copyAlert: {
                            message: '',
                            type: '',
                            show: false,
                            timeout: null
                        },
                        tooltip: {
                            open: false,
                            active: false,
                            timeout: null,
                            top: 500,
                            left: 500
                        },
                        colorList: [
                            '243, 132, 128', 
                            '243, 156, 128', 
                            '243, 182, 128', 
                            '243, 216, 128', 
                            '229, 243, 128', 
                            '174, 243, 128', 
                            '128, 243, 200', 
                            '128, 211, 243'
                        ],
                        data: {
                            id: null,
                            cfiRange: '',
                            contents: null,
                            markedText: '',
                            color: '243, 132, 128',
                            description: '',
                            tag: '',
                            tags: []
                        },
                        list: []
                    },
                    footnote: {
                        openModal: false,
                        list: [],
                        selected: ''
                    }
                },
                currentLocation: null,
                configurationOptions: {
                    opened: false,
                    font: {
                        fontSize: 20
                    },
                    theme: 'padrao',
                    previous: {
                        font: {
                            fontSize: 20
                        },
                        theme: 'padrao'
                    }
                },
                axiosAbortController: {
                    currentLocation: null,
                    configurationOptions: null
                },
                loadingBookmark: false,
                loadingContent: false
			};
        },
        computed: {
            currentChapter(){
                return this.getCurrentChapter(this.currentLocation);
            }
        },
        methods: {
            concatNodeText(nodeList){
                return nodeList.reduce((acc ,current) => {
                    return acc + current.textContent;
                }, '');
            },
            searchNode(nodeList, excerptLimit, section, query){
                query = query.toLowerCase();

                let nodeListCopy = nodeList.slice();
                let textWithCase = this.concatNodeText(nodeListCopy);
                let completeText = this.concatNodeText(nodeList);
                let text = textWithCase.toLowerCase();
                let pos = text.indexOf(query);


                while (pos >= nodeListCopy[0].length) {
                    //console.log('--remover primeiro node--');
                    
                    nodeListCopy.shift();
                    
                    textWithCase = this.concatNodeText(nodeListCopy);
                    text = textWithCase.toLowerCase();
                    pos = text.indexOf(query);

                    //console.log(nodeListCopy);

                    if(pos != -1 && pos != 0){
                        //console.log('---reduced text---');
                        //console.log(textWithCase);
                        //console.log('----------');
                        //console.log('achou: ' + pos);
                        //console.log('tamanho primeiro node: ' + nodeListCopy[0].length);
                    }
                }
                
                if (pos != -1){
                    //console.log('--lista final--');
                    //console.log(nodeListCopy);

                    const startNodeIndex = 0 , endPos = pos + query.length;
                    let endNodeIndex = 0 , l = 0;

                    //console.log('start node: ' + startNodeIndex);
                    //console.log('nodeList length: ' + nodeListCopy[startNodeIndex].length);

                    if (pos < nodeListCopy[startNodeIndex].length){
                        let cfi;
                        
                        while( endNodeIndex < nodeListCopy.length - 1 ){
                            l += nodeListCopy[endNodeIndex].length;
                            if ( endPos <= l){
                                break;
                            }
                            endNodeIndex += 1;
                        }

                        //console.log('start node: ' + startNodeIndex);
                        //console.log('end node: ' + endNodeIndex);

                        let startNode = nodeListCopy[startNodeIndex] , endNode = nodeListCopy[endNodeIndex];
                        let range = section.document.createRange();
                        
                        range.setStart(startNode,pos);
                        
                        let beforeEndLengthCount =  nodeListCopy.slice(0, endNodeIndex).reduce((acc,current)=>{return acc+current.textContent.length;},0) ;
                        
                        range.setEnd(endNode, beforeEndLengthCount > endPos ? endPos : endPos - beforeEndLengthCount );
                        cfi = section.cfiFromRange(range);

                        let excerpt = nodeListCopy.slice(0, endNodeIndex+1).reduce((acc,current)=>{return acc+current.textContent ;},"");
                        
                        if (excerpt.length > excerptLimit){
                            excerpt = excerpt.substring(pos - excerptLimit/2, pos + excerptLimit/2);
                            excerpt = "..." + excerpt + "...";
                        }
                        
                        if(typeof this.features.search.matches.find(item => item.cfi == cfi) == 'undefined'){
                            this.features.search.matches.push({
                                cfi: cfi,
                                excerpt: excerpt
                            });
                        }
                    }
                }
            },
            searchTreeWalk(section, filter){
                let treeWalker = document.createTreeWalker(section.document, NodeFilter.SHOW_TEXT, null, false);
                let maxSeqEle = 5;
                let node = [];
                let nodeList = [];

                this.features.search.matches = [];
                
                while (node = treeWalker.nextNode()) {
                    //console.log('-----------------------------------------');
                    //console.log('interação');
                    //console.log(node);
                    
                    nodeList.push(node);

                    //console.log('tamanho: ' + nodeList.length);
                    
                    if (nodeList.length == maxSeqEle){
                        //console.log('---search node---');
                        
                        this.searchNode(nodeList.slice(0 , maxSeqEle), 150, section, filter);

                        nodeList = nodeList.slice(1, maxSeqEle);
                    }

                    //console.log(nodeList);
                }

                //console.log(nodeList);
                //console.log(this.features.search.matches);

                return this.features.search.matches;
            },
            async search(e){
                let $this = this;

                this.features.search.result = [];
                this.features.search.loading = true;

                this.searchHistory(this.features.search.filter);

                this.reader.book.spine.spineItems.forEach(async function(item, index){
                    let chapter = $this.getCurrentChapter(item);

                    try {
                        await item.load($this.reader.book.load.bind($this.reader.book));

                        let res = $this.searchTreeWalk(item, $this.features.search.filter);

                        res.forEach(result => {
                            if(typeof $this.features.search.result.find(item => item.cfi == result.cfi) == 'undefined'){
                                $this.features.search.result.push({
                                    cfi: result.cfi,
                                    chapter: chapter,
                                    excerpt: $this.$Utilities.markText(result.excerpt, $this.features.search.filter)
                                });
                            }
                        });

                        $this.performMark($this.features.search.filter);

                        await item.unload();                        
                    } catch (error) {
                        //console.log(error);
                    }

                    if($this.reader.book.spine.spineItems.length == index + 1) $this.features.search.loading = false;
                });
            },
            async goToSearchedSection(searchedSection){
                if(typeof this.filter == 'undefined') return;

                let positionFound = false;
                let spineItems = [];
                let res = null;
                let item = null;

                this.loadingContent = true;
                
                this.reader.book.spine.spineItems.forEach(function(item){
                    spineItems.push(item);
                });
                                 
                for(let index in spineItems) {
                    index = parseInt(index);
                    
                    if(positionFound) break;

                    res = null;
                    item = spineItems[index];

                    try {
                        await item.load(this.reader.book.load.bind(this.reader.book))
                        
                        res = this.searchTreeWalk(item, searchedSection);
                        
                        await item.unload();

                        if(res.length > 0){
                            positionFound = true;

                            if(this.currentLocation != null && item.href == this.currentLocation.start.href){
                                this.readerAction = '';

                                let promiseDisplay = this.reader.rendition.display(res[0].cfi);

                                promiseDisplay.then(() => {
                                    this.loadingContent = false;

                                    this.performMark(this.keyword);
                                });
                            }else{
                                this.readerAction = 'filter-step2';

                                this.filterCfiRange = res[0].cfi;

                                this.features.search.markInstance.unmark({
                                    done: () => {
                                        this.reader.rendition.display(item.href);
                                    }
                                });
                            }

                            //this.reader.rendition.display(res[0].cfi);
                            //this.loadingContent = false;

                            /*setTimeout(($this, cfi) => {
                                $this.reader.rendition.display(cfi);
                                $this.loadingContent = false;
                            }, 500, this, res[0].cfi);*/
                        }
                    } catch (error) {
                        //console.log(error);
                    }

                    if(spineItems.length == index + 1) this.loadingContent = false;
                }
            },
            goToCfiRange(filterCfiRange){
                this.features.search.markInstance.unmark({
                    done: () => {
                        this.reader.rendition.display(filterCfiRange);

                        setTimeout(($this, filterCfiRange) => {
                            let promise = $this.reader.rendition.display(filterCfiRange);

                            promise.then(() => $this.performCurrentMark());
                        }, 1000, this, filterCfiRange);
                    }
                });
            },
            getCurrentChapter(location){
                let currentChapter = '';
                let locationHref = '';

                if(this.features.summary.chapters.length > 0 && location != null){
                    if(typeof location.start != 'undefined'){
                        locationHref = location.start.href;
                    }else{
                        locationHref = location.href;
                    }

                    currentChapter = this.features.summary.chapters.filter((chapter) => chapter.href == locationHref);

                    currentChapter = currentChapter.length > 0 ? currentChapter[0].label : this.features.summary.chapters[0].label;
                }

                return currentChapter;
            },
            async verifyMyLibrary(){
                try {
                    await this.$axios
                                .get(
                                    `ebook/is-my-book/${this.bookId}`,
                                    {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                                );

                    return true;
                } catch (error) {
                    switch (error.response.status) {
                        case 401:
                            this.$Utilities.verifyToken(401, {page: 'login', message: 'sessionExpired'});
                            break;

                        default:
                            this.$router.push({name: 'myBooks', params: {message: 'bookNotFound'}});
                            break;
                    }

                    return false;
                }
            },
            async createLocation(){
                try {
                    this.reader.book.locations.load(await this.reader.book.locations.generate(1600));

                    this.reader.book.rendition.reportLocation();

                    let locations = this.reader.book.locations.save();

                    await this.$axios({
                            method: 'POST',
                            url: 'ebook/location',
                            data: {
                                book_id: this.bookId,
                                location: locations
                            },
                            headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                        });
                } catch (error) {
                    return false;
                }
            },
            async createBookPagination(){
                if(this.reader.locations != null){
                    this.reader.book.locations.load(this.reader.locations);
                    this.reader.book.rendition.reportLocation();
                }else{
                    this.createLocation();
                }

                /*
                let bookKey = this.reader.book.key() + '-cile-book-locations';
                let storedLocations = localStorage.getItem(bookKey);

                if(storedLocations) {
                    this.reader.book.locations.load(storedLocations);
                }else{
                    this.reader.book.locations.load(await this.reader.book.locations.generate(1600));

                    localStorage.setItem(bookKey, this.reader.book.locations.save());

                    this.reader.book.rendition.reportLocation();
                }
                */
            },
            async getBook(){
                await this.verifyMyLibrary();

                this.loadingContent = true;

                this.reader.book = ePub(`${this.$apiBaseURL}/ebook/${this.bookId}/OEBPS/content.opf`, {
                    requestHeaders: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                });

                this.reader.book.setRequestHeaders({
                    'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`
                });

                this.reader.book.ready.then(async (book) => {
                    /*
                    * Criar paginação do livro
                    */
                    /*let bookKey = this.reader.book.key() + '-cile-book-locations';
                    let storedLocations = localStorage.getItem(bookKey);

                    if(storedLocations) {
                        this.reader.book.locations.load(storedLocations);
                    }else{
                        this.reader.book.locations.load(await this.reader.book.locations.generate(1600));

                        localStorage.setItem(bookKey, this.reader.book.locations.save());
                    }*/
                    this.createBookPagination();
                    //***********//

                    //comentario temp
                    //this.reader.rendition.display(0);
                    
                    //comentario temp
                    //this.initialSetup();

                    console.log('ready');

                    setTimeout(($this) => {
                        //$this.initialSetup();

                        $this.reader.rendition.on('relocated', (location) => {
                            $this.currentLocation = $this.reader.book.rendition.currentLocation();

                            $this.saveCurrentLocation(location.start.cfi);
                        });

                        $this.loadingContent = false;

                        //comentario temp
                        /*
                        $this.goToSearchedSection($this.filter);
                        
                        if(typeof $this.filterCfiRange != 'undefined') $this.goToCfiRange($this.filterCfiRange);
                        */
                    }, 500, this);
                }).then(locations => {
                    //console.log('carregado');
                });

                this.reader.rendition = await this.reader.book.renderTo("reader-content", {
                    width: "100%",
                    height: "100%",
                    //ignoreClass: 'annotator-hl',
                    //manager: "continuous"
                });

                this.reader.rendition.on("displayed", (section, i) => {
                    console.log('displayed');
                });

                this.reader.rendition.on("rendered", (section, i) => {
                    console.log('rendered');

                    this.verifyMyLibrary()

                    this.features.search.markInstance = new Mark(document.querySelector("#main-content div iframe").contentWindow.document.body);

                    //this.reader.rendition.display(0);

                    console.log(this.readerAction);

                    switch (this.readerAction) {
                        case 'cfi':
                            if(this.filterCfiRange != ''){
                                this.readerAction = '';

                                this.goToCfiRange(this.filterCfiRange);
                            }
                        break;

                        case 'filter-step1':
                            if(this.filter != ''){
                                this.goToSearchedSection(this.filter);
                            }
                        break;

                        case 'filter-step2':
                            if(this.filterCfiRange != ''){
                                this.readerAction = '';

                                let promiseDisplay = this.reader.rendition.display(this.filterCfiRange);

                                promiseDisplay.then(() => {
                                    let promiseDisplay2 = this.reader.rendition.display(this.filterCfiRange);

                                    promiseDisplay2.then(() => {
                                        this.loadingContent = false;

                                        this.performMark(this.keyword);
                                    });
                                });
                            }
                        break;
                    }

                    if(document.querySelector('#reader-content iframe') != null){
                        document.querySelector('#reader-content iframe').contentDocument.body.removeEventListener('mousedown', this.tooltipOutsideClickListener);
                        document.querySelector('#reader-content iframe').contentDocument.body.addEventListener('mousedown', this.tooltipOutsideClickListener);

                        //document.querySelector('#reader-content iframe').contentDocument.removeEventListener("pointerup", this.markedText);
                        //document.querySelector('#reader-content iframe').contentDocument.addEventListener("pointerup", this.markedText);

                        document.querySelector('#reader-content iframe').contentDocument.removeEventListener("selectionchange", this.markedText);
                        document.querySelector('#reader-content iframe').contentDocument.addEventListener("selectionchange", this.markedText);                        
                    }

                    if(!this.features.annotation.loaded) this.getAnnotations();

                    this.redrawAnnotations();

                    i.document.querySelectorAll('.footnote').forEach(el => {
                        el.removeEventListener('click', this.showFootnote);
                        el.addEventListener('click', this.showFootnote);
                    });

                    document.querySelector('#main-content iframe').contentDocument.removeEventListener('mousedown', this.closeAllFeatures);
                    document.querySelector('#main-content iframe').contentDocument.addEventListener('mousedown', this.closeAllFeatures);
                });
                //this.reader.rendition.reportLocation();

                this.reader.rendition.themes.register("padrao", 
                    {
                        '.padrao': {
                            'background-color': 'transparent !important',
                            'padding-top': '37.5px !important',
                            'padding-bottom': '37.5px !important'
                        },
                        '::selection': {
                            'background': 'rgba(255,255,0, 0.3)'
                        }
                    }
                );

                this.reader.rendition.themes.register("dark", 
                    {
                        '.dark': {
                            'color': '#ffffff',
                            'padding-top': '37.5px !important',
                            'padding-bottom': '37.5px !important'
                        },
                        '::selection': {
                            'background': 'rgba(255,255,0, 0.3)'
                        }
                    }
                );

                this.reader.rendition.themes.register("sepia", 
                    {
                        '.sepia': {
                            'color': '#5D4232',
                            'padding-top': '37.5px !important',
                            'padding-bottom': '37.5px !important'
                        },
                        '::selection': {
                            'background': 'rgba(255,255,0, 0.3)'
                        }
                    }
                );

                this.initialSetup();

                this.reader.book.loaded.navigation.then((toc) => {
                    this.features.summary.chapters = toc.toc;

                    this.summary.forEach((chapter, index) => {
                        if(chapter.subtitle != null 
                           && `\n${chapter.title}\n` == this.features.summary.chapters[index + 1].label){
                            this.features.summary.chapters[index + 1].label = `\n${chapter.subtitle}\n`;
                        }
                    });
                });

                this.reader.book.loaded.metadata.then((meta) => {
                    this.metadata = meta;
                });

                this.reader.rendition.on("selected", (cfiRange, contents) => {
                    if(!this.features.annotation.tooltip.active){
                        this.cancelAnnotationTooltip();
                        
                        this.features.annotation.tooltip.active = true;

                        this.markedText();
                    }

                    this.features.annotation.data.cfiRange = cfiRange;
                    this.features.annotation.data.contents = contents;
                    //this.features.annotation.tooltip.active = true;

                    /*console.log(cfiRange);
                    console.log(contents);

                    this.reader.book.getRange(cfiRange).then((range) => {
                        console.log(range.toString());
                    });*/

                    ////this.features.annotation.data.cfiRange = cfiRange;

                    ////this.addAnnotation(cfiRange, this.features.annotation.data.color);

                    //console.log(Object.keys(this.reader.rendition.annotations._annotations));
                    //console.log(this.reader.rendition.annotations);
                    
                    //console.log(this.reader.rendition.annotations._annotations[Object.keys(this.reader.rendition.annotations._annotations)[0]].mark.element.getBoundingClientRect());
                    //console.log(this.reader.rendition.annotations._annotations[Object.keys(this.reader.rendition.annotations._annotations).pop()].mark.element.getBoundingClientRect());
                    //console.log(Object.keys(this.reader.rendition.annotations._annotations)[0]);
                    //document.querySelector('g').getBoundingClientRect()
                    /*this.reader.rendition.annotations.forEach(element => {
                        console.log(element);
                    });*/
                    /*this.reader.rendition.annotations.highlight(cfiRange, {}, (e) => {
                        console.log(e.target);
                        console.log("highlight clicked", e.target);
                    });*/
                    //contents.window.getSelection().removeAllRanges();

                    ////let annotationPosition = this.reader.rendition.annotations._annotations[Object.keys(this.reader.rendition.annotations._annotations).pop()].mark.element.getBoundingClientRect()
                    
                    ////this.openAnnotationTooltip(annotationPosition);
                });
            },
            showFootnote(event){
                let selectedFootnote = this.features.footnote.list.filter(footnote => footnote.footnote_id == event.target.dataset.footnoteid);

                if(selectedFootnote.length > 0){
                    this.features.footnote.selected = selectedFootnote[0].text;
                }else{
                    this.features.footnote.selected = 'Nota de rodapé não foi encontrada.'
                }

                this.openFootnoteModal();
            },
            openFootnoteModal(){
                this.features.footnote.openModal = true;
            },
            closeFootnoteModal(){
                this.features.footnote.openModal = false;
            },
            markedText(){
                if(!this.features.annotation.tooltip.active) return;

                if(this.features.annotation.tooltip.timeout != null) clearTimeout(this.features.annotation.tooltip.timeout);

                this.features.annotation.tooltip.timeout = setTimeout(($this) => {
                    //alert(this.features.annotation.tooltip.active);

                    $this.features.annotation.tooltip.active = false;

                    $this.addAnnotation($this.features.annotation.data.cfiRange, $this.features.annotation.data.color);

                    let annotationPosition = $this.reader.rendition.annotations._annotations[Object.keys($this.reader.rendition.annotations._annotations).pop()].mark.element.getBoundingClientRect();

                    $this.openAnnotationTooltip(annotationPosition);

                    $this.features.annotation.data.contents.window.getSelection().removeAllRanges();
                }, 500, this);

                /*this.features.annotation.tooltip.timeout = setTimeout(($this) => {
                    //alert(this.features.annotation.tooltip.active);
                    if(!this.features.annotation.tooltip.active) return;

                    $this.features.annotation.tooltip.active = false;

                    $this.addAnnotation($this.features.annotation.data.cfiRange, $this.features.annotation.data.color);

                    let annotationPosition = $this.reader.rendition.annotations._annotations[Object.keys($this.reader.rendition.annotations._annotations).pop()].mark.element.getBoundingClientRect();

                    $this.openAnnotationTooltip(annotationPosition);

                    $this.features.annotation.data.contents.window.getSelection().removeAllRanges();
                }, 500, this);*/
            },
            async getFootnote(){
                try {
                    let footnote = await this.$axios
                                .get(
                                    `ebook/footnote/${this.bookId}`,
                                    {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                                );
                    
                    this.features.footnote.list = footnote.data;

                    return true;
                } catch (error) {
                    switch (error.response.status) {
                        case 401:
                            this.$Utilities.verifyToken(401, {page: 'login', message: 'sessionExpired'});
                            break;

                        default:                            
                            break;
                    }

                    return false;
                }
            },
            redrawAnnotations(){
                this.reader.rendition.views().forEach(view => view.pane ? view.pane.render() : null);
            },
            openAnnotationTooltip(annotationPosition){
                //this.resetAnnotationModal();

                this.features.annotation.tooltip.top = annotationPosition.top - 100;
                this.features.annotation.tooltip.open = true;

                if(window.innerWidth < 700) {
                    let windowWidth = window.innerWidth;
                    let tooltipWidth = document.querySelector('#annotation-tooltip').offsetWidth;

                    if(tooltipWidth == 0) tooltipWidth = 270;

                    this.features.annotation.tooltip.left = (windowWidth/2) - (tooltipWidth/2);
                }else{
                    this.features.annotation.tooltip.left = annotationPosition.left + 100;
                }
            },
            closeAnnotationTooltip(){
                this.features.annotation.tooltip.open = false;
            },
            cancelAnnotationTooltip(){
                if(this.features.annotation.data.id == null){
                    this.removeAnnotation(this.features.annotation.data.cfiRange);
                }

                this.closeAnnotationTooltip();
            },
            addAnnotation(cfiRange, color){
                try {
                    this.reader.rendition.annotations.add(
                        "highlight", 
                        cfiRange, 
                        {}, 
                        (e) => {
                            let myAnnotation = document.querySelector(`#ebook-annotation .my-annotation[data-cfi='${e.target.dataset.epubcfi}']`);

                            if(myAnnotation == null) return;

                            this.openAnnotation();
                            this.cancelAnnotationTooltip();

                            document.querySelector('.ebook-annotation').scrollTop = document.querySelector(`#ebook-annotation .my-annotation[data-cfi='${e.target.dataset.epubcfi}']`).offsetTop - 20;
                        }, 
                        "", 
                        {
                            "fill": `rgba(${color}, 0.6)`, 
                            "fill-opacity": "0.6", 
                            "mix-blend-mode": "multiply"
                        }
                    );
                } catch (error) {
                    return false;
                }
            },
            removeAnnotation(cfiRange){
                this.reader.rendition.annotations.remove(cfiRange, "highlight");
            },
            updateAnnotation(cfiRange, color){
                this.removeAnnotation(cfiRange);
                this.addAnnotation(cfiRange, color);
            },
            copyAnnotation(){
                this.reader.book.getRange(this.features.annotation.data.cfiRange).then((range) => {
                    let markedText = range.toString();

                    this.$refs.clipboard.value = markedText;
                    this.$refs.clipboard.select();
                    this.$refs.clipboard.setSelectionRange(0, 999999); // For mobile devices

                    navigator.clipboard.writeText(this.$refs.clipboard.value).then(() => {
                        this.features.annotation.copyAlert.message = 'O trecho do livro foi copiado com sucesso.';
                        this.features.annotation.copyAlert.type = 'success';
                        this.features.annotation.copyAlert.show = true;

                        this.copyAnnotationTimeout();
                    }, () => {
                        this.features.annotation.copyAlert.message = 'Ocorreu um erro. Por favor tente novamente';
                        this.features.annotation.copyAlert.type = 'warning';
                        this.features.annotation.copyAlert.show = true;

                        this.copyAnnotationTimeout();
                    });
                });
            },
            copyAnnotationTimeout(){
                if(this.features.annotation.copyAlert.timeout != null) clearTimeout(this.features.annotation.copyAlert.timeout);

                setTimeout(($this) => {
                    $this.features.annotation.copyAlert.show = false;
                }, 5000, this);
            },
            saveAnnotation(){
                let annotation = Object.assign({}, this.features.annotation.data);

                if(annotation.cfiRange != ''){
                    this.reader.book.getRange(annotation.cfiRange).then((range) => {
                        annotation.markedText = range.toString();

                        this.$axios({
                            method: 'POST',
                            url: 'ebook/annotation',
                            data: {
                                book_id: this.bookId,
                                cfi_range: annotation.cfiRange,
                                marked_text: annotation.markedText,
                                color: annotation.color
                            },
                            headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                        })
                        .then(res => {
                            this.features.annotation.list.unshift(this.formatAnnotationData(res.data));
                        })
                        .catch(error => {
                            this.$Utilities.verifyToken(error.response.status, {page: 'login', message: 'sessionExpired'});
                        });
                    });
                }

                this.resetAnnotationModal();
                this.closeAnnotationTooltip();
            },
            saveAnnotationModal(){
                let annotation = Object.assign({}, this.features.annotation.data);
                let data = null;

                this.features.annotation.loadingModal = true;
                this.features.annotation.alert.show = false;

                this.reader.book.getRange(annotation.cfiRange).then((range) => {
                    annotation.markedText = range.toString();

                    if(annotation.id == null){
                        data = {
                            book_id: this.bookId,
                            cfi_range: annotation.cfiRange,
                            marked_text: annotation.markedText,
                            color: annotation.color,
                            description: annotation.description,
                            tags: annotation.tags
                        };
                    }else{
                        data = {
                            color: annotation.color,
                            description: annotation.description,
                            tags: annotation.tags
                        };
                    }

                    this.$axios({
                            method: annotation.id == null ? 'POST' : 'PUT',
                            url: 'ebook/annotation' + (annotation.id != null ? `/${annotation.id}` : ''),
                            data: data,
                            headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                        })
                        .then(res => {
                            let index = this.features.annotation.list.findIndex(item => item.id == res.data.id);

                            if(index == -1){
                                this.features.annotation.list.unshift(this.formatAnnotationData(res.data));
                            }else{
                                this.features.annotation.list[index] = this.formatAnnotationData(res.data);
                            }

                        })
                        .catch(error => {
                            this.$Utilities.verifyToken(error.response.status, {page: 'login', message: 'sessionExpired'});

                            this.$Utilities.controlAlert(error.response.data, this.features.annotation.alert, 'danger');
                        })
                        .finally(() => {
                            this.closeAnnotationModal();

                            this.features.annotation.loadingModal = false;
                        });
                });

            },
            getAnnotations(){
                this.features.annotation.loaded = true;
                this.features.annotation.loadingContent = true;

                this.$axios
                    .get(
                        `ebook/annotations/${this.bookId}`,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status != 204) res.data.forEach(item => {
                            this.addAnnotation(item.cfi_range, item.color);
                            this.features.annotation.list.unshift(this.formatAnnotationData(item));
                        });
                    })
                    .catch(error => {
                        this.$Utilities.verifyToken(error.response.status, {page: 'login', message: 'sessionExpired'});

                        this.$Utilities.controlAlert(error.response.data, this.features.annotation.alert, 'danger');
                    })
                    .finally(() => this.features.annotation.loadingContent = false);
            },
            deleteAnnotation(annotation){
                this.features.annotation.alert.show = false;

                this.manageAnnotationLoading(annotation.id, 'show');

                this.$axios({
                        method: 'DELETE',
                        url: `ebook/annotation/${annotation.id}`, 
                        headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                    })
                    .then(res => {
                        this.features.annotation.list = this.features.annotation.list.filter(item => {
                            return item.id != annotation.id;
                        });

                        this.removeAnnotation(annotation.cfiRange);
                    })
                    .catch(error => {
                        this.$Utilities.verifyToken(error.response.status, {page: 'login', message: 'sessionExpired'});

                        this.$Utilities.controlAlert(error.response.data, this.features.annotation.alert, 'danger');
                    })
                    .finally(() => this.manageAnnotationLoading(annotation.id, 'hide'));
            },
            loadAnnotationModal(annotation){
                this.features.annotation.data.id = annotation.id;
                this.features.annotation.data.cfiRange = annotation.cfiRange;
                this.features.annotation.data.markedText = annotation.markedText;
                this.features.annotation.data.color = annotation.color;
                this.features.annotation.data.description = annotation.description;
                this.features.annotation.data.tags = annotation.tags;

                this.openAnnotationModal();
            },
            manageAnnotationLoading(annotationId, visibility){
                this.features.annotation.list.forEach((item, index) => {
                    if(item.id == annotationId) {
                        this.features.annotation.list[index].loading = visibility == 'show' ? true : false;
                    }
                });
            },
            formatAnnotationData(annotation){
                return {
                    id: annotation.id,
                    date: annotation.created_at,
                    cfiRange: annotation.cfi_range,
                    markedText: annotation.marked_text,
                    color: annotation.color,
                    description: annotation.description,
                    tags: annotation.book_annotation_tags.map(item => item.book_tags.description),
                    options: true,
                    loading: false
                };
            },
            annotationClickEvent(annotation){
                this.reader.rendition.display(annotation.cfiRange);

                this.closeAnnotation();
            },
            saveCurrentLocation(locationStartCfi){
                if(this.axiosAbortController.currentLocation != null) this.axiosAbortController.currentLocation.abort();

                this.axiosAbortController.currentLocation = new AbortController();

                let percentage = Math.floor(this.reader.book.locations.percentageFromCfi(locationStartCfi) * 100);
                let currentPage = {
                    book_id: this.bookId,
                    location: JSON.stringify(this.currentLocation)
                };

                if(this.reader.book.locations.total > 0) {
                    this.reader.percentage = percentage,
                    currentPage.percentage = percentage;
                }

                this.$axios({
                    method: 'PUT',
                    url: 'ebook/current-page',
                    data: currentPage,
                    /*data: {
                        book_id: this.bookId,
                        location: JSON.stringify(this.currentLocation),
                        percentage: percentage
                    },*/
                    signal: this.axiosAbortController.currentLocation.signal,
                    headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                });
            },
            async createBookmark(){
                this.loadingBookmark = true;

                let currentLocation = this.reader.book.rendition.currentLocation();
                let section = await this.reader.book.spine.get(currentLocation.start.cfi);
                let chapter = this.getCurrentChapter(currentLocation);

                this.reader.book.getRange(currentLocation.start.cfi).then((range) => {
                    let location = section.cfiFromElement(range.startContainer.parentElement);
                    let bookExcerpt = this.$Utilities.textExcerpt(range.startContainer.data, 100);

                    this.saveBookmark(location, chapter, bookExcerpt);
                });
            },
            saveBookmark(location, chapter, bookExcerpt){
                this.$axios({
                    method: 'POST',
                    url: 'ebook/bookmark',
                    data: {
                        book_id: this.bookId,
                        location: location.replace(/.$/, '/1:0)'),
                        chapter: chapter.replace(/\n/g, ''),
                        book_excerpt: `${bookExcerpt}...`
                    },
                    headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                })
                .then(res => {
                    res.data.loading = false;

                    this.features.bookmark.list.push(res.data);
                })
                .catch(error => {
                    //console.log(error);
                })
                .finally(() => this.loadingBookmark = false);
            },
            deleteBookmark(bookmark){
                bookmark.loading = true;

                this.features.bookmark.alert.show = false;

                this.$axios({
                        method: 'DELETE',
                        url: `ebook/bookmark/${bookmark.id}`, 
                        headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                    })
                    .then(res => {
                        this.features.bookmark.list = this.features.bookmark.list.filter(item => {
                            return item.id != bookmark.id;
                        });
                    })
                    .catch(error => {
                        this.$Utilities.controlAlert(error.response.data, this.features.bookmark.alert, 'danger');
                    })
                    .finally(() => bookmark.loading = false);
            },
            saveConfigurationOptions(){
                if(this.axiosAbortController.configurationOptions != null) this.axiosAbortController.configurationOptions.abort();

                this.axiosAbortController.configurationOptions = new AbortController();

                this.$axios({
                    method: 'PUT',
                    url: 'ebook/configuration-options',
                    data: {
                        font_size: this.configurationOptions.font.fontSize,
                        theme: this.configurationOptions.theme
                    },
                    signal: this.axiosAbortController.configurationOptions.signal,
                    headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                });
            },
            async startBook(){
                this.loadingContent = true;

                let isMyBook = await this.verifyMyLibrary();

                if(isMyBook == false) return;

                await this.getFootnote();
                await this.getBookProgress();

                this.getBook();
            },
            async getBookProgress(){
                try {
                    let progress = await this.$axios
                                    .get(
                                        `ebook/progress/${this.bookId}`,
                                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                                    );

                    if(progress.data.book_progress && progress.data.book_progress.current_location) {
                        this.currentLocation = JSON.parse(progress.data.book_progress.current_location);
                        this.reader.percentage = progress.data.book_progress.percentage;
                    }

                    if(progress.data.book_settings){
                        if(progress.data.book_settings.font_size){
                            this.configurationOptions.font.fontSize = parseInt(progress.data.book_settings.font_size);
                            this.configurationOptions.previous.font.fontSize = parseInt(progress.data.book_settings.font_size);
                        } 

                        if(progress.data.book_settings.theme) {
                            this.configurationOptions.theme = progress.data.book_settings.theme;
                            this.configurationOptions.previous.theme = progress.data.book_settings.theme;
                        }
                    }

                    if(progress.data.book_bookmarks.length > 0) {
                        this.features.bookmark.list = progress.data.book_bookmarks.map(bookmark => {
                            bookmark.loading = false;

                            return bookmark;
                        });
                    }

                    if(progress.data.book_locations){
                        this.reader.locations = progress.data.book_locations.location;
                    }

                    if(progress.data.book_summary){
                        this.summary = progress.data.book_summary;
                    }

                    return true;
                } catch (error) {
                    //console.log(error);

                    return false;
                }
            },
            initialSetup(){
                this.setFontSize(this.configurationOptions.font.fontSize);
                this.setTheme(this.configurationOptions.theme);

                if(this.currentLocation != null) {
                    this.reader.displayed = this.reader.rendition.display(this.currentLocation.start.cfi);
                }else{
                    this.reader.displayed = this.reader.rendition.display(0);
                }

                this.reader.book.rendition.reportLocation();
            },
            setFontSize(fontSize){
                this.configurationOptions.font.fontSize = fontSize;

                let imageSubtitleFontSize = (fontSize * 70) / 100;
                
                this.reader.rendition.themes.default({ 
                    "h1": {
                        "font-size": `${fontSize + 10}px !important`,
                        "line-height": `${(fontSize + 10) + 6}px !important`,
                    },
                    "h2": {
                        "font-size": `${fontSize + 6}px !important`,
                        "line-height": `${(fontSize + 6) + 6}px !important`,
                    },
                    "p": { 
                        "font-size": `${fontSize}px !important`,
                        "line-height": `${fontSize + 6}px !important`,
                    },
                    "p.image-subtitle": {
                        "font-size": `${imageSubtitleFontSize}px !important`,
                        "line-height": `${imageSubtitleFontSize + 6}px !important`,
                    }
                });

                this.redrawAnnotations();
            },
            setTheme(theme){
                this.configurationOptions.theme = theme;

                this.reader.rendition.themes.select(theme);
            },
            confirmConfigurationOptions(){
                this.configurationOptions.previous.font.fontSize = this.configurationOptions.font.fontSize;
                this.configurationOptions.previous.theme = this.configurationOptions.theme;

                this.closeConfigurationOptionsModal();

                this.saveConfigurationOptions();

                this.reader.book.rendition.reportLocation();
            },
            resetConfigurationOptions(){
                this.setFontSize(this.configurationOptions.previous.font.fontSize);
                this.setTheme(this.configurationOptions.previous.theme);

                this.closeConfigurationOptionsModal();
            },
            async nextPage(){
                this.cancelAnnotationTooltip();
                this.closeAllFeatures();

                this.verifyMyLibrary();

                //if(await this.verifyMyLibrary()){
                    this.reader.rendition.next();
                //}

                this.$Utilities.scrollToTop();
            },
            async prevPage(){
                this.cancelAnnotationTooltip();
                this.closeAllFeatures();

                this.verifyMyLibrary();

                //if(await this.verifyMyLibrary()){
                    this.reader.rendition.prev();
                //}

                this.$Utilities.scrollToTop();
            },
            async goToChapter(chapter){
                this.cancelAnnotationTooltip();

                if(await this.verifyMyLibrary()){
                    this.reader.rendition.display(chapter);

                    this.features.summary.opened = false;
                }
            },
            openSummary() {
                this.features.summary.opened = true;
                this.features.search.opened = false;

                this.openSummaryList();
            },
            closeSummary() {
                this.features.summary.opened = false;
                this.features.search.opened = false;
            },
            openSummaryList() {
                this.features.summary.openList = true;
                this.features.bookmark.openList = false;
            },
            openBookmarkList() {
                this.features.bookmark.openList = true;
                this.features.summary.openList = false;
            },
            performCurrentMark(){
                if(this.$Utilities.verifyBreakpoint(992) || this.features.search.opened) {
                    this.performMark(this.features.search.filter);
                }
            },
            openSearch() {
                this.closeAnnotation();

                this.features.search.opened = !this.features.search.opened;
                this.features.summary.opened = false;

                this.performCurrentMark();
            },
            closeSearch() {
                this.features.search.opened = false;
                this.features.summary.opened = false;

                if(!this.$Utilities.verifyBreakpoint(992) && this.features.search.markInstance != null) {
                    this.features.search.markInstance.unmark();
                }
            },
            selectSearchItem(filterCfiRange){
                if(this.$Utilities.verifyBreakpoint(992)) this.features.search.opened = false;

                this.goToCfiRange(filterCfiRange);
            },
            openAnnotation() {
                this.closeSearch();
                this.cancelAnnotationTooltip();

                if(!this.features.annotation.opened){
                    document.querySelector('#ebook-annotation').scrollTop = 0;

                    this.features.annotation.opened = true;
                }
            },
            closeAnnotation() {
                this.features.annotation.opened = false;
            },
            openAnnotationModal() {
                this.features.annotation.openModal = true;
            },
            cancelAnnotationModal(){
                if(this.features.annotation.data.id == null) {
                    this.removeAnnotation(this.features.annotation.data.cfiRange);
                }

                this.closeAnnotationModal();
            },
            closeAnnotationModal() {
                this.closeAnnotationTooltip();
                this.resetAnnotationModal();

                this.features.annotation.openModal = false;
            },
            resetAnnotationModal() {
                this.features.annotation.data.id = null;
                this.features.annotation.data.cfiRange = '';
                this.features.annotation.data.color = this.features.annotation.colorList[0];
                this.features.annotation.data.description = '';
                this.features.annotation.data.tag = '';
                this.features.annotation.data.tags = [];
            },
            setAnnotationColor(color){
                this.features.annotation.data.color = color;
                
                this.updateAnnotation(this.features.annotation.data.cfiRange, color);
            },
            addAnnotationTag(){
                let tag = this.features.annotation.data.tag.trim();

                if(tag != '') this.features.annotation.data.tags.push(tag);

                this.features.annotation.data.tag = '';
            },
            toggleConfigurationOptionsModal() {
                this.configurationOptions.opened = !this.configurationOptions.opened;
            },
            closeConfigurationOptionsModal() {
                this.configurationOptions.opened = false;
            },
            ajustWindow(){
                if(this.reader.currentHeight != window.outerHeight){
                    this.reader.currentHeight = window.outerHeight;

                    let contentMargin = document.querySelector('#main-content').offsetTop;
                    let footerHeight = 38 + 50 + 1;
                    let remainingSize = window.outerHeight - contentMargin - footerHeight;

                    remainingSize = remainingSize < 450 ? 450 : remainingSize;

                    document.querySelector('#main-content').style.height = `${remainingSize}px`;
                }
            },
            closeAllFeatures() {
                this.closeSummary();
                this.closeAnnotation();
                this.closeFootnoteModal();
                this.closeConfigurationOptionsModal();
                this.closeSearch();
            },
            tooltipOutsideClickListener(e) {
                if (!document.querySelector('#annotation-tooltip').contains(e.target)) {
                    this.cancelAnnotationTooltip();
                }
            },
            performMark(keyword) {
                keyword = keyword.trim();

                if(keyword == '') return;

                this.features.search.markInstance.unmark({
                    done: () => {
                        this.features.search.markInstance.mark(keyword, {
                            separateWordSearch: false,
                            acrossElements: true
                        });
                    }
                });
            },
            searchHistory(term) {
                this.$axios({
                    method: 'POST',
                    url: 'search-history',
                    data: {
                        term: term,
                        type: 'reader',
                        device: this.$Utilities.device()
                    },
                    headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                });
            }
        },
        mounted(){
            this.bookId = this.$route.params.id;
            this.filter = this.$route.params.filter;
            this.filterCfiRange = this.$route.params.cfiRange;
            this.keyword = this.$route.params.keyword;

            if(typeof this.$route.params.filter != 'undefined') this.readerAction = 'filter-step1';
            if(typeof this.$route.params.cfiRange != 'undefined') this.readerAction = 'cfi';

            this.ajustWindow();

            this.startBook();

            window.addEventListener('resize', this.ajustWindow);

            document.querySelectorAll('.modal-background').forEach(el => {
                el.addEventListener('click', this.closeAllFeatures);
            });
        },
        beforeDestroy(){
            window.removeEventListener('resize', this.ajustWindow);

            document.querySelectorAll('.modal-background').forEach(el => {
                el.removeEventListener('click', this.closeAllFeatures);
            });

            if(document.querySelector('#reader-content iframe') != null) {
                document.querySelector('#reader-content iframe').contentDocument.body.removeEventListener('mousedown', this.tooltipOutsideClickListener);
            }
        },
        watch:{
            
        } 
    }
</script>

<style lang="scss">
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';
    @import '~@assets/css/ebook-theme-default.scss';

    .stand-by {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 100%;
        width: 100%;
        z-index: 9999;
        background-color: rgba(10, 10, 10, 0.86);

        &.loading.large:after {
            top: calc(50% - 40px);
        }
    }

    .main-content {
        .container,
        .reader,
        .reader-content {
            position: relative;
            height: calc(100%);
        }
    }

    .ebook-reader {
        .mobile-logo {
            display: none;
        }

        .reader {
            position: relative;
            padding: 0px 50px;
        }

        .reader-prev,
        .reader-next {
            position: absolute;
            top: calc(50% - 15px);

            border: 0px;
            width: 16px;
            height: 30px;
            padding: 0px;
            display: block;
            background: none;
            cursor: pointer;
            outline: 0;
        }        

        .reader-prev {
            left: 15px;
        }

        .reader-next {
            right: 15px;
            z-index: 2000000;
        }

        .book-progress {
            margin-top: 25px;

            &,
            .progress {
                display: block;
                height: 3px;
            }

            width: calc(100% - 50px);
            margin-left: 25px;
            margin-right: 25px;
            background-color: rgba(178, 178, 178, 0.4);

            .progress {
                background-color: #00AAAD;
                
                -webkit-transition: width .5s;
                        transition: width .5s;
            }
        }
    }

    .ebook-reader {
        .reader-option-loading {
            width: 34px;
            min-height: 34px;

            &::after {
                border-width: 2px;
            }

            &:after {
                top: calc(50% - 8.5px);

                width: 17px;
                height: 17px;
            }
        }

        .message.floating-alert {
            position: absolute;
            top: 0px;
            left: 0px;

            width: 100%;
            border-radius: 0px;
            background-color: #ebfffc;

            -webkit-transform: translateY(-100%);
                    transform: translateY(-100%);

            -webkit-transition: transform .5s ease;
                    transition: transform .5s ease;

            &.is-active {
                -webkit-transform: translateY(0%);
                        transform: translateY(0%);
            }

            .message-body {
                padding: 10px 15px;
                border-radius: 0px;
            }    
        }

        .main-header {
            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid transparent;
            
            > .container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                > div {
                    display: inline-flex;
                    align-items: center;
                    
                    &.column-2 {
                        flex-direction: column;
                    }
                }
            }

            .logo {
                width: 130px;
                margin-right: 25px;
            }

            .reader-menu {
                display: flex;

                li {
                    display: inline-flex;
                    align-items: center;

                    &:not(:first-child) {
                        margin-left: 10px;
                    }
                }
            }

            button {
                cursor: pointer;
                border: none;
                padding: 5px;
                background: transparent;

                p {
                    @include font-config(16px, 20px, map.get($font-weights, 'regular'), center, #FFFFFF);
                }
            }

            .btn-chapter {
                margin-right: 25px;
            }

            .btn-my-books {
                color: #FFFFFF;

                display: inline-flex;
                align-items: center;
                width: 155px;
                padding: 6px 12px;
                border-radius: 5px;
                border: 1px solid #D9D9D9;

                .svg-icon-component {
                    margin-right: 10px;
                }
            }

            .book-title {
                @include font-config(20px, 24px, map.get($font-weights, 'regular'), center, #FFFFFF);

                margin-bottom: 10px;
            }

            .book-chapter {
                @include font-config(16px, 20px, map.get($font-weights, 'regular'), center, #FFFFFF);
            }
        }

        .main-footer {
            border-top: 1px solid transparent;
        }

        .main {
            padding-top: 35px;
        }

        .reader {
            border-radius: 5px;
            background: #FFFFFF;
            box-shadow: 0px 10px 20px #00000029;
        }

        .reader-sidebar {
            overflow-y: scroll;

            position: absolute;
            z-index: 9999;        
            width: 300px;
            height: 100%;
            background-color: #E6E6E6;
            padding: 0px 15px 25px 25px;

            .reader-sidebar-header {
                height: 65px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                margin-bottom: 10px;

                .sidebar-menu {
                    display: inline-flex;
                    align-items: center;

                    button {
                        border: none;
                        background: transparent;

                        &:not(:last-child) {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .ebook-search {
            overflow: initial;
            justify-content: space-between;

            right: 0px;
            top: 0px;

            background-color: #FBFBFB;
            box-shadow: -3px 3px 10px #0000001A;

            -webkit-transform: translateX(100%);
                    transform: translateX(100%);

            .form-search {
                margin-bottom: 15px;

                input {
                    border-color: #D9D9D9;
                    border-radius: 5px;
                    padding-left: 40px;
                }
            }

            .search-input-box {
                position: relative;

                .icon {
                    position: absolute;
                    top: 10px;
                    left: 10px;

                    svg .fill-area {
                        fill: #383D41;
                    }
                }
            }

            .search-result-list {
                margin-left: -25px;
                margin-right: -25px;

                overflow-y: scroll;
                height: calc(100% - 75px - 55px + 10px);
                
                .search-result-item {
                    padding-top: 25px;
                    padding-bottom: 25px;
                    cursor: pointer;

                    .content {
                        margin-left: 25px;
                        margin-right: 25px;
                    }

                    p {
                        @include font-config(16px, 20px, map.get($font-weights, 'regular'));
                    }

                    .result-title {
                        font-style: italic;

                        margin-bottom: 10px;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid #DCDCDC;
                    }
                }
            }
        }

        .ebook-annotation {
            width: 300px;

            right: 0px;
            top: 0px;

            box-shadow: 0px -3px 10px #0000001A;

            .my-annotation {
                .annotation-box {
                    padding: 15px 25px;
                }
            }
        }

        .ebook-summary {
            top: 0px;
            left: 0px;

            box-shadow: 3px 3px 20px #0000001a;

            .menu-icon {
                width: 18px;
            }

            p,
            ul li {
                font-size: 16px;
                line-height: 20px;
            }

            ul li {
                cursor: pointer;
            }

            .book-title {
                font-size: 20px;
                line-height: 24px;

                margin-bottom: 25px;
            }

            ul li:not(:last-child) {
                margin-bottom: 15px;
            }

            .chapter-list {
                .chapter.bookmark {
                    width: 100%;

                    display: inline-flex;
                    align-content: center;
                    justify-content: space-between;

                    > a {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .delete-bookmark {
                        width: 24px;
                        height: 24px;
                        padding: 0px;
                        border: none;
                        background: transparent;
                        cursor: pointer;

                        &:disabled {
                            cursor: no-drop;
                        }
                    }

                    .image {
                        width: 24px;
                        height: 24px;

                        &.loading.small:after {
                            top: 4px;
                        }
                    }
                }
            }
        }

        .modal {
            z-index: 99999;
        }

        .modal-configuration-option {
            padding-left: 25px;
            padding-right: 25px;

            .modal-background {
                background-color: rgba(10, 10, 10, 0.2);
            }

            .modal-card {
                position: absolute;
                bottom: 35px;

                max-width: 550px;
                width: 100%;
            }

            .modal-card-head,
            .modal-card-foot {
                background: #FFFFFF;
            }

            .modal-card-head,
            .modal-card-body,
            .modal-card-foot {
                padding-left: 25px;
                padding-right: 25px;
            }

            .modal-card-head {
                padding-top: 15px;
                padding-bottom: 10px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border: none;

                .modal-card-title {
                    @include font-config(20px, 24px, map.get($font-weights, 'bold'));
                }
            }

            .modal-card-body {
                padding-bottom: 15px;

                .annotation-config-color {
                    .color-list {
                        display: flex;

                        .color-option {
                            display: block;
                            width: 30px;
                            height: 30px;
                            border-radius: 30px;
                            border-width: 3px;
                            border-style: solid;
                            cursor: pointer;

                            &.selected {
                                border-color: #FFFFFF !important;
                                box-shadow: 0px 3px 6px #00000029;
                            }

                            &:not(:last-child) {
                                margin-right: 10px;
                            }
                        }
                    }
                }

                .tags-list {
                    margin-top: 0px;
                }
            }

            .modal-card-foot {
                padding-top: 15px;
                padding-bottom: 15px;

                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;

                border-color: #E6E6E6;

                .button-component {
                    width: 100%;

                    button {
                        width: calc(50% - 0.25rem);
                    }
                }
            }

            .configuration-option {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }

                .configuration-option-title {
                    @include font-config(16px, 20px);

                    margin-bottom: 10px;
                }

                .options-list {
                    display: flex;
                    justify-content: space-between;
                }

                .option-item {                
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    width: 80px;

                    border-radius: 5px;
                    border: 1px solid #8B8B8B;

                    cursor: pointer;

                    &.selected {
                        border: 5px solid #008087 !important;
                    }
                }

                &.config-font-size {
                    .options-list {
                        margin: 0px -5px;

                        > * {
                            margin: 0px 5px;
                        }
                    }

                    .font-size-option-1 {
                        font-size: 14px;
                        line-height: 18px;
                    }

                    .font-size-option-2 {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    .font-size-option-3 {
                        font-size: 26px;
                        line-height: 30px;
                    }
                    
                    .font-size-option-4 {
                        font-size: 31px;
                        line-height: 35px;
                    }

                    .font-size-option-5 {
                        font-size: 37px;
                        line-height: 41px;
                    }                
                }

                &.config-color {
                    .options-list .option-item {
                        @include font-config(16px, 20px, map.get($font-weights, 'semi-bold'), center);

                        flex: 1;

                        &:not(:last-child) {
                            margin-right: 10px;
                        }

                        &.option-white {
                            background-color: #FFFFFF;
                        }

                        &.option-black {
                            color: #FFFFFF;

                            border-color: #000000;
                            background-color: #000000;
                        }

                        &.option-sepia {
                            color: #393A3B;

                            border-color: #D2C3B0;
                            background-color: #D2C3B0;
                        }
                    }
                }
            }
        }

        .annotation-tooltip {
            position: absolute;
            z-index: 100;
            background-color: #04666B;
            /*padding: 8px 16px;*/
            padding: 0px;
            
            border-radius: 5px;

            display: flex;
            flex-direction: column;

            > div {
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                
                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                > *:not(.division) {
                    width: 90px;

                    display: inline-flex;
                    align-items: center;
                    flex-direction: column;
                }

                .division {
                    width: 1px;
                    height: 20px;
                    display: block;
                    margin: 0px 10px;
                    background-color: #ffffff;
                }
            }

            &:after {
                content: '';

                position: absolute;
                bottom: -12px;
                left: calc(50% - 6px);
                width: 0; 
                height: 0; 
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;

                border-top: 12px solid #04666B;
            }

            .color-list {
                display: flex;

                .color-option {
                    display: block;
                    width: 15px;
                    height: 15px; 
                    cursor: pointer;
                    border-radius: 15px;
                    border-width: 2px;
                    border-style: solid;

                    &.selected {
                        border-color: #FFFFFF !important;
                        box-shadow: 0px 3px 6px #00000029;
                    }

                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }
            }

            .btn-annotation {
                @include font-config(14px, 18px, 400, left, #ffffff);

                display: inline-flex;
                align-items: center;

                border: none;
                cursor: pointer;
                background: transparent;
                padding: 8px 0px;
                flex: 1 1 0px;

                &.btn-annotation-cancel {
                    background-color: #f38480;
                    border-radius: 0px 0px 5px 0px;
                }

                &.btn-annotation-copy {
                    background-color: #00AAAD;
                }

                .image {
                    margin-right: 5px;

                    &,
                    .icon,
                    .icon svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }

    .ebook-reader {
        .reader-sidebar {
            -webkit-transition: transform .5s;
                    transition: transform .5s;
        }

        .ebook-summary {
            -webkit-transition: transform .5s;
                    transition: transform .5s;
        }

        .main-header,
        .main-footer {
            -webkit-transition: transform .5s;
                    transition: transform .5s;

        }

        .main .reader {
            -webkit-transition: scale .5s ease, transform 0.5s ease !important;
                    transition: scale .5s ease, transform 0.5s ease !important;
        }
    }

    @media screen and (min-width: 992px), print {
        .ebook-reader {
            .reader.featured-opened {
                scale: 0.7;
                transform: translateX(-200px);
            }
        }
    }

    @media screen and (min-width: 1220px), print {
        .ebook-reader {
            .reader.featured-opened {
                scale: 0.8;
                transform: translateX(-200px);
            }
        }
    }

    @media screen and (min-width: 992px), print {    
        .ebook-reader {
            .reader-sidebar {
                .reader-sidebar-header {
                    .icon-close {
                        display: none;
                    }
                }
            }

            .ebook-search,
            .ebook-annotation {
                -webkit-transform: translateX(100%);
                        transform: translateX(100%);
            }

            .ebook-summary {
                -webkit-transform: translateX(-100%);
                        transform: translateX(-100%);
            }

            &.show-summary {
                .main-header,
                .main .reader {
                    -webkit-transform: translateX(300px);
                            transform: translateX(300px);
                }

                .main .ebook-summary {
                    -webkit-transform: translateX(0%);
                            transform: translateX(0%);
                }
            }

            &.show-search {
                .main-header {
                    -webkit-transform: translateX(-300px);
                            transform: translateX(-300px);
                }

                .main .ebook-search {
                    -webkit-transform: translateX(0%);
                            transform: translateX(0%);
                }
            }

            &.show-annotation {
                .main-header {
                    -webkit-transform: translateX(-300px);
                            transform: translateX(-300px);
                }

                .main .ebook-annotation {
                    -webkit-transform: translateX(0%);
                            transform: translateX(0%);
                }
            }
        }
    }

    @media screen and (max-width: 992px), print {
        .ebook-reader {
            .mobile-logo {
                display: block;
                margin-bottom: 10px;

                a {
                    display: inline-block;
                }

                .logo {
                    width: 100px;
                }
            }

            .main-header {
                position: initial;
                padding-left: 16px;
                padding-right: 16px;

                > .container {
                    flex-wrap: wrap;
    
                    .column-1 {
                        order: 1;
                        flex: 1;
                        justify-content: start;

                        margin-bottom: 10px;
                    }
    
                    .column-2 {
                        order: 3;
                        width: 100%;
                        justify-content: center;
                    }
    
                    .column-3 {
                        order: 2;
                        flex: 1;
                        justify-content: end;

                        margin-bottom: 10px;
                    }
                }

                .desktop-logo {
                    display: none;
                    width: 100px;
                    margin-right: 15px;
                }

                .book-title {
                    font-size: 18px;
                    /*height: 22px;*/
                    height: auto;
                    font-weight: 600;
                }

                .btn-chapter {
                    margin-right: 15px;
                }

                .btn-my-books {
                    width: auto;
                    padding: 5px;
                    margin: 0px;
                    border: 0px;

                    span {
                        display: none;
                    }
                }
            }

            .main {
                padding-top: 15px;
                margin-top: 0px;

                .reader {
                    box-shadow: none;
                    padding-top: 0px;
                    padding-bottom: 0px;
                }
            }

            .book-progress {
                margin-top: 15px;
            }

            .main-footer {
                margin-top: 30px;
            }
        }

        .ebook-reader {
            .reader-sidebar {
                width: 100%;
            }

            .ebook-search {
                top: 0px;
                height: 100%;
            }

            .ebook-search,
            .ebook-annotation {
                -webkit-transform: translateX(100%);
                        transform: translateX(100%);
            }

            .ebook-summary {
                -webkit-transform: translateX(-100%);
                        transform: translateX(-100%);
            }

            &.show-summary {
                .main .ebook-summary {
                    -webkit-transform: translateX(0%);
                            transform: translateX(0%);
                }
            }

            &.show-search {
                .main .ebook-search {
                    -webkit-transform: translateX(0%);
                            transform: translateX(0%);
                }
            }

            &.show-annotation {
                .main .ebook-annotation {
                    -webkit-transform: translateX(0%);
                            transform: translateX(0%);
                }
            }
        }
    }

    @media screen and (max-width: 400px), print {
        .ebook-reader .main-header button p {
            font-size: 14px;
            line-height: 18px;
        }

        .ebook-reader .main-header .reader-menu li:not(:first-child) {
            margin-left: 5px;
        }

        .ebook-reader .main-header .btn-chapter {
            margin-right: 5px;
        }
    }
</style>