<template>
  	<div id="app" class="app">
		<div id="is-992px" class="is-992px"></div>
		<div id="is-768px" class="is-768px"></div>

    	<main>
			<cile-login-template v-if="$route.meta.template == 'loginTemplate'"></cile-login-template>

			<cile-default-template v-else-if="$route.meta.template == 'defaultTemplate'"></cile-default-template>

			<router-view v-else></router-view>
		</main>
  	</div>
</template>

<script>
	import LoginTemplate from '@components/template/LoginTemplate.vue';
	import DefaultTemplate from '@components/template/DefaultTemplate.vue';

	export default {
		name: 'CileReaderApp',
		metaInfo: {
			title: 'Home',
			titleTemplate: '%s | Cile Digital'
		},
		components: {
			'cile-login-template': LoginTemplate,
			'cile-default-template': DefaultTemplate
		}
	}
</script>

<style>
	@import '~bulma/css/bulma.css';
	@import '~@creativebulma/bulma-collapsible/dist/css/bulma-collapsible.min.css';
</style>

<style lang="scss">
	@import '~@assets/css/main.scss';
</style>
