<template>
	<div class="field select-component" :class="validation.$error ? 'error' : ''">
        <label v-if="label" class="label">{{ label }}</label>

        <div class="control">
            <div class="select">
                <select 
                    :value="value" 
                    @input="$emit('input', $event.target.value)" 
                    @change="changeEvent"
                    :disabled="readonly">
                    <option value="" class="placeholder" :selected="!value ? true : false">{{ placeholder }}</option>
                    <option v-for="item in options" :value="item._id" v-bind:key="item._id">{{ item.name }}</option>
                </select>
            </div>

            <span class="validation-error" v-if="validation.$error">{{ $Utilities.getErrorMsg(validation) }}</span>
        </div>
    </div>
</template>

<script>
	export default {
		props: {
            value: {                
            },
            options: {
                required: true,
                type: Array
            },
            label: {
                required: false,
                type: String
            },
            placeholder: {
                required: false,
                type: String,
                default: 'Selecione'
            },
            readonly: {
                required: false,
                default: false,
                type: Boolean
            },
            validation: {
                required: false,
                type: Object,
                default: () => {
                    return {
                        $error: ''
                    }
                }
            },
            error: {
                required: false,
                type: Boolean,
                default: false
            },
            errorMessage: {
                required: false,
                type: String,
                default: ''
            }
        },
        methods: {
            changeEvent(event){
                this.$emit('customChangeEvent', event.target.value);
            }
        }
	}
</script>

<style lang="scss">
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .select {
        &:not(.is-multiple):not(.is-loading)::after {
            border-color: #839097;
        }

        &:not(.is-multiple):not(.is-loading)::after {
            -webkit-transition: border-color .4s ease-in-out;
                    transition: border-color .4s ease-in-out;
        }

        &,
        & select {
            width: 100%;
        }

        select {
            font-size: map.get($fields, 'input-font-size');
            line-height: map.get($fields, 'input-line-height');
    
            border-color: map.get($fields, 'input-border-color');
            border-radius: 0px;
            box-shadow: none;
    
            @include field-placeholder;
    
            &:hover,
            &:focus,
            &:focus:not(:active), 
            &.is-focused:not(:active) {
                outline: none;
                box-shadow: none;
                border-color: map.get($fields, 'input-border-color');
            }

            &:disabled {
                opacity: 1;
                cursor: no-drop !important;
                background-color: rgba(122, 122, 122, 0.1);
            }
        
            option {
                color: #131313;
        
                &.placeholder {
                    color: #797979;
                }
            }
        }
    }
    
    .select-component {
        &:not(:last-child) {
            margin-bottom: 15px;
        }

        .label {
            font-size: map.get($fields, 'label-font-size');
            line-height: map.get($fields, 'label-line-height');
            font-weight: map.get($fields, 'label-font-weight');
        }
    }

    .select:not(.is-multiple):not(.is-loading)::after, 
    .navbar-link:not(.is-arrowless)::after {
        border-color: #707070;
        height: 7px;
        width: 7px;
        top: 25px;
        right: 20px;
    }
</style>