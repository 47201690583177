<template>
    <div class="">
        <cile-breadcrumb 
            :pages="[{name: 'Resenhas', route: ''}]" />

        <section class="default-session">
            <header>
                <h1>Feed de resenhas</h1>
            </header>

            <cile-message :alert="alert" />

            <div :class="loadingContent ? 'loading large' : ''">
                <div v-show="!loadingContent">
                    <cile-reviews 
                        v-for="(review, index) in reviews"
                        :key="`book-review-${index}`"
                        :review="review" />

                    <cile-pagination 
                        v-show="reviews != null"
                        :paginationConfig="pagination"
                        @customChangePageEvent="changePage" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import Message from '@components/shared/message/Message.vue';
    import Pagination from '@components/shared/pagination/Pagination.vue';
    import Reviews from '@views/feedReviews/components/Reviews.vue';

    export default {
        name: 'FeedReviews',
        metaInfo: {
            title: 'Feed de resenhas'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-message': Message,
            'cile-pagination': Pagination,
            'cile-reviews': Reviews
        },
        data() {
			return {
                reviews: null,
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                },
                pagination: {
                    current: 1,
                    totalPages: 1
                }
            };
        },
        methods: {
            getReviews(page = 1, firstLoading = false){
                if(!firstLoading) this.alert.show = false;
                this.loadingContent = true;

                this.$Utilities.scrollToTop();

                this.$axios
                    .get(
                        `book/review?page=${page}`,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status == 204) {
                            this.$Utilities.controlAlert(null, this.alert, 'warning', 'Nenhuma resenha foi encontrada.');
                        } else {
                            this.pagination.current = res.data.current_page;
                            this.pagination.totalPages = res.data.last_page;
    
                            this.reviews = res.data.data;
                        }
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            },
            changePage(page){
                this.getReviews(page);
            }
        },
        created() {
            if(this.$Utilities.hasProperty(this.$route.params, 'message')){
                this.$Utilities.controlAlert(null, this.alert, this.$route.params.message);
            }

            this.getReviews(1, true);
        }
	};
</script>

<style lang="scss"></style>