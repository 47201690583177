<template>
    <section v-if="is_my_book" class="default-session my-annotations" :class="loadingContent ? 'loading large' : ''">
        <header v-show="!loadingContent">
            <h1 class="is-sr-only">Minhas notas ({{ $Utilities.leadingZero(annotations.length) }})</h1>
        </header>

        <button 
            v-show="!collapsedAnnotations"
            id="show-my-annotations" 
            class="btn-my-annotations button primary-button btn-green-2"
            @click="showAnnotations">Ver minhas notas ({{ $Utilities.leadingZero(annotations.length) }})</button>

        <button 
            v-show="collapsedAnnotations"
            id="show-my-annotations" 
            class="btn-my-annotations button primary-button btn-green-2"
            @click="hideAnnotations">Esconder minhas notas</button>

        <div id="annotation-list" class="annotation-list" :class="collapsedAnnotations ? 'is-active' : ''">
            <cile-message :alert="alert" />

            <div v-show="!loadingContent" class="box-annotation">
                <cile-annotation 
                    v-for="(annotation, index) in annotations"
                    :key="`anottation-${index}`"
                    :annotation="annotation"
                    @annotationClickEvent="goToBook" />
            </div>
        </div>

        <button 
            v-show="collapsedAnnotations"
            id="show-my-annotations" 
            class="btn-my-annotations button primary-button btn-green-2"
            @click="hideAnnotations">Esconder minhas notas</button>

        <cile-modal-subscription-plan 
            :open="modal.open"
            :url="modal.url"
            @closeModal="closeModal()" />
    </section>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import Annotation from '@components/cile/Annotation.vue';
    import ModalSubscriptionPlan from '@components/cile/ModalSubscriptionPlan.vue';

    export default {
        name: 'MyAnnotationsComponent',
        components: {
            'cile-message': Message,
            'cile-annotation': Annotation,
            'cile-modal-subscription-plan': ModalSubscriptionPlan
        },
        data() {
			return {
                is_my_book: true,
                annotations: [],
                collapsedAnnotations: false,
                loadingContent: false,
                modal: {
                    open: false,
                    url: ''
                },
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            };
        },
        methods: {
            getAnnotation(bookId) {
                this.alert.show = false
                this.loadingContent = true;

                this.$axios
                    .get(
                        `book/${bookId}/annotation`,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status == 204) {
                            this.$Utilities.controlAlert(null, this.alert, 'annotationNotFound');
                            return;
                        }

                        this.annotations = res.data.map(item => {
                            return {
                                id: item.id,
                                bookId:  item.book.id,
                                bookTitle: item.book.title,
                                date: item.created_at,
                                cfiRange: item.cfi_range,
                                markedText: item.marked_text,
                                color: item.color,
                                description: item.description,
                                tags: item.book_annotation_tags.map(item => item.book_tags.description),
                                is_my_book: item.book.is_my_book,
                                subscription_plan_url: item.book.subscription_plan_url,
                                loading: false,
                                options: false
                            }
                        });
                    })
                    .catch(error => {
                        if(error.response.status == 404){
                            this.is_my_book = false;
                        }else{
                            this.$Utilities.controlAlert(error.response.data, this.alert, 'danger');
                        }
                    })
                    .finally(() => this.loadingContent = false);
            },
            goToBook(annotation){
                if(annotation.is_my_book == 0 && annotation.subscription_plan_url != ''){
                    this.openModal(annotation.subscription_plan_url);
                }else{
                    this.$router.push({name: 'ebookReader', params: {id: annotation.bookId, cfiRange: annotation.cfiRange}});
                }
            },
            showAnnotations(){
                let elAnnotationList = document.querySelector('#annotation-list');

                elAnnotationList.style.height = elAnnotationList.querySelector('.box-annotation').offsetHeight + 'px';

                this.collapsedAnnotations = true;            
            },
            hideAnnotations(){
                document.querySelector('#annotation-list').style.removeProperty('height');

                this.collapsedAnnotations = false;
            },
            toggleAnnotations(){
                let elAnnotationList = document.querySelector('#annotation-list');

                if(this.showAnnotations){
                    elAnnotationList.style.height = '0px';
                }else{
                    elAnnotationList.style.height = elAnnotationList.querySelector('.box-annotation').offsetHeight + 'px';
                }

                this.showAnnotations = !this.showAnnotations;
            },
            openModal(url){
                this.modal.open = true;
                this.modal.url = url;
            },
            closeModal(){
                this.modal.open = false;
            }
        },
        created() {
            this.getAnnotation(this.$route.params.id);
        }
	};
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .my-annotations {
        margin-top: 50px;

        .annotation-list {
            height: 0px;
            overflow: hidden;
            margin-top: 25px;
            margin-bottom: 25px;

            -webkit-transition: height .5s ease;
                    transition: height .5s ease;
        }
    }
</style>