<template>
    <div class="beneficiaries">
        <cile-breadcrumb 
            :pages="[
                {name: 'Gestão de Beneficiários', route: ''},
                {name: 'Assinaturas', route: 'subscriptionPlans'},
                {name: orderInfo != null ? orderInfo.nomePlano : '', route: ''}
            ]" />

        <section class="default-session">
            <header>
                <h1>Gestão de Beneficiários</h1>

                <div class="is-flex">
                    <cile-button 
                        v-show="!loadingContent"
                        type="route"
                        route="subscriptionPlans"
                        title="Voltar" 
                        class="mr-2"
                        buttonStyle="button btn-green-2" />

                    <cile-button 
                        v-show="!loadingContent && this.beneficiaries.length < this.totalVacancies"
                        type="route"
                        route="addBeneficiary" 
                        :routeParams="{
                            id: orderId,
                            order: orderInfo
                        }"
                        title="Adicionar beneficiário" 
                        buttonStyle="button btn-blue" />
                </div>
            </header>

            <cile-message :alert="alert" />

            <div :class="loadingContent ? 'loading large' : ''">
                <div v-show="!loadingContent">
                    <div class="beneficiary-box">
                        <p class="number-of-users">{{ orderTitle }}</p>
                        <p class="number-of-users">Usuários do plano {{ numberOfUsers }}</p>

                        <div class="list-beneficiaries">
                            <article 
                                v-for="(beneficiary, index) in beneficiaries" 
                                :key="`beneficiary-${index}`" 
                                class="beneficiary">
                                <header>
                                    <p class="beneficiary-name">{{ beneficiary.nome }}</p>

                                    <cile-beneficiary-tag 
                                        :beneficiary="beneficiary"
                                        class="is-hidden-tablet" />
                                </header>

                                <div class="details">
                                    <cile-beneficiary-tag 
                                        :beneficiary="beneficiary"
                                        class="is-hidden-mobile" />

                                    <p class="obs notification-type">Contato: {{ notificationType(beneficiary) }}</p>

                                    <p v-if="beneficiary.pendencia == 0" class="obs invitation-date">Ativo desde {{ beneficiary.dtInicioBenef }}</p>
                                    <p v-else class="obs invitation-date">Convite enviado em {{ beneficiary.dtInicioBenef }}</p>

                                    <cile-button 
                                        type="button"
                                        title="Remover" 
                                        class="is-hidden-mobile"
                                        buttonStyle="button btn-pink-only-border"
                                        @customClickEvent="openModalRemoveBeneficiary(beneficiary)"
                                        :loading="beneficiary.loading" />
                                </div>

                                <cile-button 
                                        type="button"
                                        title="Remover" 
                                        class="is-hidden-tablet has-text-right"
                                        buttonStyle="button btn-pink-only-border"
                                        @customClickEvent="openModalRemoveBeneficiary(beneficiary)"
                                        :loading="beneficiary.loading" />
                            </article>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal" :class="modalRemoveBeneficiary.open ? 'is-active' : ''">
                <div class="modal-background"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-centered">Remover Beneficiário</p>
                    </header>

                        <section class="modal-card-body">
                            <p class="has-text-centered mt-4 mb-4">Deseja remover o beneficiário dessa assinatura?</p>
                        </section>

                    <footer class="modal-card-foot">
                        <cile-button 
                            type="button" 
                            title="Confirmar" 
                            buttonStyle="button btn-green"
                            :loading="modalRemoveBeneficiary.loading"
                            :secondButton="{
                                type: 'button', 
                                title: 'Cancelar', 
                                class: 'btn-grey', 
                                visible: true
                            }"
                            @customClickEvent="removeBeneficiary()"
                            @customClickSecondEvent="closeModalRemoveBeneficiary()" />
                    </footer>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import Message from '@components/shared/message/Message.vue';
    import Button from '@components/shared/button/Button.vue';
    import BeneficiaryTag from '@views/beneficiaries/components/BeneficiaryTag.vue';

    export default {
        name: 'Beneficiaries',
        metaInfo: {
            title: 'Beneficiários'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-message': Message,
            'cile-button': Button,
            'cile-beneficiary-tag': BeneficiaryTag
        },
        data() {
			return {
                orderId: 0,
                orderInfo: null,
                beneficiaries: [],
                totalVacancies: 0,
                modalRemoveBeneficiary: {
                    open: false,
                    selected: null,
                    loading: false
                },
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            };
        },
        computed: {
            orderTitle(){
                if(this.orderInfo != null){
                    return `Pedido ${this.orderId}: ${this.orderInfo.nomePlano} - de ${this.orderInfo.dtInicio} a ${this.orderInfo.dtFim}`;
                }

                return '';
            },
            numberOfUsers(){
                if(this.orderInfo == null) return '';

                return `${this.beneficiaries.length}/${this.totalVacancies}`;
            }
        },
        methods: {
            getBeneficiaries(){
                this.loadingContent = true;

                this.$Utilities.scrollToTop();

                this.$axios
                    .get(
                        `subscription-plans/${this.orderId}/beneficiary`,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status == 204) {
                            this.$Utilities.controlAlert(null, this.alert, 'warning', 'Nenhuma assinatura foi encontrada.');
                        } else {
                            res.data.listaBeneficiarios.forEach(item => {
                                item.loading = false;
                            });

                            this.beneficiaries = res.data.listaBeneficiarios;
                            this.orderInfo = res.data.infosPedido;
                            this.totalVacancies = this.orderInfo.vagas + this.beneficiaries.length;
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 404) this.$router.push({name: 'dashboard'});

                        this.$Utilities.controlAlert(error.response.data, this.alert, 'danger');
                    })
                    .finally(() => this.loadingContent = false);
            },
            removeBeneficiary(){
                this.modalRemoveBeneficiary.loading = true;
                this.modalRemoveBeneficiary.selected.loading = true;

                this.$axios({
                    method: 'DELETE',
                    url: `subscription-plans/${this.orderId}/beneficiary/remove/${this.modalRemoveBeneficiary.selected.id}`,
                    headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}
                })
                .then(res => {
                    this.beneficiaries = this.beneficiaries.filter(item => this.modalRemoveBeneficiary.selected.id != item.id);

                    this.$Utilities.controlAlert(null, this.alert, 'success', res.data.message);
                })
                .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                .finally(() => {
                    this.modalRemoveBeneficiary.selected.loading = false;

                    this.closeModalRemoveBeneficiary();
                });
            },
            openModalRemoveBeneficiary(beneficiary){
                this.modalRemoveBeneficiary.open = true;
                this.modalRemoveBeneficiary.selected = beneficiary;
            },
            closeModalRemoveBeneficiary(){
                this.modalRemoveBeneficiary.open = false;
                this.modalRemoveBeneficiary.selected = null;
                this.modalRemoveBeneficiary.loading = false;
            },
            notificationType(beneficiary){
                return this.$Utilities.isNumeric(beneficiary.contato) ? 
                       this.$Utilities.formatPhone(beneficiary.contato) : 
                       beneficiary.contato
            }
        },
        created() {
            if(this.$Utilities.hasProperty(this.$route.params, 'message.type')){
                this.$Utilities.controlAlert(null, this.alert, this.$route.params.message.type, this.$route.params.message.text);
            }else if(this.$Utilities.hasProperty(this.$route.params, 'message')){
                this.$Utilities.controlAlert(null, this.alert, this.$route.params.message);
            }

            this.orderId = this.$route.params.id;

            this.getBeneficiaries();
        }
	};
</script>

<style lang="scss"></style>