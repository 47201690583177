import { render, staticRenderFns } from "./ExpiredSubscriptionPlanNotice.vue?vue&type=template&id=1988ded6&scoped=true&"
import script from "./ExpiredSubscriptionPlanNotice.vue?vue&type=script&lang=js&"
export * from "./ExpiredSubscriptionPlanNotice.vue?vue&type=script&lang=js&"
import style0 from "./ExpiredSubscriptionPlanNotice.vue?vue&type=style&index=0&id=1988ded6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1988ded6",
  null
  
)

export default component.exports