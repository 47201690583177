<template>
    <div class="">
        <cile-breadcrumb 
            :pages="[{name: 'Meus Livros', route: ''}]" />

        <cile-message :alert="alert" />

        <cile-keep-reading />


        <cile-my-books />
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import Message from '@components/shared/message/Message.vue';
    import KeepReading from '@views/myBooks/components/KeepReading.vue';
    import MyBooks from '@views/myBooks/components/MyBooks.vue';

    export default {
        name: 'MyBooks',
        metaInfo: {
            title: 'Meus Livros'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-message': Message,
            'cile-keep-reading': KeepReading,
            'cile-my-books': MyBooks
        },
        data() {
			return {
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            };
        },
        methods: {},
        created() {
            if(this.$Utilities.hasProperty(this.$route.params, 'message')){
                this.$Utilities.controlAlert(null, this.alert, this.$route.params.message);
            }
        }
	};
</script>

<style lang="scss"></style>