<template>
	<section :class="loadingContent ? 'loading large' : ''">
        <cile-message :alert="alert" />

        <div v-show="!loadingContent">
            <cile-news 
                v-for="(news, index) in newsList" 
                :key="`result-news-${index}`" 
                :news="news" />
        </div>        
    </section>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import News from '@views/search/components/News.vue';

    export default {
        name: 'SearchNews',
        components: {
            'cile-message': Message,
            'cile-news': News
		},
        data(){
            return {
                filter: '',
                newsList: [],
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            }
        },
        methods: {
            search(filter) {
                this.filter = filter;
                this.alert.show = false
                this.loadingContent = true;
                this.newsList = [];

                this.$axios
                    .get(
                        `search/news/${this.filter}`,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status == 204){
                            this.$Utilities.controlAlert(null, this.alert, 'emptyList');

                            return;
                        }

                        this.newsList = res.data.map(item => {
                            return {
                                id: item.id,
                                title: this.$Utilities.markText(item.title, this.filter),
                                excerpt: this.$Utilities.markText(item.description, this.filter),
                                publishedDate: item.published_at
                            }
                        });
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            }
        },
        created(){
            this.search(this.$route.query.filter);
        },
        watch:{
            $route (to, from){
                this.search(this.$route.query.filter);
            }
        }
	}
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';
</style>