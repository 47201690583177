<template>
    <div class="container-login">
        <section v-show="activePage == 'social-media-login' || activePage == 'cile-login'" class="box-login box-registration-completed">
            <header>
                <img class="logo" src="~@assets/images/cile-logo.png" />
                <h1>Login</h1>

                <div class="horizontal-line"></div>

                <p>Seu login está sendo concluido.</p>
            </header>
            
            <div class="box-login-content">
                <cile-message :alert="alert" />
                
                <div v-show="cileLoginLoading" class="loading medium"></div>

                <cile-button 
                    v-show="!cileLoginLoading"
                    type="button" 
                    title="Voltar" 
                    buttonStyle="is-fullwidth btn-green"
                    @customClickEvent="goToPage('login')" />
            </div>
        </section>

        <section v-show="activePage == 'social-media-redirect'" class="box-login box-registration-completed">
            <header>
                <img class="logo" src="~@assets/images/cile-logo.png" />
                <h1>Login</h1>

                <div class="horizontal-line"></div>

                <p>Você será redirecionado para dar continuidade ao login por rede social.</p>
            </header>
            
            <div class="box-login-content">
                <p class="mb-6 has-text-centered">Você será redirecionado em <span class="font-weight-medium">{{ redirectTime.currentTime }} segundos</span>.</p>

                <cile-button 
                    type="button" 
                    title="Cancelar" 
                    buttonStyle="is-fullwidth btn-green"
                    @customClickEvent="cancelCountdown()" />
            </div>
        </section>

        <section v-show="activePage == 'new-register'" class="box-login box-registration-completed">
            <header>
                <img class="logo" src="~@assets/images/cile-logo.png" />
                <h1>Cadastro Realizado com Sucesso</h1>

                <div class="horizontal-line"></div>

                <p>Agradecemos o cadastro. Aproveite!</p>
            </header>

            <div class="box-login-content">
                <cile-button 
                    type="button" 
                    title="Continuar" 
                    buttonStyle="is-fullwidth btn-green"
                    @customClickEvent="goToPage('login')" />
            </div>
        </section>

        <section v-show="activePage == 'registration-redirect'" class="box-login box-registration-completed">
            <header>
                <img class="logo" src="~@assets/images/cile-logo.png" />

                <h1>Cadastro</h1>

                <p v-if="$Utilities.hasProperty($route.params, 'customMessage') && $route.params.customMessage != ''">{{ $route.params.customMessage }}</p>
                <p v-else>Faça o cadastro, é rápido e fácil!.</p>

                <div class="horizontal-line"></div>

                <p>Você será redirecionado para dar continuidade ao seu cadastro.</p>
            </header>
            
            <div class="box-login-content">
                <p class="mb-6 has-text-centered">Você será redirecionado em <span class="font-weight-medium">{{ redirectTime.currentTime }} segundos</span>.</p>

                <cile-button 
                    v-show="!$route.params.hideCancelButton"
                    type="button" 
                    title="Cancelar" 
                    buttonStyle="is-fullwidth btn-green"
                    @customClickEvent="cancelCountdown()" />
            </div>
        </section>

        <section v-show="activePage == 'first-access'" class="box-login box-registration-completed">
            <header>
                <img class="logo" src="~@assets/images/cile-logo.png" />

                <h1>Primeiro Acesso</h1>

                <p v-if="$Utilities.hasProperty($route.params, 'customMessage') && $route.params.customMessage != ''">{{ $route.params.customMessage }}</p>
                <p v-else>Será necessário confirmar seus dados para finalizar o cadastro.</p>

                <div class="horizontal-line"></div>

                <p>Você será redirecionado para dar continuidade ao seu cadastro.</p>
            </header>

            <div class="box-login-content">
                <p class="mb-6 has-text-centered">Você será redirecionado em <span class="font-weight-medium">{{ redirectTime.currentTime }} segundos</span>.</p>

                <cile-button 
                    v-show="!$route.params.hideCancelButton"
                    type="button" 
                    title="Cancelar" 
                    buttonStyle="is-fullwidth btn-green"
                    @customClickEvent="cancelCountdown()" />
            </div>
        </section>

        <section v-show="activePage == 'updated-registration'" class="box-login box-registration-completed">
            <header>
                <img class="logo" src="~@assets/images/cile-logo.png" />
                <h1>Cadastro atualizado com Sucesso</h1>

                <div class="horizontal-line"></div>

                <p>Agradecemos o cadastro. Aproveite!</p>
            </header>

            <div class="box-login-content">
                <cile-button 
                    type="button" 
                    title="Continuar" 
                    buttonStyle="is-fullwidth btn-green"
                    @customClickEvent="goToPage('login')" />
            </div>
        </section>

        <section v-show="activePage == 'forgot-password'" class="box-login box-forgot-password">
            <header>
                <h1>Esqueceu a senha?</h1>
                <p>Não se preocupe. Enviaremos uma mensagem para o seu e-mail ou telefone cadastrado para que você possa redefinir sua senha.</p>
            </header>
            
            <div class="box-login-content">
                <cile-message :alert="alert" />

                <form @submit.prevent="submitForgotPassword">
                    <cile-input
                        type="tel"
                        label="Digite seu CPF"
                        placeholder="Ex: 123.123.123-12"
                        inputMask="###.###.###-##"
                        v-model="$v.data.forgotPassword.cpf.$model" 
                        :validation="$v.data.forgotPassword.cpf" />

                    <cile-button 
                        type="submit" 
                        title="Enviar" 
                        buttonStyle="is-fullwidth btn-green"
                        :loading="sendingRequisition" />

                    <cile-button 
                        type="button" 
                        title="Voltar" 
                        buttonStyle="button is-fullwidth text-green"
                        @customClickEvent="goToPage('login')"
                        :isDisabled="sendingRequisition" />
                </form>        
            </div>
        </section>

        <section v-show="activePage == 'login'" class="box-login">
            <header>
                <h1>Bem-vindo ao CILE!</h1>

                <p>Se você já tem cadastro no site da Editora Brasil Seikyo ou do CILE, use o mesmo login e senha.</p>
            </header>

            
            <div class="box-login-content">
                <cile-message :alert="alert" />

                <form @submit.prevent="submitLogin">
                    <cile-input
                        type="tel"
                        label="Digite seu CPF"
                        placeholder="Ex: 123.123.123-12"
                        inputMask="###.###.###-##"
                        v-model="$v.data.login.cpf.$model" 
                        :validation="$v.data.login.cpf" />

                    <cile-input
                        label="Digite sua senha"
                        type="password"
                        v-model="$v.data.login.senha.$model" 
                        :validation="$v.data.login.senha" />

                    <cile-button 
                        type="submit" 
                        title="Entrar" 
                        buttonStyle="is-fullwidth btn-green"
                        :loading="sendingRequisition" />

                    <cile-button 
                        type="button" 
                        title="Esqueci a senha" 
                        buttonStyle="button is-fullwidth text-green"
                        @customClickEvent="goToPage('forgot-password')"
                        :isDisabled="sendingRequisition" />
                </form>

                <div class="login-division">
                    <p>ou entrar com</p>
                </div>

                <div class="social-media-login">
                    <ul>
                        <li><img src="~@assets/images/social-media/a-logo.png" @click="goToPage('social-media-redirect')" /></li>
                        <!--li><img src="~@assets/images/social-media/f-logo.png" @click="goToPage('social-media-redirect')" /></li-->
                        <li><img src="~@assets/images/social-media/g-logo.png" @click="goToPage('social-media-redirect')" /></li>
                    </ul>
                </div>
            </div>

            <footer>
                <p class="mb-3">Não é cadastrado? <a href="#" class="text-dark-green" @click="goToPage('registration-redirect')">Clique aqui para se cadastrar.</a></p>

                <router-link 
                    :to="{name: 'landingPage'}" 
                    class="has-text-centered has-text-weight-semibold text-dark-green is-block">Voltar para tela inicial
                </router-link>
            </footer>
        </section>

        <section v-show="activePage == 'check-registration'" class="box-login box-registration-completed">
            <header>
                <img class="logo" src="~@assets/images/cile-logo.png" />

                <h1>Verificar Cadastro</h1>
                <p>Informe seu CPF</p>
            </header>

            <div class="box-login-content">
                <cile-message :alert="alert" />

                <form v-if="data.checkRegistration.action == 'check-registration'" @submit.prevent="submitCheckRegistration">
                    <cile-input
                        type="tel"
                        label="Digite seu CPF"
                        placeholder="Ex: 123.123.123-12"
                        inputMask="###.###.###-##"
                        v-model="$v.data.checkRegistration.cpf.$model" 
                        :validation="$v.data.checkRegistration.cpf" />

                    <cile-button 
                        type="submit" 
                        title="Enviar" 
                        buttonStyle="is-fullwidth btn-green"
                        :loading="sendingRequisition" />
                </form>

                <cile-button 
                    v-if="data.checkRegistration.action == 'login'"
                    type="button" 
                    title="Realizar Login" 
                    buttonStyle="button is-fullwidth btn-green"
                    @customClickEvent="goToPage('login')" />
            </div>
        </section>
    </div>
</template>

<script>
    import Input from '@components/shared/fields/Input.vue';
    import Button from '@components/shared/button/Button.vue';
    import Message from '@components/shared/message/Message.vue';

    import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';

    export default {
        name: 'Login',
        metaInfo: {
            title: 'Login'
        },
		components: {
            'cile-input': Input,
            'cile-button': Button,
            'cile-message': Message
		},
        data() {
			return {
                data: {
                    login: {
                        cpf: '',
                        senha: ''
                    },
                    forgotPassword: {
                        cpf: ''
                    },
                    checkRegistration: {
                        cpf: '',
                        action: 'check-registration'
                    }
                },
                redirectTime: {
                    startTime: 5,
                    currentTime: 0,
                    countdown: null
                },
                activePage: 'login',
                sendingRequisition: false,
                cileLoginLoading: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
			};
        },
        validations() {
            return {
                data: {
                    login: {
                        cpf: {
                            required: requiredIf(() => this.activePage == 'login')
                        },
                        senha: {
                            required: requiredIf(() => this.activePage == 'login')
                        }
                    },
                    forgotPassword: {
                        cpf: {
                            required: requiredIf(() => this.activePage == 'forgot-password')
                        }
                    },
                    checkRegistration: {
                        cpf: {
                            required: requiredIf(() => this.activePage == 'check-registration')
                        }
                    }
                }
            }
        },
        methods: {
            submitLogin(){
                this.alert.show = false;

                this.$v.$touch();

				if(this.$v.$invalid) return;

                this.sendingRequisition = true;

                this.$axios
					.post('account/login', this.data.login)
					.then(res => {
                        this.$Session.set(this.$userSessionName, res.data);

                        if(this.$Utilities.hasProperty(this.$route.params, 'redirectTo')){
                            this.$router.push({
                                name: this.$route.params.redirectTo.name,
                                params: this.$route.params.redirectTo.params
                            });
                        }else{
                            this.$router.push({name: 'dashboard'});
                        }
					})
					.catch(error => {
                        switch (error.response.status) {
                            case 307:
                                let token_verificacao_beneficiario = this.$Utilities.hasProperty(this.$route.query, 'token_verificacao_beneficiario') ? this.$route.query.token_verificacao_beneficiario : '';

                                this.goToPage(error.response.data.action, error.response.data.url_redirect, false, '', token_verificacao_beneficiario);
                                break;
                        
                            default:
                                this.$Utilities.controlAlert(error.response.data, this.alert, 'danger');
                                break;
                        }
                    })
					.finally(() => this.sendingRequisition = false);
            },
            cileLogin($cileToken){
                this.alert.show = false;

                this.cileLoginLoading = true;

                this.$axios
					.post('account/login-cile', {token: $cileToken})
                    .then(res => {
                        this.$Session.set(this.$userSessionName, res.data);

                        this.$router.push({name: 'dashboard'});
					})
					.catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
					.finally(() => this.cileLoginLoading = false);
            },
            loginByToken(){
                if(typeof this.$route.query.token != 'undefined') {
                    this.cileLogin(this.$route.query.token);
                }else{
                    this.goToPage('login');
                }
            },
            submitForgotPassword(){
                this.alert.show = false;

                this.$v.$touch();

				if(this.$v.$invalid) return;

                this.sendingRequisition = true;

                this.$axios
					.post('account/forgot-password', this.data.forgotPassword)
					.then(res => this.$Utilities.controlAlert(null, this.alert, 'success', res.data.message))
					.catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
					.finally(() => this.sendingRequisition = false);
            },
            submitCheckRegistration(){
                this.alert.show = false;

                this.$v.$touch();

				if(this.$v.$invalid) return;

                this.sendingRequisition = true;

                this.$axios
					.post('account/check-registration', {
                        cpf: this.data.checkRegistration.cpf,
                        token: this.$route.query.token
                    })
					.then(res => {
                        if(this.$Utilities.hasProperty(res.data, 'action')){
                            this.data.checkRegistration.action = res.data.action;
                        }

                        if(this.$Utilities.hasProperty(res.data, 'message')){
                            this.$Utilities.controlAlert(null, this.alert, 'success', res.data.message);
                        }
                    })
					.catch(error => {
                        switch (error.response.status) {
                            case 307:
                                this.goToPage(
                                    error.response.data.action, 
                                    error.response.data.url_redirect, 
                                    true,
                                    error.response.data.custom_message,
                                    error.response.data.token_verificacao_beneficiario);
                                break;

                            default:
                                this.$Utilities.controlAlert(error.response.data, this.alert, 'danger');
                                break;
                        }
                    })
					.finally(() => this.sendingRequisition = false);
            },
            goToPage(page, urlRedirect = false, hideCancelButton = false, customMessage = '', token_verificacao_beneficiario = ''){
                this.alert.show = false;

                this.$router.push({
                    name: 'login', 
                    params: {
                        page: page, 
                        urlRedirect: urlRedirect,
                        hideCancelButton: hideCancelButton,
                        customMessage: customMessage
                    },
                    query: token_verificacao_beneficiario != '' ? 
                           {token_verificacao_beneficiario: token_verificacao_beneficiario} :
                           {}
                });
            },
            startCountdown(action, redirectToSpecificPage = false){
                let token_verificacao_beneficiario = this.$Utilities.hasProperty(this.$route.query, 'token_verificacao_beneficiario') ? 
                                                     '&' +  'token_verificacao_beneficiario=' + this.$route.query.token_verificacao_beneficiario : 
                                                     '';

                this.redirectTime.currentTime = this.redirectTime.startTime;

                this.redirectTime.countdown = setInterval(($this) => {
                    if($this.redirectTime.currentTime <= 0) {
                        $this.cancelCountdown(false);

                        window.location.href = redirectToSpecificPage === false ? 
                                               `${$this.$ecommerceUrl}?action=${action}&reader_url=${window.location.origin}/account${token_verificacao_beneficiario}` : 
                                               redirectToSpecificPage + token_verificacao_beneficiario;

                        return;
                    }

                    $this.redirectTime.currentTime -= 1;
                }, 1000, this);
            },
            cancelCountdown($redirect = true){
                if(this.redirectTime.countdown != null) clearInterval(this.redirectTime.countdown);

                if($redirect) this.goToPage('login');
            },
            initialPageSettings(){
                switch (this.activePage) {
                    case 'registration-redirect':
                        this.startCountdown('new-register');
                        break;

                    case 'first-access':
                        if(typeof this.$route.params.urlRedirect == 'undefined' || this.$route.params.urlRedirect === false){
                            this.goToPage('login');
                        }else{
                            this.startCountdown('first-access', `${this.$route.params.urlRedirect}&action=updated-registration&reader_url=${window.location.origin}/account`);
                        }
                        break;

                    case 'updated-registration':
                        this.goToPage('updated-registration');
                        break;

                    case 'social-media-redirect':
                        this.startCountdown('social-media-login');
                        break;

                    case 'social-media-login':
                        this.loginByToken();
                        break;

                    case 'cile-login':
                        this.loginByToken();
                        break;
                }
            }
        },
        created() {
            if(this.$Utilities.hasProperty(this.$route.params, 'message')){
                this.$Utilities.controlAlert(null, this.alert, this.$route.params.message);
            }

            this.activePage = this.$route.params.page;

            this.initialPageSettings();
        },
        watch: {
            $route (to, from){
                this.activePage = to.params.page;

                this.initialPageSettings();
            }
        }
	};
</script>

<style></style>