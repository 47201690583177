<template>
	<section class="default-session">
        <header>
            <h1>Últimas notas</h1>
        </header>

        <div :class="loadingContent ? 'loading large' : ''">
            <cile-message :alert="alert" />

            <div v-show="!loadingContent">
                <cile-annotation 
                    v-for="(annotation, index) in annotations"
                    :key="`annotation-${index}`"
                    :annotation="annotation"
                    @annotationClickEvent="goToBook" />
            </div>
        </div>

        <cile-modal-subscription-plan 
            :open="modal.open"
            :url="modal.url"
            @closeModal="closeModal()" />
    </section>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import Annotation from '@components/cile/Annotation.vue';
    import ModalSubscriptionPlan from '@components/cile/ModalSubscriptionPlan.vue';

    export default {
        name: 'LastNotes',
        components: {
            'cile-message': Message,
            'cile-annotation': Annotation,
            'cile-modal-subscription-plan': ModalSubscriptionPlan
		},
        data(){
            return {
                annotations: [],
                loadingContent: false,
                modal: {
                    open: false,
                    url: ''
                },
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            }
        },
        methods: {
            getAnnotations() {
                this.alert.show = false
                this.loadingContent = true;
                this.annotations = [];

                this.$axios
                    .get(
                        'book/last-annotations',
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status == 204){
                            this.$Utilities.controlAlert(null, this.alert, 'emptyList');

                            return;
                        }

                        this.annotations = res.data.map(item => {
                            return {
                                id: item.id,
                                bookId:  item.book.id,
                                bookTitle: item.book.title,
                                date: item.created_at,
                                cfiRange: item.cfi_range,
                                markedText: this.filterBy == 'tag' ? item.marked_text : this.$Utilities.markText(item.marked_text, this.filter),
                                color: item.color,
                                description: this.filterBy == 'tag' ? item.description : this.$Utilities.markText(item.description, this.filter),
                                tags: item.book_annotation_tags.map(item => item.book_tags.description),
                                subscription_plan_url: item.book.subscription_plan_url,
                                is_my_book: item.book.is_my_book,
                                loading: false,
                                options: false
                            }
                        });
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            },
            goToBook(annotation){
                if(annotation.is_my_book == 0 && annotation.subscription_plan_url != ''){
                    this.openModal(annotation.subscription_plan_url);
                }else{
                    this.$router.push({name: 'ebookReader', params: {id: annotation.bookId, cfiRange: annotation.cfiRange}});
                }
            },
            openModal(url){
                this.modal.open = true;
                this.modal.url = url;
            },
            closeModal(){
                this.modal.open = false;
            }
        },
        created(){
            this.getAnnotations();
        }
	}
</script>

<style lang="scss"></style>