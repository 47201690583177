<template>
    <div class="book-details">
        <cile-breadcrumb 
            :pages="[
                {name: 'Anotações', route: ''}]" />

        <cile-library-books />

        <div class="horizontal-line"></div>

        <cile-last-notes />
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import LibraryBooks from '@views/annotations/components/LibraryBooks.vue';
    import LastNotes from '@views/annotations/components/LastNotes.vue';

    export default {
        name: 'Annotations',
        metaInfo: {
            title: 'Anotações'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-library-books': LibraryBooks,
            'cile-last-notes': LastNotes
        },
        data() {
			return {};
        },
        methods: {},
        created() {}
	};
</script>

<style lang="scss">
    
</style>