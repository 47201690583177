<template>
	<div 
        class="input-component field" 
        :class="[
            validation.$error ? 'error' : '', 
            activeFilter ? 'active-filter' : '',
            type == 'inputButton' ? 'input-button' : ''
        ]">
        <label v-if="label" class="label">{{ label }}</label>

        <div class="control" :class="controlStyle">
            <div>
                <span class='clear-field' @click="clearClickEvent()"><i class='clear-icon'></i></span>

                <!-- Calendario -->
                <date-picker v-if="type == 'datepicker'"
                    input-class="input"
                    valueType="format" 
                    :lang="datepicker.lang"
                    format="DD/MM/YYYY"
                    :value="value"
                    :placeholder="placeholder" 
                    @input="setDate"
                    @change="changeEvent"
                    :disabled="readonly"
                    :not-after="disableFutureDates">
                </date-picker>

                <!-- Input com mascara -->
                <the-mask v-else-if="inputMask" 
                    :mask="inputMask"
                    class="input" 
                    :type="type" 
                    :placeholder="placeholder" 
                    :value="value"
                    @input.native="maskValue()"
                    @keyup.native="keyUpEvent()"
                    @blur.native="blurEvent()"
                    :readonly="readonly" />

                <!-- Input padrão ou busca -->
                <input v-else 
                    class="input"
                    :class="type == 'inputSearch' ? 'input-search' : ''"
                    :autocomplete="!autocomplete ? 'new-password' : 'on'"
                    :type="type == 'inputSearch' ? 'text' : type" 
                    :placeholder="placeholder" 
                    :value="value"
                    @input="$emit('input', $event.target.value)"
                    @keyup="keyUpEvent"
                    @blur="blurEvent"
                    @click="clickInputEvent()" 
                    :readonly="readonly"
                    :disabled="disabled" />

                <button 
                    v-if="type == 'inputSearch'" 
                    type="button" 
                    class="btn-cancel-search" 
                    @click="cancelSearch()"></button>

                <button 
                    v-if="type == 'inputButton'"
                    class="button primary-button btn-green"
                    @click="buttonClickEvent()">
                    Adicionar
                </button>
            </div>

            <span class="validation-error" v-if="validation.$error">{{ $Utilities.getErrorMsg(validation) }}</span>
        </div>
    </div>
</template>

<script>
    import {TheMask} from 'vue-the-mask';
    import DatePicker from 'vue2-datepicker';

    export default {
        components: {
            'TheMask': TheMask,
            'date-picker': DatePicker
        },
        data(){
            return {
                datepicker: {
                    lang: {
                        days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                        placeholder: {
                        date: '',
                        dateRange: 'Select Date Range'
                        }
                    }
                }
            }
        },
		props: {
            type: {
                required: false,
                default: 'text',
                type: String
            },
            label: {
                required: false,
                type: String
            },
            placeholder: {
                type: String
            },            
            autocomplete: {
                required: false,
                default: false,
                type: Boolean
            },
            readonly: {
                required: false,
                default: false,
                type: Boolean
            },
            disabled: {
                required: false,
                default: false,
                type: Boolean
            },            
            value: {
                required: false
            },
            disablePreviousDates: {
                required: false,
                default: ''
            },
            disableFutureDates: {
                required: false,
                default: ''
            },
            activeFilter: {
                required: false,
                type: Boolean,
                default: false
            },
            inputMask: {
                required: false,
                default: false
            },
            loading: {
                required: false,
                default: false,
                type: Boolean
            },
            isCentralized: {
                required: false,
                type: Boolean,
                default: false
            },
            validation: {
                required: false,
                type: Object,
                default: () => {
                    return {
                        $error: false
                    }
                }
            }
        },
        methods: {
            keyUpEvent(){
                this.$emit('customKeyUpEvent', this.returnOnlyNumbers(event.target.value));
            },
            keyUpDatepicker(){
                let date = event.target.value;

                if(event.target.value.length > 10){
                    event.target.value = event.target.value.substring(0, 10);
                }else{
                    switch (event.target.value.length) {
                        case 2:
                            date = `${date}/`;
                            break;
    
                        case 5:
                            date = `${date}/`;
                            break;                
                    }
    
                    event.target.value = date;
                }

                if(event.target.value.length == 10){
                    this.setDate(date);
                }

                return true;
            },
            setDate(date){
                return this.$emit('input', date);
            },
            maskValue(){
                if(this.keepMask){
                    return this.$emit('input', event.target.value);
                }else{
                    return this.$emit('input', this.returnOnlyNumbers(event.target.value));
                }
            },
            changeEvent(){
                this.$emit('customChangeEvent');
            },
            blurEvent(){
                this.$emit('customBlurEvent');
            },
            clickInputEvent(){
                this.$emit('customClickEvent');
            },
            clearClickEvent(){
                this.$emit('customClearClickEvent');
            },
            cancelSearch(){
                this.$emit('cancelSearchEvent');
            },
            buttonClickEvent(){
                this.$emit('customButtonClickEvent');
            },
            returnOnlyNumbers(number){
                return number.replace(/[^0-9a-zA-Z]+/g,'');
            }
        },
        computed: {
            controlStyle(){
                let style = '';

                if(this.loading) style += ' is-loading ';
                if(this.isCentralized) style += ' has-text-centered ';

                return style;
            }
        }
	}
</script>

<style lang="scss">
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .field.input-component {
        &:not(:last-child) {
            margin-bottom: 25px;
        }

        label {
            font-size: map.get($fields, 'label-font-size');
            line-height: map.get($fields, 'label-line-height');
            font-weight: map.get($fields, 'label-font-weight');
        
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        input {
            font-size: map.get($fields, 'input-font-size');
            line-height: map.get($fields, 'input-line-height');

            border-color: map.get($fields, 'input-border-color');
            border-radius: 0px;
            box-shadow: none;

            @include field-placeholder;
        }

        .mx-datepicker {
            width: 100%;
        }

        &.input-button {
            .control {
                > div {
                    width: 100%;
                    display: inline-flex;

                    input {
                        border-radius: 5px 0px 0px 5px;
                    }

                    button {
                        @include font-config(14px, 18px, map.get($font-weights, 'medium'));

                        padding-top: 10px;
                        padding-bottom: 10px;
                        border-radius: 0px 5px 5px 0px;
                    }
                }
            }
        }
    }
</style>