<template>
    <div class="book-details">
        <cile-breadcrumb 
            :pages="[
                {name: 'Meus Livros', route: 'myBooks'},
                {name: 'Detalhes do Livro', route: ''}]"
            class="is-hidden-mobile" />

        <cile-book-details />

        <cile-my-annotations />

        <div class="fullwidth-featured-content featured-content">
            <cile-featured-video 
                title="Vídeo sobre o Livro"
                :endpoint="`book/${$route.params.id}/video_review`" />

            <cile-my-book-review 
                title="Resenha"
                :endpoint="`book/${$route.params.id}/review`" />
        </div>

        <cile-spotify-banner />
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import BookDetails from '@views/myBooks/components/BookDetails.vue';
    import FeaturedReview from '@components/cile/FeaturedReview.vue';
    import FeaturedVideo from '@components/cile/FeaturedVideo.vue';
    import SpotifyBanner from '@views/myBooks/components/SpotifyBanner.vue';
    import MyAnnotations from '@views/myBooks/components/MyAnnotations.vue';

    export default {
        name: 'BookDetails',
        metaInfo: {
            title: 'Detalhes do Livro'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-book-details': BookDetails,
            'cile-my-book-review': FeaturedReview,
            'cile-featured-video': FeaturedVideo,
            'cile-spotify-banner': SpotifyBanner,
            'cile-my-annotations': MyAnnotations
        },
        data() {
			return {
                bookReview: null
            };
        },
        methods: {},
        created() {}
	};
</script>

<style lang="scss">
    @media screen and (max-width: 1023px), print {
        .book-details {
            .featured-content {
                > div:not(:last-child) {
                    margin-bottom: 25px;
                }
            }
        }

        .book-details {
            .featured-content {
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                background-image: url('~@assets/images/bg1.svg');
            }
        }
    }
</style>