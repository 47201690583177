<template>
	<div class="textarea-component field" :class="validation.$error ? 'error' : ''">
        <label v-if="label" class="label">{{ label }}</label>

        <div class="control">
            <textarea 
                class="textarea has-fixed-size custom-scroll" 
                :placeholder="placeholder" 
                :value="value"
                @input="$emit('input', $event.target.value)"
                :readonly="readonly"
                :disabled="disabled"></textarea>

            <span class="validation-error" v-if="validation.$error">{{ $Utilities.getErrorMsg(validation) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                required: false,
                type: String
            },
            placeholder: {
                type: String
            },
            value: {
                required: false
            },
            readonly: {
                required: false,
                default: false,
                type: Boolean
            },
            disabled: {
                required: false,
                default: false,
                type: Boolean
            },
            validation: {
                required: false,
                type: Object,
                default: () => {
                    return {
                        $error: false
                    }
                }
            },     
            error: {
                required: false,
                type: Boolean,
                default: false
            },
            errorMessage: {
                required: false,
                type: String,
                default: ''
            }
        }
	}
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .textarea-component {
        &.field:not(:last-child) {
            margin-bottom: 15px;
        }

        .label {
            font-size: map.get($fields, 'label-font-size');
            line-height: map.get($fields, 'label-line-height');
            font-weight: map.get($fields, 'label-font-weight');            

            margin-left: 20px;
        }

        textarea {
            font-size: map.get($fields, 'input-font-size');
            line-height: map.get($fields, 'input-line-height');

            max-width: 100%;
            border-radius: 5px;
            border: 1px solid map.get($fields, 'input-border-color');
            box-shadow: none;
            background-color: #ffffff;
            padding-right: 10px;

            @include field-placeholder;

            &:read-only {
                background-color: transparent;
            }

            &:disabled {
                background-color: rgba(122, 122, 122, 0.1) !important;
            }
    
            &.custom-scroll::-webkit-scrollbar-track,
            &.custom-scroll::-webkit-scrollbar-thumb {
                border-radius: 0px 3px 3px 0px;
            }

            &.custom-scroll::-webkit-scrollbar-track {
                margin: 0px 0px 0px 0px;
            }

            &.custom-scroll::-webkit-scrollbar {
                width: 6px;
            }
        }

        &.textarea-type-2 {
            .label {
                margin-left: 0px;
            }
        }
    }
</style>
