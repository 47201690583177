<template>
    <article class="message is-warning expired-subscription-plan-notice">
        <div class="message-body">
            <div class="message-title">Olá</div>

            <p class="mb-5 has-text-centered">Este conteúdo é exclusivo para assinantes. Para acessar, assine agora mesmo o CILE Digital.</p>            
        
            <cile-button 
                type="link"
                :href="url" 
                title="ASSINAR" 
                class="has-text-centered"
                buttonStyle="button btn-green-2" />
        </div>
    </article>
</template>

<script>
    import Button from '@components/shared/button/Button.vue';

    export default {
        name: 'ExpiredSubscriptionPlanNotice',
        components: {
            'cile-button': Button
		},
        props: {
            url: {
                required: true,
                type: String
            }
        },
        data(){
            return {}
        },
        methods: {
        }
	}
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .expired-subscription-plan-notice {
        .message-title {
            @include font-config(22px, 26px, map.get($font-weights, 'semi-bold'), center);

            margin-bottom: 20px;
        }
    }
</style>