<template>
    <div class="login">
        <div class="container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoginTemplate'
    }
</script>

<style lang="scss">
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .login {
        background: transparent linear-gradient(180deg, #EBAAA8 0%, #E9C9B7 100%) 0% 0% no-repeat padding-box;

        &,
        .container,
        .container-login {
            height: 100%;
            min-height: 100%;
        }

        .container-login {
            display: flex;
            align-items: center;

            min-height: 100vh;
            padding-left: 15px;
            padding-right: 15px;
        }

        footer {
            border-top: 1px solid #D2D2D2;
            background-color: #F5F2ED;

            p {
                font-weight: 600;
                text-align: center;
            }
        }

        .box-login {
            width: 550px;
            max-width: 550px;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            background-color: #FEFEFE;

            header,
            footer,
            .box-login-content {
                padding-left: 35px;
                padding-right: 35px;
            }

            header {
                padding-top: 35px;
                padding-bottom: 35px;

                h1 {
                    @include font-config(30px, 34px, map.get($font-weights, 'semi-bold'), center);

                    margin-bottom: 15px;
                }

                p {
                    color: #000000;
                    text-align: center;
                    font-family: 'Roboto', sans-serif !important;

                    max-width: 450px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            footer {
                padding-top: 35px;
                padding-bottom: 35px;
                border-radius: 0px 0px 10px 10px;
            }

            .box-login-content {
                padding-bottom: 35px;
            }

            &.box-registration-completed {
                header {
                    padding-bottom: 45px;
                }

                .logo {
                    display: block;
                    width: 100px;
                    margin: 0px auto 35px auto;
                }

                .horizontal-line {
                    width: 80%;
                    margin: 25px auto;
                }
            }
        }

        .login-division {
            text-align: center;

            position: relative;

            &:before {
                content: '';

                top: 14px;
                position: absolute;
                z-index: 0;
                display: block;
                height: 1px;
                width: 100%;
                background-color: #D2D2D2;
            }

            p {
                color: #3C4349;

                position: relative;
                z-index: 1;
                padding: 0px 15px;
                display: inline-block;
                margin-left: auto;
                margin-right: auto;
                background-color: #FEFEFE;
            }
        }

        .social-media-login {
            margin-top: 35px;

            ul {
                text-align: center;
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    margin: 0 10px;

                    img {
                       cursor: pointer;
                        width: 60px;
                        padding: 16px;
                        border-radius: 50px;
                        border: 1px solid #E8E8E8;
                        box-shadow: 0 3px 6px #00000029;
                    }
                }
            }
        }
    }

    .password-rules {
        margin-bottom: 25px;

        .password-rules-list {
            padding: 15px 25px;
            margin-bottom: 15px;
            border-radius: 5px;
            border: 1px solid #989898;
        }

        .content-description ul {
            list-style: initial;
            padding-left: 20px;
            margin-bottom: 0px;

            li:not(:last-child) {
                margin-bottom: 10px;
            }

            li:not(:last-child) {
                margin-bottom: 5px;
            }
        }

        .password-strength-indicator {
            > div {
                display: flex;
            }

            .indicator {
                flex-grow: 1;
                width: auto;
                height: 6px;
                margin: 0px 5px;
                border-radius: 5px;
                background-color: #DCDCDC;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    display: block;
                    height: 6px;
                    width: 0%;
                    border-radius: 5px;
                    transition: width .8s, background-color .8s;
                }
            }

            .nivel-1 .indicator:nth-child(-n+1):before {
                width: 100%;
                background-color: #ff1717;
            }

            .nivel-2 .indicator:nth-child(-n+2):before {
                width: 100%;
                background-color: #e0dd02;
            }

            .nivel-3 .indicator:nth-child(-n+3):before {
                width: 100%;
                background-color: #45e002;
            }

            .nivel-4 .indicator:nth-child(-n+4):before {
                width: 100%;
                background-color: #009C98;
            }
        }
    }
</style>