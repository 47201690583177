<template>
	<section class="default-session">
        <header>
            <h1>Livros com notas em sua biblioteca</h1>
        </header>

        <div :class="loadingContent ? 'loading large' : ''">
            <cile-message :alert="alert" />

            <div v-show="!loadingContent" class="columns is-multiline is-mobile">
                <div v-for="(book, index) in bookShowcase" 
                    :key="`last_books_bought_${index}`" 
                    class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd">
                    <cile-book-showcase 
                        :book="book" 
                        :linkBookDetail="true" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import BookShowcase from '@components/cile/BookShowcase.vue';

    export default {
        name: 'LibraryBooks',
        components: {
            'cile-message': Message,
            'cile-book-showcase': BookShowcase
		},
        data(){
            return {
                bookShowcase: [],
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            }
        },
        methods: {
            getBooks() {
                this.alert.show = false
                this.loadingContent = true;
                this.bookShowcase = [];

                this.$axios
                    .get(
                        'book/with-annotations',
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status == 204){
                            this.$Utilities.controlAlert(null, this.alert, 'emptyList');

                            return;
                        }

                        this.bookShowcase = res.data;
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            }
        },
        created(){
            this.getBooks();
        }
	}
</script>

<style lang="scss"></style>