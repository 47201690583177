<template>
    <div v-show="!isEmpty">
        <section :id="review != null ? `featured-review-${review.id}` : ''" class="default-session featured-section">
            <header class="is-hidden-mobile">
                <h1>{{ title }}</h1>
            </header>

            <article 
                class="featured-review" 
                :class="[
                    loadingContent ? 'loading medium v-align' : '',
                    review == null && !loadingContent ? 'review-not-found' : ''
                ]">
                <p v-if="review == null && !loadingContent" class="not-found-text">Não há resenhas deste livro.</p>


                <div v-if="review">
                    <cile-reviews
                        :review="review" />

                    <div class="ribbon"></div>
                </div>
            </article>
        </section>
    </div>
</template>

<script>
    import Button from '@components/shared/button/Button.vue';
    import Reviews from '@views/feedReviews/components/Reviews.vue';

    export default {
        name: 'FeaturedReview',
        components: {
            'cile-button': Button,
            'cile-reviews': Reviews
		},
        props: {
            title: {
                required: false,
                type: String
            },
            endpoint: {
                required: true                
            }
        },
        data(){
            return {
                review: null,
                isEmpty: false,
                loadingContent: false
            }
        },
        methods: {
            getReview(){
                this.loadingContent = true;

                this.$axios
                    .get(
                        this.endpoint,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status != 204){
                            this.review = res.data;
                        } else {
                            this.isEmpty = true;
                        }
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            }
        },
        created(){
            this.getReview();
        }
	}
</script>

<style lang="scss">
    .featured-review footer .button {
        width: 100%;
        max-width: 260px;
    }
</style>

<style lang="scss">
    .featured-review {
        .feed-review-item {
            padding: 50px 25px
        }

        .feed-review-item:not(:last-child) {
            margin-bottom: 0px
        }
    }
</style>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .featured-section {
        margin: 0px 10px;
    }

    .featured-review {
        position: relative;

        header {
            display: flex;
            align-items: center;

            margin-bottom: 25px;

            .book-cover img {
                max-width: 210px;
            }
        
            .book-info {
                margin-left: 20px;
            }
        }

        .book-info .book-review-date {
            @include font-config(16px, 20px);
        }

        .book-info .book-synopsis,
        .book-info .book-title {
            margin-bottom: 5px;
        }

        .book-info .book-title {
            font-weight: 600;
        }

        .book-info .book-review-date {
            font-style: italic;
        }

        .featured-review-content {
            margin-bottom: 35px;
        }

        footer {
            text-align: center;
        }

        .ribbon {
            position: absolute;
            top: -4px;
            right: 25px;


            display: block;
            width: 34px;
            height: 45px;
            background-color: #009C98;

            &:before,
            &:after {
                content: '';
                
                position: absolute;
                bottom: -17px;
                width: 0px;
                height: 0px;

                border-top: 17px solid transparent;
                border-bottom: 17px solid transparent;
            }

            &:before {
                content: '';
                    
                right: 0px;
                border-right: 32px solid #009C98;
            }

            &:after {
                content: '';
                
                left: 0px;
                border-left: 32px solid #009C98;
            }
        }
    }

    @media screen and (max-width: 768px), print {
        .home-featured-review {
            header {
                text-align: center;    
                flex-direction: column;
            
                .book-cover {
                    margin-bottom: 25px;
                }
        
                .book-info {
                    margin-left: 0px;
                }
            }    
        }
    }

    @media screen and (max-width: 992px), print {
        .featured-review {
            .ribbon {
                height: 24px;
            }
        }
    }
</style>