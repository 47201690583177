<template>
    <article class="book-excerpt-item">
        <p class="book-info"><span class="book-name">{{ paragraph.book.title }}</span> - <span class="book-chapter">{{ paragraph.chapter }}</span></p>
        
        <p class="book-excerpt" v-html="paragraph.paragraph_excerpt"></p>

        <router-link 
            v-if="linkBookLibrary"
            :to="{
                name: 'ebookReader', 
                params: {
                    id: paragraph.book.id, 
                    filter: paragraph.filter, 
                    keyword: keyword
                }
            }"
            class="btn-at-the-library">
            <img src="~@assets/images/icons/icon_na_biblioteca.svg" /> Na biblioteca 
        </router-link>

        <router-link 
            v-if="linkBookDetail"
            :to="{name: 'bookDetails', params: {id: paragraph.book.id}}" 
            class="btn-at-the-library">Detalhes do livro
        </router-link>

        <a 
            v-if="linkBookEcommerce" 
            :href="paragraph.book.ecommerce_url" 
            class="btn-buy" 
            target="_blank">Comprar</a>

        <a 
            v-if="linkSubscriptionPlan" 
            :href="paragraph.book.subscription_plan_url" 
            class="btn-buy" 
            target="_blank">Assinar</a>
    </article>
</template>

<script>
    export default {
        name: 'BookExcerptsComponent',
        data(){
            return {}
        },
        props: {
            paragraph: {
                required: true,
                type: Object,
                default: () => {
                    return {
                        id: '',
                        text: '',
                        paragraph: '',
                        chapter: '',
                        bookTitle: ''
                    }
                }
            },
            keyword: {
                require: true,
                type: String
            },
            linkBookLibrary: {
                require: false,
                type:Boolean,
                default: false
            },
            linkBookDetail: {
                require: false,
                type:Boolean,
                default: false
            },
            linkBookEcommerce: {
                require: false,
                type:Boolean,
                default: false
            },
            linkSubscriptionPlan: {
                require: false,
                type:Boolean,
                default: false
            }
        }
	}
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .book-excerpt-item {
        &:not(:last-child){
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px solid #DCDCDC;
        }

        .book-info {
            @include font-config(16px, 20px);

            margin-bottom: 15px;

            .book-name {
                font-weight: map.get($font-weights, 'semi-bold');                
            }

            .book-chapter,
            .book-position {
                font-style: italic;
            }
        }

        .book-excerpt {
            @include font-config(16px, 20px);

            margin-bottom: 15px;

            .searched-excerpt {
                padding: 0px 2px;
                background-color: rgba(0, 170, 173, 0.2);
            }
        }

        .btn-at-the-library,
        .btn-buy {
            @include font-config(16px, 20px);

            border: none;
            cursor: pointer;
            background-color: transparent;
        }

        .btn-at-the-library {
            color: #00AAAD;

            display: flex;
            align-items: center;

            img {
                width: 15px;
                margin-right: 5px;
            }
        }

        .btn-buy {
            color: #F38480;
        }
    }
</style>