<template>
    <div class="logged-area light-mode">
        <header class="main-header">
            <div class="column-container is-hidden-mobile">
                <div class="columns">
                    <div class="column is-3">
                        <div class="header-first-column">
                            <figure class="image logo" @click="goToRoute('dashboard')">
                                <img src="~@assets/images/logo_cile_topo.svg" />
                            </figure>
                        </div>
                    </div>

                    <div class="column is-9">
                        <div class="header-second-column">
                            <form @submit.prevent="search">
                                <cile-input
                                    placeholder="Buscar..."
                                    class="search-input"
                                    v-model="filter" />
                            </form>

                            <div class="header-user-info">
                                <p v-if="this.user" class="logged-user">Olá, <span>{{ this.user.name }}</span></p>

                                <p class="is-clickable" @click="openLogoutModal()">Sair</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-header-mobile is-hidden-tablet">
                <div class="header-first-row">
                    <div>
                        <button class="menu-burger" @click="toggleMobileMainMenu('open')">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>

                    <div>
                        <figure class="image logo" @click="goToRoute('dashboard')">
                            <img src="~@assets/images/logo_cile_topo.svg" />
                        </figure>
                    </div>

                    <div class="header-user-info">
                        <p v-if="this.user" class="logged-user">Olá, <span>{{ userName }}</span></p>

                        <p class="is-clickable" @click="openLogoutModal()">Sair</p>
                    </div>
                </div>

                <form @submit.prevent="search">
                    <cile-input
                        placeholder="Buscar..."
                        class="search-input"
                        v-model="filter" />
                </form>
            </div>
        </header>

        <div class="main">
            <div class="column-container">
                <div class="columns">
                    <div class="column main-menu-box" :class="activeMobileMainMenu ? 'is-active' : ''">
                        <div class="leftover-menu" @click="toggleMobileMainMenu('close')"></div>

                        <aside class="menu main-menu">
                            <figure class="image logo is-hidden-tablet">
                                <a href="#"><img src="~@assets/images/cile-logo-2.svg" /></a>
                            </figure>

                            <ul class="menu-list">
                                <li v-for="(item, index) in itensMenu" :key="`${index} - ${item.meta.menu.title}`">
                                    <router-link 
                                        :to="{
                                            name: item.name,
                                            params: $Utilities.hasProperty(item.meta, 'params') ? 
                                                    {slug: item.meta.params.slug} : 
                                                    {}
                                        }"
                                        :class="currentPage == item.meta.page ? 'active' : ''"
                                        @click.native="toggleMobileMainMenu('close'); $Utilities.scrollToTop();">
                                        <figure class="image is-23x23">
                                            <cile-svg-icon :src="require(`@assets/images/icons/${item.meta.menu.icon}`)" />
                                        </figure> {{ item.meta.menu.title }}
                                    </router-link>
                                </li>
                                <li><a href="#" @click="openLogoutModal()"><figure class="image is-23x23"><cile-svg-icon :src="require('@assets/images/icons/logout.svg')" /></figure> Sair</a></li>
                            </ul>

                            <div class="box-btn-ecommerce">
                                <cile-button 
                                    type="button" 
                                    title="Acessar a loja" 
                                    buttonStyle="button btn-green-2"
                                    @customClickEvent="redirectToEcommerce()"
                                    :loading="loadingRedirectToEcommerce" />

                                <a 
                                    :href="`${$ecommerceUrl}/clube-livros-digitais#description-of-plans`" 
                                    class="button primary-button btn-green-2" 
                                    target="_blank">Quero assinar</a>
                            </div>

                            <button class="btn-close-menu is-hidden-tablet" @click="toggleMobileMainMenu('close')">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </aside>
                    </div>
    
                    <div class="column main-content">
                        <router-view></router-view>
                    </div>
                </div>
            </div>

            <div class="modal" :class="logoutModal ? 'is-active' : ''">
                <div class="modal-background"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-centered">Logout</p>
                    </header>

                        <section class="modal-card-body">
                            <p class="has-text-centered mt-4 mb-4">Você tem certeza que quer sair?</p>
                        </section>

                    <footer class="modal-card-foot">
                        <cile-button 
                            type="button" 
                            title="Confirmar" 
                            buttonStyle="button btn-green"
                            :secondButton="{
                                type: 'button', 
                                title: 'Cancelar', 
                                class: 'btn-grey', 
                                visible: true
                            }"
                            @customClickEvent="logout()"
                            @customClickSecondEvent="closeLogoutModal()" />
                    </footer>
                </div>
            </div>
        </div>

        <footer class="main-footer">
            <div class="lb-seal">
                <p class="">
                    Made with
                    <img src="~@assets/images/icons/coracao.svg" class="buy-button" />
                    by <a href="https://littleboat.com.br/" target="_blank">Little Boat</a>
                </p>
            </div>
        </footer>
    </div>
</template>

<script>
    import Input from '@components/shared/fields/Input.vue';
    import Button from '@components/shared/button/Button.vue';
    import SvgIcon from '@components/shared/images/SvgIcon.vue';

    export default {
        name: 'DefaultTemplate',
        components: {
            'cile-input': Input,
            'cile-button': Button,
            'cile-svg-icon': SvgIcon
        },
        data() {
			return {
                currentPage: '',
                user: null,
                logoutModal: false,
				activeMobileMainMenu: false,
                filter: '',
                loadingRedirectToEcommerce: false
			};
        },
        computed: {
            itensMenu(){
                return this.$router.options.routes.filter(item => {
                    let isMenu = this.$Utilities.hasProperty(item, 'meta.menu');

                    if(isMenu 
                       && item.name == 'subscriptionPlans' 
                       && this.user.is_non_requesting_beneficiaries){
                        return false;
                    }

                    return isMenu;
                });
            },
            userName(){
                return this.user.name.split(' ')[0];
            }
        },
        methods: {
            goToRoute(route){
                this.$Utilities.scrollToTop();
                this.$router.push({name: route});
            },
            search(){
                if(this.$route.query.filter == this.filter) return;

                let goToRoute = '';

                if(['searchBooks', 'searchBookExcerpts', 
                    'searchHighlight', 'searchTags', 
                    'searchAnnotation', 'searchNews'].includes(this.$route.name)){
                    goToRoute = this.$route.name;
                }else{
                    goToRoute = 'searchBookExcerpts';
                }

                this.$router.push({name: goToRoute, query: {filter: this.filter}});
            },
            toggleMobileMainMenu(toggle){
                switch (toggle) {
                    case 'open':
                        this.activeMobileMainMenu = true;
                        break;

                    case 'close':
                        this.activeMobileMainMenu = false;
                        break;
                }
            },
            openLogoutModal(){
                this.logoutModal = true;
            },
            closeLogoutModal(){
                this.logoutModal = false;
            },
            logout(){
                this.$Session.remove(this.$userSessionName);

                this.$router.push({name: 'landingPage'});
            },
            redirectToEcommerce(){
                this.loadingRedirectToEcommerce = true;

                this.$axios
                    .get(
                        'account/ecommerce-url',
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        let url = `${this.$ecommerceUrl}?url_login=${res.data.ecommerce_url}&token=${res.data.ecommerce_token}`;

                        this.$Session.setAttribute(this.$userSessionName, 'token', res.data.token);

                        this.$Utilities.redirect(url);
                    })
                    .catch(error => this.$Utilities.redirect(this.$ecommerceUrl))
                    .finally(() => this.loadingRedirectToEcommerce = false);
            },
            verifyIsNonRequestingBeneficiary(){
                this.$axios
                    .get(
                        'account/is-non-requesting-beneficiary',
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        this.user.is_non_requesting_beneficiaries = res.data.is_non_requesting_beneficiaries;

                        this.$Session.setAttribute(this.$userSessionName, 'user', this.user);
                    })
                    .catch(error => {});
            }
        },
        created(){
            this.currentPage = this.$route.meta.page;
            this.user = this.$Session.get(this.$userSessionName).user;

            this.verifyIsNonRequestingBeneficiary();
        },
        watch:{
            $route (to, from){
                this.currentPage = to.meta.page;
            }
        } 
    }
</script>

<style lang="scss">
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    /*
    * Menu Principal
    */
    .logged-area {
        .main-header,
        .main-header-mobile {
            .logo {
                cursor: pointer;
            }
        }

        .main .main-menu-box {
            position: fixed;
            z-index: 9999;
            min-width: 300px;
            top: 86px;
            height: calc(100vh - 86px);

            .main-menu {
                margin-top: 25px;
                margin-left: -1.5rem;

                ul li:not(:last-child) {
                    margin-bottom: 10px;
                }

                ul li a {
                    display: inline-flex;
                    align-items: center;

                    position: relative;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 1.5rem;

                    &.active,
                    &:hover {
                        color: #008087;
                        font-weight: 500;

                        figure svg .b {
                            fill: #008087;
                        }
                    }

                    &:before {
                        content: '';

                        display: block;
                        position: absolute;
                        left: 0px;
                        top: 50%;
                        transform: translateY(-50%);

                        width: 3px;
                        height: 0px;
                        background-color: #008087;

                        -webkit-transition: height .4s ease-in-out;
                                transition: height .4s ease-in-out;
                    }

                    &:hover {
                        background: transparent;
                    }

                    &:hover:before,
                    &.active:before {
                        height: 36px;
                    }

                    figure {
                        margin-right: 10px;
                    }
                }

                .logo {
                    max-width: 160px;
                    margin: 0px auto 50px auto;
                }

                .box-btn-ecommerce {
                    margin-top: 25px;
                    padding-left: 1.5rem;

                    .button {
                        width: 100%;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }

            .main-menu {
                height: 100%;
                margin-top: 0px;
                margin-right: -1.5rem;
                padding-right: 1.5rem;
                padding-top: 10px;
                padding-bottom: 50px;

                overflow-y: scroll;
                overflow-x: hidden;

                ul li {
                    margin-bottom: 5px;

                    a {
                        padding-top: 2px;
                        padding-bottom: 2px;
                    }
                }

                &::-webkit-scrollbar-track {
                    background-color: #F9F9F9;
                    margin: 0px;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #00AAAD;
                }
            }

        }

        .menu-burger {
            border: none;
            background-color: transparent;
        }
    }

    .logged-area.light-mode .main .main-menu-box {
        background-color: #ffffff;
    }

    @media screen and (max-width: 768px), print {
        .logged-area .main .main-menu-box {
            z-index: 100;

            top: 0px;
            height: 100vh;
            width: calc(100% - 100px);
            min-width: initial;

            -webkit-transform: translateX(-100%);
                    transform: translateX(-100%);

            -webkit-transition: transform .5s, box-shadow .5s;
                    transition: transform .5s, box-shadow .5s;

            .leftover-menu {
                display: none;
                width: 100px;
                height: 100%;

                position: absolute;
                top: 0px;
                right: -100px;
            }

            .image.logo {
                max-width: 120px;
                margin-bottom: 20px;
            }

            &.is-active {
                -webkit-transform: translateX(0%);
                        transform: translateX(0%);

                box-shadow: 50px 25px 60px #00000080;

                .leftover-menu {
                    display: block;
                }

                .btn-close-menu {
                    position: absolute;
                    top: 20px;
                    right: -45px;

                    width: 40px;
                    height: 35px;
                    display: block;
                    border: none;
                    background: none;
                    cursor: pointer;

                    span {
                        position: absolute;
                        top: auto;
                        bottom: auto;

                        display: block;
                        width: 25px;
                        height: 5px;
                        border-radius: 10px;
                        background-color: #fff;

                        &:nth-child(1) {
                            transform: rotate(45deg);
                        }

                        &:nth-child(2) {
                            display: none;
                        }

                        &:nth-child(3) {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px), print {
        .logged-area .main .main-content {
            margin-left: 0px !important;
        }
    }
</style>