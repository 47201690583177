<template>
    <section class="default-session book-detail" :class="loadingContent ? 'loading medium' : ''">
        <header v-show="!loadingContent" class="is-hidden-mobile">
            <h1>Detalhes do Livro</h1>
        </header>

        <div v-if="book != null" v-show="!loadingContent" class="book-content-mobile is-hidden-tablet">
            <router-link :to="{name: 'ebookReader', params: {id: book.id}}">
                <img class="book-cover" :src='book.image' />
            </router-link>

            <div class="book-box">
                <cile-button 
                    type="route"
                    route="ebookReader" 
                    :routeParams="{id: book.id}"
                    title="LER" 
                    buttonStyle="button is-fullwidth btn-green-2"
                    :disabled="book.is_my_book == 0" />

                <!--cile-button 
                    type="link"
                    :href="book.ecommerce_url" 
                    title="COMPRAR" 
                    buttonStyle="button is-fullwidth btn-green" /-->

                <div class="book-description mb-6">
                    <p class="book-title font-weight-bold mb-3">{{ book.title }}</p>
                    <p class="mb-3">{{ book.synopsis }}</p>
                    <p class="font-weight-semibold">{{ book.author }}</p>
                </div>

                <div v-if="book.is_my_book == 1 && book.book_progress.length > 0" class="last-acess">
                    <p>Último acesso em: <br> <time>{{ book.book_progress[0].last_access }}</time></p>
                </div>

                <cile-social-media 
                    :url="$router.resolve({name: 'bookDetails', params: {id: book.id}}).href"></cile-social-media>
            </div>
        </div>

        <div v-if="book != null" v-show="!loadingContent" class="columns is-hidden-mobile">
            <div class="column is-9">
                <div class="book-content">
                    <router-link :to="{name: 'ebookReader', params: {id: book.id}}">
                        <figure class="book-cover image">
                            <img :src='book.image' />
                        </figure>
                    </router-link>

                    <div class="book-info">
                        <div class="book-description mb-6">
                            <p class="book-title font-weight-bold mb-3">{{ book.title }}</p>
                            <p class="mb-3">{{ book.synopsis }}</p>
                            <p class="font-weight-semibold">{{ book.author }}</p>
                            <p v-if="book.is_my_book == 1 && book.book_progress.length > 0" class="last-acess">Último acesso em: <br> <time>{{ book.book_progress[0].last_access }}</time></p>
                        </div>

                        <cile-social-media 
                            :url="$router.resolve({name: 'bookDetails', params: {id: book.id}}).href"></cile-social-media>
                    </div>
                </div>
            </div>

            <div class="column is-3">
                <cile-button 
                    v-if="book.is_my_book == 1"
                    type="route"
                    route="ebookReader" 
                    :routeParams="{id: book.id}"
                    title="LER" 
                    buttonStyle="button is-fullwidth btn-green"
                    :disabled="book.is_my_book == 0" />

                <cile-button 
                    v-else
                    type="link"
                    :href="book.subscription_plan_url" 
                    title="ASSINAR" 
                    buttonStyle="button is-fullwidth btn-green-2" />

                <!--cile-button 
                    type="link"
                    :href="book.ecommerce_url" 
                    title="COMPRAR" 
                    buttonStyle="button is-fullwidth btn-green-2" /-->
            </div>
        </div>
    </section>
</template>

<script>
    import Button from '@components/shared/button/Button.vue';
    import SocialMedia from '@components/cile/SocialMedia.vue';

    export default {
        name: 'BookDetailsComponent',
        components: {
            'cile-button': Button,
            'cile-social-media': SocialMedia
        },
        data() {
			return {
                book: null,
                loadingContent: false
            };
        },
        methods: {
            getBook(id){
                this.loadingContent = true;

                this.$Utilities.scrollToTop();

                this.$axios
                    .get(
                        `book/${id}`,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => this.book = res.data)
                    .catch(error => {
                        this.$router.push({
                            name: 'myBooks', 
                            params: {message: error.response.status == 404 ? 'bookNotFound' : 'bookError'}
                        });
                    })
                    .finally(() => this.loadingContent = false);
            }
        },
        created() {
            this.getBook(this.$route.params.id);
        }
	};
</script>

<style lang="scss">
    @media screen and (max-width: 768px), print {
        .book-detail .share-social-media {
            text-align: center;
            
            p {
                text-align: center;
            }
        }
    }
</style>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .book-detail {
        .book-content {
            display: flex;

            .book-cover {
                min-width: 250px;
                margin-right: 25px;

                img {
                    width: 250px;
                }
            }

            .book-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .book-title {
                    @include font-config(26px);
                }

                .last-acess {
                    margin-top: 15px;
        
                    &,
                    time {
                        @include font-config(16px, 20px, map.get($font-weights, 'regular'), left, #393A3B, italic);
                    }
                }
        
                .social-media {
                    p {
                        color: map.get($colors, 'medium-gray');
        
                        margin-bottom: 15px;
                    }
        
                    ul {
                        display: flex;
        
                        li:not(:last-child) {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }

        .book-detail-warning {
            @include font-config(16px, 20px, map.get($font-weights, 'semi-bold'), center, #ffffff);

            margin-bottom: 15px;
        }

        .book-content-mobile {
            margin-left: -1.5rem;
            margin-right: -1.5rem;

            .book-cover {
                width: 100%;
            }

            .book-box {
                padding-top: 25px;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }

            .button-component {
                margin: 0px 15px 25px 15px;
            }

            .book-description {
                margin-bottom: 15px;

                .book-title {
                    @include font-config(22px);
                }
            }

            .last-acess {
                padding: 1rem 1.5rem;
                margin: 0px -1.5rem 25px -1.5rem;
                background-color: #ECECEC;

                p,
                time {
                    @include font-config(16px, 20px, map.get($font-weights, 'regular'), left, #393A3B, italic);
                }
            }

            .social-media {
                p {
                    text-align: center;
                    color: map.get($colors, 'medium-gray');

                    margin-bottom: 15px;
                }

                ul {
                    display: flex;
                    justify-content: center;

                    li {
                        margin: 0px 5px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px), print {
        .main-content .book-detail {
            margin-top: -0.75rem;
        }

        .book-detail .book-content-mobile .book-cover {
            display: block;
            margin: 25px auto 0px auto;

            width: auto;
            max-width: 100%;
            height: calc(100vh - 400px);
        }
    }
</style>