var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"book-excerpt-item"},[_c('p',{staticClass:"book-info"},[_c('span',{staticClass:"book-name"},[_vm._v(_vm._s(_vm.paragraph.book.title))]),_vm._v(" - "),_c('span',{staticClass:"book-chapter"},[_vm._v(_vm._s(_vm.paragraph.chapter))])]),_c('p',{staticClass:"book-excerpt",domProps:{"innerHTML":_vm._s(_vm.paragraph.paragraph_excerpt)}}),(_vm.linkBookLibrary)?_c('router-link',{staticClass:"btn-at-the-library",attrs:{"to":{
            name: 'ebookReader', 
            params: {
                id: _vm.paragraph.book.id, 
                filter: _vm.paragraph.filter, 
                keyword: _vm.keyword
            }
        }}},[_c('img',{attrs:{"src":require("@assets/images/icons/icon_na_biblioteca.svg")}}),_vm._v(" Na biblioteca ")]):_vm._e(),(_vm.linkBookDetail)?_c('router-link',{staticClass:"btn-at-the-library",attrs:{"to":{name: 'bookDetails', params: {id: _vm.paragraph.book.id}}}},[_vm._v("Detalhes do livro ")]):_vm._e(),(_vm.linkBookEcommerce)?_c('a',{staticClass:"btn-buy",attrs:{"href":_vm.paragraph.book.ecommerce_url,"target":"_blank"}},[_vm._v("Comprar")]):_vm._e(),(_vm.linkSubscriptionPlan)?_c('a',{staticClass:"btn-buy",attrs:{"href":_vm.paragraph.book.subscription_plan_url,"target":"_blank"}},[_vm._v("Assinar")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }