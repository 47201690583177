<template>
    <section class="default-session book-detail">
        <header class="is-hidden-mobile">
            <h1>Suas notas</h1>
        </header>

        <div class="book-content">
            <figure class="book-cover image">
                <img :src="require('@assets/images/mockup/livro_1.png')" />
            </figure>

            <div class="book-info">
                <div class="book-description">
                    <p>Por uma revolução na educação embasada na dignidade da vida</p>
                    <p class="font-weight-semibold">Daisaku Ikeda</p>
                    <p class="last-acess">Último acesso em: <br> <time>Segunda-Feira, 06 de setembro, 2021</time></p>

                    <div class="book-progress">
                        <p class="progress-title">Você já leu:</p>

                        <div class="progress-indicator">
                            <div class="percentage">35%</div>
                            <div class="progress">
                                <div :style="{width: '35%'}"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <cile-button 
                    type="route" 
                    title="Ver detalhes do livro" 
                    buttonStyle="button text-button text-green btn-see-more" />
            </div>
        </div>
    </section>
</template>

<script>
    import Button from '@components/shared/button/Button.vue';

    export default {
        name: 'BookDetailsComponent',
        components: {
            'cile-button': Button
        },
        data() {
			return {};
        },
        methods: {},
        created() {}
	};
</script>

<style lang="scss">
    .btn-see-more {
        font-weight: 400 !important;

        justify-content: flex-start;
        padding: 0px !important;
    }
</style>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .book-detail {
        .book-content {
            display: flex;

            .book-cover {
                margin-right: 25px;

                img {
                    width: 250px;
                }
            }

            .book-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .last-acess {
                    margin-top: 15px;
        
                    &,
                    time {
                        @include font-config(16px, 20px, map.get($font-weights, 'regular'), left, #393A3B, italic);
                    }
                }        
            }

            .book-progress {
                margin-top: 30px;

                .progress-title {
                    @include font-config(16px, 20px, map.get($font-weights, 'regular'), left, #393A3B, italic);

                    margin-bottom: 5px;
                }

                .progress-indicator {
                    @include font-config(12px, 16px, map.get($font-weights, 'semi-bold'));
    
                    display: flex;
                    align-items: center;
                    max-width: 150px;
    
                    .percentage {
                        margin-right: 5px;
                    }
    
                    .progress {
                        background-color: #F38480;
    
                        &,
                        > div {
                            height: 4px;
                            width: 100%;
                            border-radius: 0px;
                        }
    
                        > div {
                            background-color: #008087;
                        }
                    }
                }
            }
        }
    }
</style>