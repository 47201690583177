<template>
	<article class="bookcase-item">
        <a 
            v-if="linkSubscriptionPlan" 
            :href="book.subscription_plan_url" 
            class="book-detail" 
            target="_blank">
            <figure class="book-cover image">
                <img :src="book.image" />
            </figure>
        </a>

        <a 
            v-else-if="toEcommerce" 
            :href="book.ecommerce_url" 
            class="book-detail" 
            target="_blank">
            <figure class="book-cover image">
                <img :src="book.image" />
            </figure>
        </a>

        <router-link 
            v-else
            :to="{
                name: toReader ? 'ebookReader' : 'bookDetails', 
                params: {id: book.id}
            }" 
            class="book-detail">
            <figure class="book-cover image">
                <img :src="book.image" />
            </figure>
        </router-link>

        <div v-if="book.book_progress" class="book-progress">
            <div class="percentage">{{ book.book_progress.length > 0 ? book.book_progress[0].percentage : 0 }}%</div>
            <div class="progress">
                <div :style="{width: `${book.book_progress.length > 0 ? book.book_progress[0].percentage : 0}%`}"></div>
            </div>
        </div>

        <p class="book-synopsis" v-html="book.summary ? book.summary : book.author"></p>

        <p class="book-title" v-html="book.title"></p>

        <router-link 
            v-if="linkBookDetail"
            :to="{name: 'bookDetails', params: {id: book.id}}" 
            class="book-detail">Detalhes do livro</router-link>

        <router-link 
            v-if="linkBookLibrary"
            :to="{name: 'bookDetails', params: {id: book.id}}" 
            class="book-detail-library">
            <figure class="image">
                <img src="@assets/images/icons/icon_biblioteca.svg" />
            </figure> Na biblioteca</router-link>

        <a 
            v-if="typeof book.ecommerce_url != 'undefined' && linkBookEcommerce" 
            :href="book.ecommerce_url" 
            class="book-purchase" 
            target="_blank">Comprar</a>

        <a 
            v-if="typeof book.subscription_plan_url != 'undefined' && linkSubscriptionPlan" 
            :href="book.subscription_plan_url" 
            class="book-purchase" 
            target="_blank">Assinar</a>
    </article>
</template>

<script>
    export default {
        data(){
            return {}
        },
		props: {
            book: {
                required: true,
                type: Object,
                default: () => {
                    return {
                        title: '',
                        synopsis: '',
                        progress: 0
                    }
                }
            },
            toEcommerce: {
                required: false,
                type: Boolean,
                default: false
            },
            toReader: {
                required: false,
                type: Boolean,
                default: false
            },
            linkBookDetail: {
                required: false,
                type: Boolean,
                default: false
            },
            linkBookLibrary: {
                require: false,
                type:Boolean,
                default: false
            },
            linkBookEcommerce: {
                require: false,
                type:Boolean,
                default: false
            },
            linkSubscriptionPlan: {
                require: false,
                type:Boolean,
                default: false
            }
        }
	}
</script>

<style lang="scss">
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .bookcase-item {
        padding: 0px 15px;

        .book-cover {
            margin-bottom: 15px;
        }

        .book-progress {
            @include font-config(12px, 16px, map.get($font-weights, 'semi-bold'));

            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .percentage {
                margin-right: 5px;
            }

            .progress {
                background-color: rgba(243, 132, 128, 0.4);

                &,
                > div {
                    height: 4px;
                    width: 100%;
                    border-radius: 0px;
                }

                > div {
                    background-color: #008087;
                }
            }
        }

        .book-synopsis,
        .book-title,
        .book-detail,
        .book-detail-library,
        .book-purchase {
            @include font-config(16px, 20px);
        }

        .book-synopsis,
        .book-title {
            color: #4D545A;

            margin-bottom: 5px;
        }

        .book-title {
            font-weight: 600;
        }

        .book-detail,
        .book-detail:hover {
            color: #008087;
        }

        .book-purchase {
            display: block;
        }
        
        .book-purchase,
        .book-purchase:hover {
            color: #F38480;
        }

        .book-detail-library {
            &,
            &:hover {
                color: #00AAAD;
            }

            display: inline-flex;
            align-items: center;

            .image {
                width: 15px;
                margin-right: 5px;
            }
        }
    }
</style>