<template>
    <section class="spotify-banner">
        <figure class="image spotify-icon">
            <img src="~@assets/images/icons/spotify.svg" />
        </figure>

        <h1>Selecionamos algumas músicas para você!</h1>

        <cile-button 
            type="link" 
            title="Confira aqui" 
            buttonStyle="btn-pink"
            href="https://open.spotify.com/user/6532um7yf7x5rojsnlh99zbf0?si=4e5d6e152a2849a1" />
    </section>
</template>

<script>
    import Button from '@components/shared/button/Button.vue';

    export default {
        name: 'SpotifyBannerComponent',
        components: {
            'cile-button': Button
        },
        data() {
			return {};
        },
        methods: {},
        created() {}
	};
</script>

<style lang="scss">
    .button-component.field .button {
        padding-left: 35px;
        padding-right: 35px;
    }
</style>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .spotify-banner {
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        min-height: 350px;
        margin: 25px -1.5rem 0px -1.5rem;
        padding: 1.5rem 0.75rem;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('~@assets/images/spotify_banner.png');

        .spotify-icon,
        h1 {
            margin-bottom: 20px;
        }

        .spotify-icon {
            display: inline-block;
        
            img {
                width: 40px;
            }
        }

        h1 {
            @include font-config(18px, 22px, map.get($font-weights, 'regular'), center, map.get($colors, 'white'));
        }
    }

    @media screen and (max-width: 1023px), print {
        .spotify-banner {
            background-image: url('~@assets/images/spotify_banner_mobile.png');
        }
    }
</style>