<template>
  <div class="">
    <div class="columns is-multiline">
      <div class="column is-12 is-8-widescreen">
        <cile-welcome id="cile-welcome" @loadEvent="ajustElements" />
      </div>
      <div class="column is-12 is-4-widescreen">
        <cile-see-more id="cile-see-more" :link="linkReview" />
      </div>
    </div>

    <div class="horizontal-line"></div>

    <!-- <div class="fullwidth-featured-content"> -->
    <div class="featured-content">
      <!-- <cile-featured-video title="Vídeo Destaque" endpoint="featured/video_review" /> -->

      <cile-featured-review title="Resenha em Destaque" endpoint="featured/review" />
    </div>
    <div class="horizontal-line"></div>
    <cile-featured-books title="Continue lendo" endpoint="books/books-im-reading" />
    <div class="horizontal-line"></div>

    <cile-featured-books
      title="Lançamentos"
      endpoint="books/category/lancamentos"
      :linkBookEcommerce="false"
      :linkBookDetail="true"
    />

    <div class="horizontal-line"></div>

    <div class="dashboard-my-books">
      <cile-featured-books
        title="Nova Revolução Humana"
        endpoint="books/category/nova-revolucao-humana"
        :link="{
          route: 'booksByCategoryNovaRevolucaoHumana',
          params: { slug: 'nova-revolucao-humana' },
        }"
      />
    </div>

    <!-- <div class="horizontal-line"></div> -->

    <!-- <div class="dashboard-my-books">
            <cile-featured-books title="Meus livros" endpoint="books/my-books?limit=5" :link="{
                    route: 'myBooks'
                }" />
        </div> -->
  </div>
</template>

<script>
import Welcome from "@views/dashboard/components/Welcome.vue";
import SeeMore from "@views/dashboard/components/SeeMore.vue";
import FeaturedBooks from "@views/dashboard/components/FeaturedBooks.vue";
import FeaturedVideo from "@components/cile/FeaturedVideo.vue";
import FeaturedReview from "@components/cile/FeaturedReview.vue";
import KeepReading from "@views/myBooks/components/KeepReading.vue";

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Home",
  },
  components: {
    "cile-welcome": Welcome,
    "cile-see-more": SeeMore,
    "cile-featured-books": FeaturedBooks,
    "cile-featured-video": FeaturedVideo,
    "cile-featured-review": FeaturedReview,
    "cile-featured-keep-reading": KeepReading,
  },
  data() {
    return {
      ajustElementsTimeout: null,
      linkReview: null,
    };
  },
  methods: {
    ajustElements() {
      if (this.ajustElementsTimeout != null) clearTimeout(this.ajustElementsTimeout);

      this.ajustElementsTimeout = setTimeout(() => {
        let eleHeight = document.querySelector(
          "#cile-welcome .VueCarousel .VueCarousel-slide > a"
        ).offsetHeight;

        document.querySelectorAll("#cile-see-more .see-more-item").forEach((el) => {
          el.style.height = `${eleHeight}px`;
        });
      }, 100);
    },
    mouseEnterEvent(event) {
      if (!this.$Utilities.verifyBreakpoint(768)) {
        event.target.querySelector(".see-more-info").style.paddingTop =
          (event.target.offsetHeight - 190) / 2 + "px";
      }
    },
    mouseLeaveEnter(event) {
      if (!this.$Utilities.verifyBreakpoint(768)) {
        event.target.querySelector(".see-more-info").style.paddingTop = "25px";
      }
    },
    getVideo() {
      this.loadingContent = true;

      this.$axios
        .get("featured/video_review", {
          headers: {
            Authorization: `bearer ${this.$Session.get(this.$userSessionName).token}`,
          },
        })
        .then((res) => {
          this.linkReview = res.data.link_youtube;
        })
        .catch((error) =>
          this.$Utilities.controlAlert(error.response.data, this.alert, "danger")
        )
        .finally(() => (this.loadingContent = false));
    },
  },
  created() {
    window.addEventListener("resize", this.ajustElements);
    this.getVideo();
  },
  mounted() {
    document.querySelectorAll("#cile-see-more .see-more-item").forEach((el) => {
      el.addEventListener("mouseenter", this.mouseEnterEvent);
      el.addEventListener("mouseleave", (event) => this.mouseLeaveEnter);
    });
  },
  beforeDestroy() {
    document.querySelectorAll("#cile-see-more .see-more-item").forEach((el) => {
      el.removeEventListener("mouseenter", this.mouseEnterEvent);
      el.removeEventListener("mouseleave", (event) => this.mouseLeaveEnter);
    });

    window.removeEventListener("resize", this.ajustElements);
  },
};
</script>

<style lang="scss">
@use "sass:map";
@import "~@assets/css/config.scss";
@import "~@assets/css/mixins.scss";

.logged-area .main {
  .home-featured-content {
    margin: 25px -1.5rem;
    padding: 1.5rem 0.75rem;

    background-color: #ececec;
  }

  .home-featured-section {
    height: 100%;

    .message {
      &,
      .message-body {
        height: 100%;
        border-radius: 6px;
      }

      .message-body {
        display: flex;
        align-items: center;
      }
    }
  }

  .dashboard-my-books {
    & > section {
      margin-bottom: 15px;
    }

    .see-more {
      @include font-config(16px, 20px, map.get($font-weights, "medium"), right, #00aaad);

      display: block;
      margin-left: auto;
      margin-right: 20px;
      border: none;
      background-color: transparent;
      position: relative;

      &:after {
        content: "";

        position: absolute;
        top: 5px;

        display: inline-block;
        padding: 6px;
        margin-left: 3px;
        border: solid #00aaad;
        border-width: 0 3px 3px 0;

        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
}
</style>
