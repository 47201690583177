<template>
	<section class="default-session" :class="loadingContent ? 'loading medium' : ''">
        <header v-show="!loadingContent && !expiredSubPlan">
            <h1>Meus Livros</h1>
        </header>

        <cile-message :alert="alert" />

        <div v-show="!loadingContent" class="columns is-multiline is-mobile">
            <div v-for="(book, index) in bookShowcase" 
                 :key="`last_books_bought_${index}`" 
                 class="column is-12-mobile is-4-tablet is-2-fullhd">
                <cile-book-showcase 
                    :book="book" 
                    :linkBookDetail="true" 
                    :toReader="true" />
            </div>
        </div>

        <cile-expired-subscription-plan-notice
            v-show="expiredSubPlan"
            :url="subscriptionPlanUrl" />
    </section>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import BookShowcase from '@components/cile/BookShowcase.vue';
    import ExpiredSubscriptionPlanNotice from '@components/cile/ExpiredSubscriptionPlanNotice.vue';    

    export default {
        name: 'MyBooksComponent',
        components: {
            'cile-message': Message,
            'cile-book-showcase': BookShowcase,
            'cile-expired-subscription-plan-notice': ExpiredSubscriptionPlanNotice
		},
        data(){
            return {
                bookShowcase: [],
                expiredSubPlan: false,
                subscriptionPlanUrl: '',
                loadingContent: true,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            }
        },
        methods: {
            getBooks(){
                this.alert.show = false;
                this.loadingContent = true;

                this.$axios
                    .get(
                        'books/my-books',
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => this.bookShowcase = res.data)
                    .catch(error => {
                        if(error.response.status == 404 && error.response.data.action == 'expiredSubPlan'){
                            this.expiredSubPlan = true;
                            this.subscriptionPlanUrl = error.response.data.subscription_plan_url;
                        }else{
                            this.$Utilities.controlAlert(error.response.data, this.alert, 'danger');
                        }
                    })
                    .finally(() => this.loadingContent = false);
            }
        },
        created(){
            this.getBooks();
        }
	}
</script>

<style lang="scss"></style>