<template>
    <article class="feed-review-item">
        <div class="review-cover">
            <figure class="image">
                <img :src="review.book.image" />
            </figure>
        </div>

        <div class="review-info">
            <div>
                <p class="review-title">{{ review.title }}</p>
                <p class="review-author font-weight-semibold" v-html="review.book.author"></p>

                <time class="review-date">{{ review.published_at }}</time>

                <div class="horizontal-line is-hidden-mobile"></div>

                <div class="review-description">
                    <p>{{ review.resume }}</p>
                </div>
            </div>
            <div v-show="!!review.external_link">
                <cile-button type="route" route="reviewPdf" :routeParams="{ id: review.id }" title="Ver resenha!"
                    class="btn-review" buttonStyle="button btn-green-only-border" />
            </div>
            <div v-show="!review.external_link">
                <cile-button type="open-link" :openLink="review.pdf" title="Ver resenha!"
                    class="btn-review" buttonStyle="button btn-green-only-border" />
            </div>

        </div>
    </article>
</template>

<script>
import Button from '@components/shared/button/Button.vue';

export default {
    name: 'ReviewItemComponent',
    components: {
        'cile-button': Button
    },
    props: {
        review: {
            required: true,
            type: Object
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss">
.btn-review .button {
    max-width: 290px;
    width: 100%;
}

@media screen and (max-width: 768px),
print {
    .btn-review button {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>

<style lang="scss" scoped>
@use "sass:map";
@import '~@assets/css/config.scss';
@import '~@assets/css/mixins.scss';

.feed-review-item {
    &:not(:last-child) {
        margin-bottom: 25px;
    }

    display: flex;
    border-radius: 5px;
    padding: 50px 45px;

    background-color: #FFFFFF;
    box-shadow: 0px 3px 10px #00000014;

    .review-cover {
        margin-right: 35px;

        figure {
            width: 200px;
            margin-top: 25px;
        }
    }

    .review-info {
        height: 100%;

        .review-title {
            margin-bottom: 5px;
        }

        .review-author {
            margin-bottom: 5px
        }

        .review-date {
            @include font-config(16px, 20px, map.get($font-weights, 'regular'), left, map.get($colors, 'dark-gray'), italic);

            display: block;
            margin-bottom: 15px;
        }

        .review-description {
            margin-bottom: 50px;
        }
    }
}

@media screen and (max-width: 992px),
print {
    .feed-review-item {
        display: block;
        padding: 50px 25px;

        .review-cover {
            margin: 0px 0px 15px 0px;

            figure {
                width: 150px;
                margin: 0 auto;
            }
        }

        .review-info {

            .review-title,
            .review-author,
            .review-date {
                text-align: center;
            }

            .review-date {
                margin-bottom: 35px;
            }

            .btn-review {
                text-align: center;
            }
        }
    }
}
</style>