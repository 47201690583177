export default {
	install(Vue, options) {
		Vue.prototype.$Session = {
			set: (sessionName, value) => {
				localStorage.setItem(sessionName, JSON.stringify(value));
			},
			get: (sessionName) => {
                let storage = localStorage.getItem(sessionName);
                
				return storage ? JSON.parse(localStorage.getItem(sessionName)) : false;
			},
			remove: (sessionName) => {
				localStorage.removeItem(sessionName);
			},
			setUser: (value) => {
				let loggedUser = JSON.parse(localStorage.getItem(Vue.prototype.$userSessionName));

				loggedUser.user = value;

				localStorage.setItem(Vue.prototype.$userSessionName, JSON.stringify(loggedUser));
			},
			setAttribute: (sessionName, attribute, value) => {
				let loggedUser = JSON.parse(localStorage.getItem(sessionName));

				loggedUser[attribute] = value;

				localStorage.setItem(sessionName, JSON.stringify(loggedUser));
			},
			setUserAttribute: (attribute, value) => {
				let loggedUser = JSON.parse(localStorage.getItem(Vue.prototype.$userSessionName));

				loggedUser.user[attribute] = value;

				localStorage.setItem(Vue.prototype.$userSessionName, JSON.stringify(loggedUser));
			}
		}
	}
}