<template>
	<section :class="loadingContent ? 'loading large' : ''">
        <cile-message :alert="alert" />

        <div v-show="!loadingContent">
            <cile-book-excerpt 
                v-for="(paragraph, index) in bookParagraphs"
                :key="`paragraph-${index}`"
                :paragraph="paragraph"
                :keyword="filter"
                :linkBookLibrary="paragraph.book.is_my_book == 1"
                :linkBookDetail="paragraph.book.is_my_book == 0" />

            <cile-pagination 
                v-show="pagination.current > 0"
                :paginationConfig="pagination"
                @customChangePageEvent="changePage" />
        </div>
    </section>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import BookExcerpt from '@views/search/components/BookExcerpt.vue';
    import Pagination from '@components/shared/pagination/Pagination.vue';

    export default {
        name: 'SearchBookExcerpts',
        components: {
            'cile-message': Message,
            'cile-book-excerpt': BookExcerpt,
            'cile-pagination': Pagination
		},
        data(){
            return {
                filter: '',
                bookParagraphs: [],
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                },
                pagination: {
                    current: 0,
                    lastPage: false
                }
            }
        },
        methods: {
            search(filter, page = 1) {
                this.filter = filter;
                this.alert.show = false
                this.loadingContent = true;
                this.bookParagraphs = [];

                this.$Utilities.scrollToTop();

                this.$axios
                    .get(
                        `search/book_excerpt/${this.filter}?page=${page}`,
                        {
                            headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`},
                            params: {
                                device: this.$Utilities.device()
                            }
                        }
                    )
                    .then((res) => {
                        if(res.status == 204){
                            this.pagination.lastPage = true;
                            this.pagination.current = page;

                            this.$Utilities.controlAlert(null, this.alert, 'emptyList');

                            return;
                        }else{
                            this.pagination.lastPage = false;
                        }

                        this.pagination.current = res.data.current_page;

                        this.bookParagraphs = res.data.data.map(item => {
                            return {
                                id: item.id,
                                //text: this.$Utilities.markText(item.clean_text, this.filter),
                                //paragraph: item.clean_text.slice(0, 50),
                                paragraph_excerpt: this.$Utilities.markText(item.paragraph_excerpt, this.filter),
                                filter: item.filter,
                                chapter: item.summary.subtitle ? item.summary.subtitle : item.summary.title,
                                book: {
                                    id: item.summary.book.id,
                                    title: item.summary.book.title,
                                    is_my_book: item.summary.book.is_my_book,
                                    ecommerce_url: item.summary.book.ecommerce_url ? item.summary.book.ecommerce_url : '',
                                    subscription_plan_url: item.summary.book.subscription_plan_url ? item.summary.book.subscription_plan_url : ''
                                }
                            }
                        });
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            },
            changePage(page){
                this.search(this.filter, page);
            }
        },        
        created(){
            this.search(this.$route.query.filter);
        },
        watch:{
            $route (to, from){
                this.search(this.$route.query.filter);
            }
        }
	}
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';
</style>