<template>
    <div class="">
        <cile-breadcrumb 
            :pages="breadcrumb" />

        <section class="default-session">
            <header class="is-sr-only">
                <h1>{{ video != null ? video.title : '' }}</h1>
            </header>

            <cile-message :alert="alert" />

            <div :class="loadingContent ? 'loading large' : ''">
                <cile-videos 
                    v-if="video != null"
                    :video="video" />
            </div>
        </section>
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import Message from '@components/shared/message/Message.vue';
    import Videos from '@views/feedVideos/components/Videos.vue';

    export default {
        name: 'FeedVideos',
        metaInfo() {
            return {
                title: this.video != null ? this.video.title : ''
            }
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-message': Message,
            'cile-videos': Videos
        },
        data() {
			return {
                video: null,
                breadcrumb: [{name: 'Vídeos', route: 'feedVideos'}],
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            };
        },
        methods: {
            getVideo(id){
                this.alert.show = false;
                this.loadingContent = true;

                this.$Utilities.scrollToTop();

                this.$axios
                    .get(
                        `book/videos/${id}`,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status == 204) this.$router.push({name: 'feedVideos'});

                        this.breadcrumb.push({name: res.data.title, route: ''});
                        this.video = res.data;
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            }
        },
        created() {
            this.getVideo(this.$route.params.id);
        }
	};
</script>

<style lang="scss"></style>