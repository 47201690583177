<template>
    <div class="book-details">
        <cile-breadcrumb 
            :pages="[
                {name: 'Anotações', route: 'annotations'},
                {name: 'Por uma revolução na educação embasada na dignidade da vida', route: ''}]" />

        <cile-book-details />
        
        <div class="horizontal-line"></div>

        <cile-last-notes />
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import BookDetails from '@views/annotations/components/BookDetails.vue';
    import LastNotes from '@views/annotations/components/LastNotes.vue';

    export default {
        name: 'YourNotes',
        metaInfo: {
            title: 'Suas Anotações'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-book-details': BookDetails,
            'cile-last-notes': LastNotes
        },
        data() {
			return {};
        },
        methods: {},
        created() {}
	};
</script>

<style lang="scss">
    
</style>