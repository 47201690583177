<template>
	<section class="default-session welcome-section" :class="loadingContent ? 'loading medium v-align' : ''">
        <header>
            <h1>Bem-vindo</h1>
        </header>

        <vue-carousel 
            v-if="!loadingContent && banners.length > 0"
            :navigationEnabled="true"
            :paginationEnabled="false"
            :centerMode="true"
            navigationPrevLabel=""
            navigationNextLabel=""
            :perPageCustom="[[0, 1]]">
            <vue-slide 
                data-index="0"
                data-name="WelcomeImage"
                v-for="(banner, index) in banners" 
                :key="`welcome_image_${index}`">
                <router-link :to="{path: banner.url}">
                    <figure class="image welcome-image">
                        <img :src="banner.image" class="buy-button" @load="emitLoadEvent()" />
                    </figure>
                </router-link>
            </vue-slide>
        </vue-carousel>
    </section>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: 'Welcome',
        components: {
            'vue-carousel': Carousel,
            'vue-slide': Slide
		},
        data(){
            return {
                banners: [],
                loadingContent: false
            }
        },
        methods: {
            getWelcome(){
                this.loadingContent = true;

                this.$axios
                    .get(
                        'featured/welcome',
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        if(res.status == 200) this.banners = res.data;
                    })
                    .catch(error => {})
                    .finally(() => this.loadingContent = false);
            },
            emitLoadEvent(){
                this.$emit('loadEvent');
            }
        },
        created(){
            this.getWelcome();
        }
	}
</script>

<style lang="scss">
    .VueCarousel {
        width: 100%;

        .VueCarousel-navigation {
            .VueCarousel-navigation--disabled {
                border-color: #ffffff !important;
            }

            .VueCarousel-navigation-prev,
            .VueCarousel-navigation-next {
                padding: 10px !important;
                top: calc(50% - 20px);
                border-width: 0px 4px 4px 0px;
            }

            .VueCarousel-navigation-prev {
                left: 10px;
            }

            .VueCarousel-navigation-next {
                right: 10px;
            }
        }
    }
</style>

<style lang="scss" scoped>
    .welcome-section {
        height: 100%;

        .loading.medium {
            height: 100%;

            &:after {
                top: calc(50% - 20px);
                transform: translateY(-50%);
            }
        }
    }

    @media screen and (max-width: 768px), print {
        .logged-area .main .welcome-image {
            border-radius: 6px;
            overflow: hidden;
        }
    }
</style>