import Vue from 'vue'
import App from './App.vue'
import router from './router';
import Axios from 'axios';
import Vuelidate from "vuelidate";
import Session from './plugins/Session.js';
import Utilities from './plugins/Utilities.js';
import VueGtag from "vue-gtag";

Vue.use(Vuelidate);
Vue.use(Session);
Vue.use(Utilities);
Vue.prototype.$axios = Axios;
Vue.prototype.$routes = router;
Vue.prototype.$userSessionName = 'loggedCileReader';
Vue.prototype.$ecommerceUrl = process.env.VUE_APP_ECOMMERCE_URL;
Vue.prototype.$apiBaseURL = process.env.VUE_APP_API_WEB;
Vue.config.productionTip = false;

Axios.defaults.baseURL = process.env.VUE_APP_API_WEB;

Vue.use(VueGtag, {
	config: {
		id: process.env.VUE_APP_GA
	}
});

new Vue({
	router,
	render: h => h(App),
	created(){
		Vue.prototype.$axios.interceptors.request.use(async config => {
			if(typeof config.headers.Authorization == 'undefined') return config;

			if(Vue.prototype.$Session.get(Vue.prototype.$userSessionName) && config.baseURL.includes('/api')){
				let axiosInstance = Axios.create();
				let resCod = 200;

				await axiosInstance
                    .get(
                        'account/token',
                        {headers: {'Authorization': `bearer ${Vue.prototype.$Session.get(Vue.prototype.$userSessionName).token}`}}
                    )
                    .catch(error => resCod = error.response.status);

				if(resCod == 401){
					Vue.prototype.$Session.remove(Vue.prototype.$userSessionName);

					Vue.prototype.$routes.push({name: 'login', params: {page: 'login', message: 'sessionExpired'}});
				}
			}

			return config;
		}, function (error) {
			return Promise.reject(error);
		});
	}
}).$mount('#app')