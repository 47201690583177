var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"beneficiaries add-beneficiary"},[_c('cile-breadcrumb',{attrs:{"pages":[
            {name: 'Gestão de Beneficiários', route: ''},
            {name: 'Assinaturas', route: 'subscriptionPlans'},
            {name: _vm.orderInfo != null ? _vm.orderInfo.nomePlano : '', route: 'beneficiaries', params: {id: _vm.orderId}},
            {name: 'Adicionar', route: ''}
        ]}}),_c('section',{staticClass:"default-session"},[_vm._m(0),_c('cile-message',{attrs:{"alert":_vm.alert}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderInfo.sugestaoBeneficiarios.length > 0),expression:"orderInfo.sugestaoBeneficiarios.length > 0"}],staticClass:"beneficiary-box"},[_c('h2',[_vm._v("Sugestão de beneficiários")]),_c('div',{staticClass:"list-beneficiaries"},_vm._l((_vm.orderInfo.sugestaoBeneficiarios),function(beneficiary,index){return _c('div',{key:("beneficiary-" + index),staticClass:"beneficiary"},[_c('p',{staticClass:"beneficiary-name"},[_vm._v(_vm._s(beneficiary.nome))]),_c('cile-button',{attrs:{"type":"button","title":"Selecionar","buttonStyle":"button btn-green"},on:{"customClickEvent":function($event){return _vm.selectBeneficiary(beneficiary)}}})],1)}),0)]),_c('cile-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderInfo.sugestaoBeneficiarios.length > 0),expression:"orderInfo.sugestaoBeneficiarios.length > 0"}],attrs:{"type":"button","title":"Outra pessoa...","buttonStyle":"button btn-blue","secondButton":{
                type: 'route', 
                route: 'beneficiaries',
                title: 'Voltar', 
                class: 'btn-pink-only-border', 
                visible: !_vm.visibleBeneficiaryForm,
                disabled: _vm.loading
            }},on:{"customClickEvent":function($event){return _vm.newBeneficiary()}}}),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.visibleBeneficiaryForm || _vm.orderInfo.sugestaoBeneficiarios.length == 0),expression:"visibleBeneficiaryForm || orderInfo.sugestaoBeneficiarios.length == 0"}],on:{"submit":function($event){$event.preventDefault();return _vm.submitBeneficiary.apply(null, arguments)}}},[_c('cile-input',{attrs:{"label":"Nome do beneficiário","placeholder":"Digite o nome","validation":_vm.$v.beneficiary.name,"disabled":_vm.beneficiary.beneficiarySuggestion},model:{value:(_vm.$v.beneficiary.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.beneficiary.name, "$model", $$v)},expression:"$v.beneficiary.name.$model"}}),_c('p',{staticClass:"is-italic text-medium-gray mb-3"},[_vm._v("Escolha a melhor forma para envio da notificação")]),_c('cile-radio',{attrs:{"label":"","name":"orderBy","options":[
                    {_id: 'email', name: 'E-mail'},
                    {_id: 'phone', name: 'Whatsapp'}
                ]},model:{value:(_vm.beneficiary.type),callback:function ($$v) {_vm.$set(_vm.beneficiary, "type", $$v)},expression:"beneficiary.type"}}),_c('cile-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.beneficiary.type == 'email'),expression:"beneficiary.type == 'email'"}],attrs:{"label":"E-mail do beneficiário","placeholder":"Digite o e-mail","validation":_vm.$v.beneficiary.email},model:{value:(_vm.$v.beneficiary.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.beneficiary.email, "$model", $$v)},expression:"$v.beneficiary.email.$model"}}),_c('cile-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.beneficiary.type == 'phone'),expression:"beneficiary.type == 'phone'"}],attrs:{"label":"Whatsapp do beneficiário","placeholder":"Digite o Whatsapp","inputMask":['(##) ####-####', '(##) #####-####'],"validation":_vm.$v.beneficiary.phone},model:{value:(_vm.$v.beneficiary.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.beneficiary.phone, "$model", $$v)},expression:"$v.beneficiary.phone.$model"}}),_c('cile-button',{attrs:{"type":"submit","title":"Adicionar beneficiário","buttonStyle":"btn-blue","loading":_vm.loading,"secondButton":{
                    type: 'route', 
                    route: 'beneficiaries',
                    title: 'Voltar', 
                    class: 'btn-pink-only-border', 
                    visible: true,
                    disabled: _vm.loading
                }}})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('h1',[_vm._v("Adicionar Beneficiário")])])}]

export { render, staticRenderFns }