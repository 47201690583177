<template>
    <div class="">
        <cile-breadcrumb 
            :pages="[{name: 'CEND', route: ''}]" />

        <section class="default-session" :class="loadingContent ? 'loading medium' : ''">
            <header v-show="!loadingContent">
                <h1>CEND</h1>
            </header>

            <cile-message :alert="alert" />

            <div v-show="!loadingContent" class="columns is-multiline is-mobile">
                <div v-for="(book, index) in bookShowcase" 
                    :key="`last_books_bought_${index}`" 
                    class="column is-6-mobile is-4-tablet is-2-fullhd">
                    <cile-book-showcase :book="book" :linkBookDetail="true" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import Message from '@components/shared/message/Message.vue';
    import BookShowcase from '@components/cile/BookShowcase.vue';

    export default {
        name: 'MyBooks',
        metaInfo: {
            title: 'CEND'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-message': Message,
            'cile-book-showcase': BookShowcase
        },
        data() {
			return {
                bookShowcase: [],
                loadingContent: true,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            };
        },
        methods: {
            getBooks(){
                this.alert.show = false;
                this.loadingContent = true;

                this.$axios
                    .get(
                        'books/free-books',
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => this.bookShowcase = res.data)
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            }
        },
        created() {
            this.getBooks();
        }
	};
</script>

<style lang="scss"></style>