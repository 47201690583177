<template>
    <div class="">
        <cile-breadcrumb 
            :pages="[{name: 'Vídeos', route: ''}]" />

        <section class="default-session">
            <header>
                <h1>Feed de vídeos</h1>
            </header>

            <cile-message :alert="alert" />

            <div :class="loadingContent ? 'loading large' : ''">
                <div v-show="!loadingContent && videosList.length > 0">
                    <cile-videos 
                        v-for="(video, index) in videosList" 
                        :key="`${index}-news`"
                        :video="video" />

                    <cile-pagination 
                        :paginationConfig="pagination"
                        @customChangePageEvent="changePage" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Breadcrumb from '@components/shared/breadcrumb/Breadcrumb.vue';
    import Message from '@components/shared/message/Message.vue';
    import Pagination from '@components/shared/pagination/Pagination.vue';
    import Videos from '@views/feedVideos/components/Videos.vue';

    export default {
        name: 'FeedVideos',
        metaInfo: {
            title: 'Feed de vídeos'
        },
        components: {
            'cile-breadcrumb': Breadcrumb,
            'cile-message': Message,
            'cile-pagination': Pagination,
            'cile-videos': Videos
        },
        data() {
			return {
                videosList: [],
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                },
                pagination: {
                    current: 1,
                    totalPages: 1
                }
            };
        },
        methods: {
            getVideos(page = 1){
                this.alert.show = false;
                this.loadingContent = true;

                this.$Utilities.scrollToTop();

                this.$axios
                    .get(
                        `book/videos?page=${page}`,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => {
                        this.pagination.current = res.data.current_page;
                        this.pagination.totalPages = res.data.last_page;

                        this.videosList = res.data.data;
                    })
                    .catch(error => this.$Utilities.controlAlert(error.response.data, this.alert, 'danger'))
                    .finally(() => this.loadingContent = false);
            },
            changePage(page){
                this.getVideos(page);
            }
        },
        created() {
            this.getVideos();
        }
	};
</script>

<style lang="scss"></style>