<template>
    <div class="modal" :class="open ? 'is-active' : ''">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title has-text-centered">Olá!</p>
            </header>

            <section class="modal-card-body">
                <p class="has-text-centered mt-4 mb-4">Este conteúdo é exclusivo para assinantes. Para acessar, assine agora mesmo o CILE Digital.</p>
            </section>

            <footer class="modal-card-foot">
                <cile-button 
                    type="link"
                    :href="url" 
                    title="ASSINAR" 
                    buttonStyle="button btn-green-2"
                    :secondButton="{
                        type: 'button', 
                        title: 'Cancelar', 
                        class: 'btn-grey', 
                        visible: true
                    }"
                    @customClickEvent="closeModal()"
                    @customClickSecondEvent="closeModal()" />
            </footer>
        </div>
    </div>
</template>

<script>
    import Button from '@components/shared/button/Button.vue';

    export default {
        name: 'ModalSubscriptionPlan',
        components: {
            'cile-button': Button
		},
        props: {
            open: {
                required: true,
                type: Boolean,
                default: false
            },
            url: {
                required: true,
                type: String
            }
        },
        data(){
            return {}
        },
        methods: {
            closeModal(){
                this.$emit('closeModal');
            }
        }
	}
</script>

<style lang="scss">
    .modal {
        .modal-card-foot {
            .button {
                width: calc(50% - 0.25rem);
            }
        }
    }
</style>

<style lang="scss" scoped></style>