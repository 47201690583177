<template>
	<section :class="loadingContent ? 'loading medium' : ''" class="default-session">
        <header v-if="title" v-show="!alert.show && !loadingContent">
            <h1>{{ title }}</h1>
        </header>

        <cile-message :alert="alert" />

        <vue-carousel 
            v-show="!alert.show"
            v-if="!loadingContent"
            :navigationEnabled="false"
            navigationPrevLabel=""
            navigationNextLabel=""
            :perPageCustom="[[0, 1], [992, 3], [1216, 4], [1408, 6]]">
            <vue-slide 
                data-index="0"
                data-name="FeaturedBooks"
                v-for="(book, index) in bookShowcase" 
                :key="`featured_books_${index}`">
                <cile-book-showcase 
                    :book="book" 
                    :linkBookDetail="true" />
            </vue-slide>
        </vue-carousel>

        <cile-expired-subscription-plan-notice
            v-show="expiredSubPlan"
            :url="subscriptionPlanUrl" />

        <router-link         
            v-if="link.route != '' && !expiredSubPlan"
            :to="{
                name: link.route,
                params: link.params
            }" 
            class="see-more">Ver todos
        </router-link>
    </section>
</template>

<script>
    import Message from '@components/shared/message/Message.vue';
    import BookShowcase from '@components/cile/BookShowcase.vue';
    import ExpiredSubscriptionPlanNotice from '@components/cile/ExpiredSubscriptionPlanNotice.vue';    

    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: 'FeaturedBooks',
        components: {
            'cile-message': Message,
            'cile-book-showcase': BookShowcase,
            'vue-carousel': Carousel,
            'vue-slide': Slide,
            'cile-expired-subscription-plan-notice': ExpiredSubscriptionPlanNotice
		},
        props: {
            title: {
                required: false,
                type: String
            },
            endpoint: {
                required: true,
                type: String
            },
            linkBookDetail: {
                required: false,
                type: Boolean,
                default: true
            },
            linkBookEcommerce: {
                required: false,
                type: Boolean,
                default: false
            },
            link: {
                required: false,
                type: Object,
                default: () => {
                    return {
                        route: '',
                        params: {}
                    }
                }
            }
        },
        data(){
            return {
                bookShowcase: [],
                expiredSubPlan: false,
                subscriptionPlanUrl: '',
                loadingContent: false,
                alert: {
                    message: '',
                    type: '',
                    show: false
                }
            }
        },
        methods: {
            getBooks(){
                this.alert.show = false;
                this.loadingContent = true;

                this.$axios
                    .get(
                        this.endpoint,
                        {headers: {'Authorization': `bearer ${this.$Session.get(this.$userSessionName).token}`}}
                    )
                    .then((res) => this.bookShowcase = res.data)
                    .catch(error => {
                        if(error.response.status == 404 && error.response.data.action == 'expiredSubPlan'){
                            this.expiredSubPlan = true;
                            this.subscriptionPlanUrl = error.response.data.subscription_plan_url;
                        }else{
                            this.$Utilities.controlAlert(error.response.data, this.alert, 'danger');
                        }
                    })
                    .finally(() => this.loadingContent = false);
            }
        },
        created(){
            this.getBooks();
        }
	}
</script>

<style lang="scss"></style>