import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';

import Login from './views/login/Login.vue';
import Dashboard from './views/dashboard/Dashboard.vue';
import MyBooks from './views/myBooks/MyBooks.vue';
import BookDetails from './views/myBooks/BookDetails.vue';
import FreeBooks from './views/freeBooks/FreeBooks.vue';
import BooksByCategory from './views/books/BooksByCategory.vue';
import Annotations from './views/annotations/Annotations.vue';
import YourNotes from './views/annotations/YourNotes.vue';
import FeedVideos from './views/feedVideos/FeedVideos.vue';
import VideoDetail from './views/feedVideos/VideoDetail.vue';
import FeedReviews from './views/feedReviews/FeedReviews.vue';
//import FeedNews from './views/feedNews/FeedNews.vue';
//import newsDetail from './views/feedNews/NewsDetail.vue';
import Search from './views/search/Search.vue';
import SearchBooks from './views/search/SearchBooks.vue';
import SearchBookExcerpts from './views/search/SearchBookExcerpts.vue';
import SearchNews from './views/search/SearchNews.vue';
import SearchAnnotation from './views/search/SearchAnnotation.vue';
import EbookReader from './views/reader/EbookReader.vue';
import SubscriptionPlans from './views/beneficiaries/SubscriptionPlans.vue';
import Beneficiaries from './views/beneficiaries/Beneficiaries.vue';
import AddBeneficiary from './views/beneficiaries/AddBeneficiary.vue';

import ReviewPdf from './views/feedReviews/ReviewPdf.vue';

import LandingPage from './views/digitalSubscriptionPlan/LandingPage.vue';

Vue.use(Router);
Vue.use(VueMeta);

const router = new Router({
	mode: 'history',
	base: '',
	routes: [
		{
			path: '/', 
			name: 'landingPage', 
			component: LandingPage, 
			meta: {
				requiresAuth: false
			}
		},
        {
			path: '/account/:page', 
			name: 'login', 
			component: Login, 
			meta: {
				template: 'loginTemplate', 
				requiresAuth: false
			}
		},
		{
			path: '/dashboard', 
			name: 'dashboard', 
			component: Dashboard, 
			meta: {
				template: 'defaultTemplate', 
				page: 'dashboard', 
				requiresAuth: true, 
				menu: {
					title: 'Home', 
					page: 'dashboard', 
					icon: 'home.svg'
				}
			}
		},
		{
			path: '/meus-livros', 
			name: 'myBooks', 
			component: MyBooks, 
			meta: {
				template: 'defaultTemplate', 
				page: 'myBooks', 
				requiresAuth: true, 
				menu: {
					title: 'Meus Livros', 
					page: 'myBooks', 
					icon: 'meus_livros.svg'
				}
			}
		},
		{
			path: '/meus-livros/detalhes/:id', 
			name: 'bookDetails', 
			component: BookDetails, 
			meta: {
				template: 'defaultTemplate', 
				page: 'myBooks', 
				requiresAuth: true
			}
		},
		{
			path: '/livro/:id', 
			name: 'ebookReader', 
			component: EbookReader, 
			meta: {
				template: 'ebookTemplate', 
				page: 'ebookReader', 
				requiresAuth: true
			}
		},
		{
			path: '/livros/:slug', 
			name: 'booksByCategoryCend', 
			component: BooksByCategory, 
			meta: {
				template: 'defaultTemplate', 
				page: 'booksByCategoryCend', 
				requiresAuth: true, 
				params: {
					slug: 'biblioteca-gratuita'
				},
				menu: {
					title: 'Biblioteca Gratuita', 
					page: 'booksByCategoryCend', 
					icon: 'meus_livros.svg'
				}
			}
		},
		{
			path: '/livros/:slug', 
			name: 'booksByCategoryNovaRevolucaoHumana', 
			component: BooksByCategory, 
			meta: {
				template: 'defaultTemplate', 
				page: 'booksByCategoryNovaRevolucaoHumana', 
				requiresAuth: true, 
				params: {
					slug: 'nova-revolucao-humana'
				}
			}
		},
		{
			path: '/anotacoes', 
			name: 'annotations', 
			component: Annotations, 
			meta: {
				template: 'defaultTemplate', 
				page: 'annotations', 
				requiresAuth: true, 
				menu: {
					title: 'Anotações', 
					page: 'annotations', 
					icon: 'anotacoes.svg'
				}
			}
		},
		{
			path: '/anotacoes/suas-anotacoes', 
			name: 'yourNotes', 
			component: YourNotes, 
			meta: {
				template: 'defaultTemplate', 
				page: 'annotations', 
				requiresAuth: true
			}
		},
		{
			path: '/videos', 
			name: 'feedVideos', 
			component: FeedVideos, 
			meta: {
				template: 'defaultTemplate', 
				page: 'feedVideos', 
				requiresAuth: true, 
				menu: {
					title: 'Vídeos', 
					page: 'feedVideos', 
					icon: 'videos.svg'
				}
			}
		},
		{
			path: '/video/:id', 
			name: 'videoDetail', 
			component: VideoDetail, 
			meta: {
				template: 'defaultTemplate', 
				page: 'feedVideos', 
				requiresAuth: true
			}
		},
		{
			path: '/resenhas', 
			name: 'feedReviews', 
			component: FeedReviews, 
			meta: {
				template: 'defaultTemplate', 
				page: 'feedReviews', 
				requiresAuth: true, 
				menu: {
					title: 'Resenhas', 
					page: 'feedReviews', 
					icon: 'resenha.svg'
				}
			}
		},
		{
			path: '/resenha/:id', 
			name: 'reviewPdf', 
			component: ReviewPdf, 
			meta: {
				template: 'defaultTemplate', 
				page: 'feedReviews', 
				requiresAuth: true
			}
		},
		{
			path: '/planos-de-assinatura', 
			name: 'subscriptionPlans', 
			component: SubscriptionPlans, 
			meta: {
				template: 'defaultTemplate', 
				page: 'subscriptionPlans', 
				requiresAuth: true, 
				menu: {
					title: 'Gestão de Beneficiários', 
					page: 'subscriptionPlans', 
					icon: 'beneficiarios.svg'
				}
			}
		},
		{
			path: '/planos-de-assinatura/:id/beneficiarios', 
			name: 'beneficiaries', 
			component: Beneficiaries, 
			meta: {
				template: 'defaultTemplate', 
				requiresAuth: true
			}
		},
		{
			path: '/planos-de-assinatura/:id/beneficiarios/adicionar', 
			name: 'addBeneficiary', 
			component: AddBeneficiary, 
			meta: {
				template: 'defaultTemplate', 
				page: 'addBeneficiary', 
				requiresAuth: true
			}
		},
		/*
		{
			path: '/noticias', 
			name: 'feedNews', 
			component: FeedNews, 
			meta: {
				template: 'defaultTemplate', 
				page: 'feedNews', 
				requiresAuth: true, 
				menu: {
					title: 'Feed de Notícias', 
					page: 'feedNews', 
					icon: 'feed.svg'
				}
			}
		},
		{
			path: '/noticia/:id', 
			name: 'newsDetail', 
			component: newsDetail, 
			meta: {
				template: 'defaultTemplate', 
				page: 'feedNews', 
				requiresAuth: true
			}
		},
		*/
		{
			path: '/busca', 
			name: 'search', 
			component: Search, 
			meta: {
				template: 'defaultTemplate', 
				requiresAuth: true
			}, 
			children: [
				{
					path: 'livros', 
					name: 'searchBooks', 
					component: SearchBooks, 
					meta: {
						template: 'defaultTemplate', 
						requiresAuth: true
					}
				},
				{
					path: 'trechos', 
					name: 'searchBookExcerpts', 
					component: SearchBookExcerpts, 
					meta: {
						template: 'defaultTemplate', 
						requiresAuth: true
					}
				},
				/*
				{
					path: 'noticias', 
					name: 'searchNews', 
					component: SearchNews, 
					meta: {
						template: 'defaultTemplate', 
						requiresAuth: true
					}
				},
				*/
				{
					path: 'tags', 
					name: 'searchTags', 
					component: SearchAnnotation, 
					meta: {
						template: 'defaultTemplate', 
						requiresAuth: true, 
						filterBy: 'tag'
					}
				},
				{
					path: 'anotacoes', 
					name: 'searchAnnotation', 
					component: SearchAnnotation, 
					meta: {
						template: 'defaultTemplate', 
						requiresAuth: true, 
						filterBy: 'annotation'
					}
				}
			]
		}
    ]
});

router.beforeEach((to, from, next) => {
	Vue.prototype.$Utilities.scrollToTop();

	if(to.meta.requiresAuth && Vue.prototype.$Session.get(Vue.prototype.$userSessionName) == false){
		// Usuario não esta logado e tenta acessar alguma pagina interna
		if(['newsDetail', 'videoDetail', 'bookDetails'].includes(to.name)){
			next({name: 'login', params: {page: 'login', redirectTo: to}});
		}else{
			next({name: 'landingPage'});
		}
	}else if(!to.meta.requiresAuth && Vue.prototype.$Session.get(Vue.prototype.$userSessionName) != false){
		// Usuario esta logado e tenta acessar a tela de login

		if(to.name == 'login' && (typeof to.params.page != 'undefined' && to.params.page == 'check-registration')){
			//Usuario esta logado e tenta acessar o link de verificação de beneficiario
			Vue.prototype.$Session.remove(Vue.prototype.$userSessionName);

			next();
		}else{
			next({name: 'dashboard'});
		}
	}else{
		// Usuario esta logado e tenta acessar qualquer pagina interna
		next();
	}
});

export default router;