<template>
    <article class="book-excerpt-item">
        <router-link :to="{name: 'newsDetail', params: {id: news.id}}">
            <p class="book-info"><span class="book-name" v-html="news.title"></span> - <span class="book-chapter">{{ news.publishedDate }}</span></p>
            
            <p class="book-excerpt" v-html="news.excerpt"></p>
        </router-link>
    </article>
</template>

<script>
    export default {
        name: 'SearchNewsComponent',
        data(){
            return {}
        },
        props: {
            news: {
                required: true,
                type: Object,
                default: () => {
                    return {
                        id: '',
                        title: '',
                        excerpt: '',
                        publishedDate: ''
                    }
                }
            }
        }
	}
</script>

<style lang="scss" scoped>
    @use "sass:map";
    @import '~@assets/css/config.scss';
    @import '~@assets/css/mixins.scss';

    .book-excerpt-item {
        &:not(:last-child){
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px solid #DCDCDC;
        }

        .book-info {
            @include font-config(16px, 20px);

            margin-bottom: 15px;

            .book-name {
                font-weight: map.get($font-weights, 'semi-bold');                
            }

            .book-chapter,
            .book-position {
                font-style: italic;
            }
        }

        .book-excerpt {
            @include font-config(16px, 20px);

            margin-bottom: 15px;

            .searched-excerpt {
                padding: 0px 2px;
                background-color: rgba(0, 170, 173, 0.2);
            }
        }

        .btn-at-the-library,
        .btn-buy {
            @include font-config(16px, 20px);

            border: none;
            cursor: pointer;
            background-color: transparent;
        }

        .btn-at-the-library {
            color: #00AAAD;

            display: flex;
            align-items: center;

            img {
                width: 15px;
                margin-right: 5px;
            }
        }

        .btn-buy {
            color: #F38480;
        }
    }
</style>